import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBillingData, invoiceJob } from '../../actions/jobActions'
import { Card } from 'react-bootstrap'
import { Message, Loader } from '../componentLib'
import MaterialTable from 'material-table'
import Moment from 'react-moment'
import Check from '@material-ui/icons/Check'

export const BillingData = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBillingData())
  }, [dispatch])

  const jobData = useSelector((state) => state.jobData)
  const { loadingBilling, errorBilling, dataBilling } = jobData

  const invoiceHandler = (jobId, invoiceType) => {
    dispatch(invoiceJob(jobId, invoiceType))
  }

  const columns = [
    {
      title: 'timeIn',
      field: 'timeIn',
      render: (rowData) => <Moment format="ddd MMM D">{rowData.timeIn}</Moment>,
    },
    { title: 'Status', field: 'statusCurrent' },
    { title: 'custNum', field: 'owner.num' },
    { title: 'custName', field: 'owner.companyName' },
    {
      title: 'Number',
      field: 'num',
      render: (rowData) => (
        <>
          {rowData.num}-R{rowData.revision}
        </>
      ),
    },
    { title: 'Name', field: 'name' },
    { title: 'Revenue', field: 'revenue' },
    { title: 'Level', field: 'level' },
    { title: 'Billing', field: 'billingStatusCurrent' },
  ]

  return (
    <div>
      {loadingBilling ? (
        <Loader />
      ) : errorBilling ? (
        <Message variant="danger">{errorBilling}</Message>
      ) : dataBilling ? (
        <>
          <Card className="mb-3">
            <MaterialTable
              className="white-bg"
              columns={columns}
              title="All Estimates"
              data={dataBilling}
              actions={[
                {
                  icon: () => <Check />,
                  toolTip: 'Click to set to invoiced',
                  onClick: (event, rowData) =>
                    invoiceHandler(rowData._id, 'Invoiced'),
                },
              ]}
              options={{
                filtering: true,
                paging: true,
                searchFieldAlignment: 'left',
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                pageSizeOptions: [10, 25, 50, 100, 500, 1000, 1500], // rows selection options
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.color,
                }),
              }}
            />
          </Card>
        </>
      ) : null}
    </div>
  )
}
