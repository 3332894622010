import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AvatarEditor } from '../../utils/react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { Card, Button } from 'react-bootstrap'
import { updateClientLogo } from '../../actions/clientActions'

export const ImageEditor = ({ clientInitials, clientId, clientLogoUrl }) => {
  //const SERVER_URI = process.env.SERVER_URL
  //const LOGO_URI = process.env.REACT_APP_CLIENT_LOGO_URL

  const [image, setImage] = useState(clientLogoUrl)
  const [scale, setScale] = useState(1)
  const editor = useRef(null)

  const dispatch = useDispatch()
  const saveLogoHandler = () => {
    const canvasScaled = editor.current.getImageScaledToCanvas()
    canvasScaled.toBlob((blob) => {
      const formData = new FormData()
      formData.append('logo', blob, `${clientInitials}.png`)
      dispatch(updateClientLogo(clientId, formData))
      //axios.post(`/api/v1/clients/${clientId}/logo`, formData)
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: (file) => setImage(file[0]),
    maxFiles: 1,
  })

  return (
    <Card>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag and drop image here, or click to select image file</p>
        )}
      </div>
      {image && (
        <>
          <AvatarEditor
            style={{ outline: '2px dashed gray' }}
            ref={editor}
            image={image}
            width={450}
            height={250}
            border={0}
            color={[128, 128, 128, 0.5]} // RGBA
            scale={scale}
            rotate={0}
            backgroundColor='white'
            canvasBackgroundColor={[255, 255, 255, 1]}
          />
          <div>
            Zoom:
            <input
              type='range'
              min={0.1}
              max={3}
              step={0.1}
              onChange={(e) => setScale(e.target.value)}
            />
          </div>
          <Button onClick={saveLogoHandler} variant='primary'>
            Save Logo
          </Button>
          <div className='text-muted'>Refresh after save</div>
        </>
      )}
    </Card>
  )
}
