import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import { ListGroup, Card, Col, Row, Button } from 'react-bootstrap'

import { getJobById, updateJobStatus } from '../../actions/jobActions'
import { createQuote } from '../../actions/quoteActions'
import {
  Message,
  Loader,
  JobValidate,
  JobCopyData,
  JobStatusBadge,
  JobStatusBadgeUpdate,
  QuoteIndexJob,
  QuotePdfReleaseJob,
} from '../componentLib'
import { RBA_CAD, RBA_ADMIN, RBA_CLIENT } from '../../utils/rba'
import { LinkContainer } from 'react-router-bootstrap'

export const JobDetail = ({ jobId, history }) => {
  const dispatch = useDispatch()

  const jobDetail = useSelector((state) => state.jobDetail)
  const { loading, error, job } = jobDetail

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [draftFiles, setDraftFiles] = useState('')
  const [subFiles, setSubFiles] = useState([])
  const [currentReleaseFiles, setCurrentReleaseFiles] = useState('')
  const [oldReleaseFiles, setOldReleaseFiles] = useState('')
  const [importFiles, setImportFiles] = useState('')
  const [isMrm, setIsMrm] = useState(false)

  const [isCopied, setIsCopied] = useState(false)

  //Draft files are type 4, and we only show current revision/correction
  //Release files are type 6, and we'll show by revision
  useEffect(() => {
    setDraftFiles(
      job.files?.filter(
        (file) => file.fileType === 4 && file.jobRev === job.revision && file.jobCorr === job.correction
      )
    )
    setSubFiles(job.files?.filter((file) => file.fileType === 1 && file.url !== '' && file.url != null))
    setCurrentReleaseFiles(job.files?.filter((file) => file.fileType === 6 && file.jobRev === job.revision))
    console.log('subFiles', subFiles)
    setOldReleaseFiles(
      job.files?.filter((file) => file.fileType === 6 && file.jobRev !== job.revision)
      //getOldFiles(job.files, job.revision)
    )
    setImportFiles(job.files?.filter((file) => file.fileType === 9))
  }, [job])

  const getOldFiles = (files, revision) => {
    let oldFiles = files.filter((file) => file.fileType === 6 && file.jobRev !== revision)
    return [oldFiles].sort((a, b) => a.jobRev > b.jobRev)
  }

  useEffect(() => {
    if (userInfo) {
      dispatch(getJobById(jobId))
    }
  }, [dispatch, userInfo, jobId])

  useEffect(() => {
    if (job && job.owner && job.owner.inits === 'MRM') {
      setIsMrm(true)
    }
  }, [job])

  const assignHandler = () => {
    console.log(`assign`)
    dispatch(updateJobStatus(jobId, 'assign'))
  }

  const unassignHandler = () => {
    console.log(`assign`)
    dispatch(updateJobStatus(jobId, 'unassign'))
  }

  let googleMaps = `https://www.google.com/maps/embed/v1/place?key=AIzaSyBcPnYoR66oQP2iTGs0j_3OTbs0_0u4tNA&q=${job.street},${job.city},${job.state}&maptype=satellite&zoom=20`
  if (job.mrmAddress) {
    googleMaps = `https://www.google.com/maps/embed/v1/place?key=AIzaSyBcPnYoR66oQP2iTGs0j_3OTbs0_0u4tNA&q=${job.mrmAddress}&maptype=satellite&zoom=20`
  }

  //Quote creation...should refactor...
  const quoteIndex = useSelector((state) => state.quoteIndex)
  const { newQuoteId } = quoteIndex

  const [redirect, setRedirect] = useState(false)
  const handlePrefill = (url, type) => {
    const SERVER_URI = '/' //'http://localhost:3000/'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log(`url for prefill: ${url}`)
    console.log(job.customer)
    if (type === 'quote') {
      const quote = {
        url,
        prefill: true,
        num: job.num,
        job: jobId,
        street: job.street,
        city: job.city,
        state: job.state,
        name: job.name,
        customer: job.customer?._id,
      }
      dispatch(createQuote(quote))
      setRedirect(true)
    }
    if (type === 'packList') {
      console.log('packlist')

      axios
        .post(`${SERVER_URI}api/v1/quotes/parsePacks`, { url }, config)
        .then((res) => {
          console.log('RESPONSE FROM SERVER TO CLIENT:', res.data)
          // setSuccess(true)
        })
        .catch((err) => {
          console.log('SERVER ERROR TO CLIENT:', err)
          // setError(err)
        })
    }
    if (type === 'flatsCalc') {
      console.log('flatsCalc')
      axios
        .post(`${SERVER_URI}api/v1/quotes/parseFlats`, { url }, config)
        .then((res) => {
          console.log('RESPONSE FROM SERVER TO CLIENT:', res.data)
          // setSuccess(true)
        })
        .catch((err) => {
          console.log('SERVER ERROR TO CLIENT:', err)
          // setError(err)
        })
    }
  }

  useEffect(() => {
    if (redirect && newQuoteId) {
      history?.push(`/quotes/${newQuoteId}`)
    }
  }, [redirect, newQuoteId, history])

  console.log(oldReleaseFiles)

  const handleClickAddress = () => {
    let address = `${job.street}, ${job.city}, ${job.state}`
    navigator.clipboard.writeText(address)
    setIsCopied(true)
  }

  //Interval function to update periodically
  const useInterval = (callback, delay) => {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      const tick = () => {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const refreshInterval = RBA_CAD.indexOf(userInfo.role) >= 0 ? 1 * 1000 : 5 * 60 * 1000

  useInterval(() => {
    // Make the request here
    dispatch(getJobById(jobId))
  }, refreshInterval)

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : job ? (
        <>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={5}>
                  <h2 className="card-title mb-1">
                    {job.num}-Rev{job.revision}
                  </h2>
                  <h3>
                    {job.customer &&
                      job.customer.shortName &&
                      RBA_CLIENT.indexOf(userInfo.role) >= 0 &&
                      `[${job.customer.shortName || job.customer.name}] `}
                    {job.name}
                  </h3>

                  <p>
                    Address:{' '}
                    <span className="click-to-copy" onClick={handleClickAddress}>
                      {job.street} {job.city} {job.state}{' '}
                    </span>
                    <span className={isCopied && 'mark'}>{isCopied ? 'Copied!' : ''}</span>
                  </p>

                  <p>
                    Status: <strong>{job.statusCurrent}</strong>
                  </p>
                  <p>
                    Panel: {job.pan1Name} Coverage: {job.pan1Width}
                  </p>
                  {(job.pan2Width || job.pan2Name) && (
                    <p className={RBA_CAD.indexOf(userInfo.role) >= 0 ? 'job-hl-panel2' : ''}>
                      Alt Panel: {job.pan2Name} Coverage: {job.pan2Width}
                    </p>
                  )}
                  {job.specialReq && (
                    <p className={RBA_CAD.indexOf(userInfo.role) >= 0 ? 'job-hl-notes' : ''}>
                      {' '}
                      <span className="badge rounded-pill bg-secondary">
                        <i className="fa-solid fa-comment" /> Job Notes
                      </span>{' '}
                      Job Notes: <strong>{job.specialReq}</strong>{' '}
                    </p>
                  )}
                  {job.owner?.clientNotes && RBA_CAD.indexOf(userInfo.role) >= 0 && (
                    <p>
                      {' '}
                      <span className="badge rounded-pill bg-success">
                        <i className="fas fa-sticky-note" /> Client Notes
                      </span>{' '}
                      Client Notes: <strong>{job.owner.clientNotes}</strong>{' '}
                    </p>
                  )}
                </Col>

                <Col lg={7}>
                  <div>
                    <JobStatusBadge
                      userRole={userInfo.role}
                      jobStatus={job.statusCurrent}
                      quoteStatus={job.quoteStatusCurrent}
                      productionStatus={job.productionStatusCurrent}
                    />
                    {RBA_CAD.indexOf(userInfo.role) >= 0 && job.cadCurrent && <p>Assigned to: {job.cadCurrent.name}</p>}{' '}
                    Submitted by: {job.author?.name} | {job.owner?.companyName}{' '}
                    {job.author?.phone && `(${job.author?.phone})`}
                  </div>

                  {/* {(RBA_CAD.indexOf(userInfo.role) >= 0 ||
                    (userInfo.hasQuoter &&
                      RBA_CLIENT.indexOf(userInfo.role) >= 0)) && (
                    <>
                      <div>Update Status--</div>
                      <div>
                        <JobStatusBadgeUpdate
                          userRole={userInfo.role}
                          jobId={job._id}
                          jobStatus={job.statusCurrent}
                          quoteStatus={job.quoteStatusCurrent}
                          productionStatus={job.productionStatusCurrent}
                        />
                      </div>
                    </>
                  )} */}

                  {job.street || job.mrmAddress ? (
                    <div className="embed-responsive embed-responsive-21by9">
                      <iframe className="embed-responsive-item" title="map" src={googleMaps} allowFullScreen>
                        {' '}
                      </iframe>
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>

              <Row className="no-flex">
                {/* Row for submitted and draft files */}
                <Row>
                  {subFiles && subFiles.length > 0 ? (
                    <Col>
                      <p className={RBA_CAD.indexOf(userInfo.role) >= 0 ? 'job-hl-files' : ''}>
                        <span className="badge rounded-pill bg-secondary">
                          <i className="fas fa-file" /> Files
                        </span>
                        <strong>Files submitted with job:</strong>
                        {subFiles.map((file) => (
                          <>
                            <br />
                            <a href={file.url} without target="_blank" rel="noopener noreferrer">
                              {file.name}
                            </a>
                          </>
                        ))}
                      </p>
                    </Col>
                  ) : (
                    ''
                  )}

                  {RBA_CAD.indexOf(userInfo.role) >= 0 && draftFiles && draftFiles.length > 0 ? (
                    <Col>
                      <ListGroup variant="flush" className="clean-list-group">
                        Current Draft Files:
                        {draftFiles.map((file) => (
                          <a
                            href={file.url}
                            without
                            target="_blank"
                            rel="noopener noreferrer"
                            className="list-group-item list-group-item-action list-group-item-secondary clean-list-group"
                          >
                            {file.name}
                          </a>
                        ))}
                      </ListGroup>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
                {/* END Row for submitted and draft files */}
                {/* {(userInfo.hasQuoter ||
                  RBA_CAD.indexOf(userInfo.role) >= 0) && (
                  <Row>
                    <QuoteIndexJob jobId={job._id} />
                  </Row>
                )}
                {(userInfo.hasQuoter || RBA_CAD.indexOf(userInfo.role) >= 0) &&
                  job.quotePdf && (
                    <Row>
                      <QuotePdfReleaseJob quotePdf={job.quotePdf} />
                    </Row>
                  )} */}

                {/* Row for current and old release files */}
                <Card style={{ width: '100%' }}>
                  <Row>
                    {currentReleaseFiles && currentReleaseFiles.length > 0 ? (
                      <Col lg={6}>
                        <ListGroup variant="flush" className="clean-list-group">
                          <h5>
                            <i class="fad fa-clipboard-check" /> Current Version Files:
                          </h5>
                          {currentReleaseFiles.map((file) => (
                            <>
                              <Row>
                                <Col className="px-0">
                                  <a
                                    href={file.url}
                                    without
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="list-group-item list-group-item-action list-group-item-secondary clean-list-group"
                                    key={file.url}
                                  >
                                    <strong>Rev{file.jobRev}:</strong> {file.name}
                                  </a>
                                </Col>
                              </Row>
                            </>
                          ))}
                        </ListGroup>
                      </Col>
                    ) : (
                      ''
                    )}

                    {oldReleaseFiles && oldReleaseFiles.length > 0 ? (
                      <Col lg={6}>
                        <ListGroup variant="flush" className="clean-list-group">
                          <h5>
                            <i class="fad fa-history" /> Older Files:
                          </h5>

                          {oldReleaseFiles
                            .sort((a, b) => a.jobRev - b.jobRev)
                            .map((file) => (
                              <>
                                <Row>
                                  <Col className="px-0">
                                    <a
                                      href={file.url}
                                      without
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="list-group-item list-group-item-action list-group-item-warning clean-list-group"
                                      key={file.url}
                                    >
                                      <strong>Rev{file.jobRev}:</strong> {file.name}
                                    </a>
                                  </Col>
                                </Row>
                              </>
                            ))}
                        </ListGroup>
                      </Col>
                    ) : (
                      ''
                    )}

                    {importFiles && importFiles.length > 0 ? (
                      <Col lg={6}>
                        <ListGroup variant="flush" className="clean-list-group">
                          <h5>
                            <i class="fad fa-exclamation-square" /> Imported Files--Check to make sure these are
                            correct!:
                          </h5>

                          {importFiles.map((file) => (
                            <ListGroup.Item action variant="warning" href={file.url} className="clean-list-group">
                              {file.name}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                </Card>
                {/* Row for current and old release files */}
              </Row>
              {/* Admin Actions */}
              <div className="no-flex align-items-center">
                {RBA_ADMIN.indexOf(userInfo?.role) >= 0 && job.statusCurrent === 'Submitted' && (
                  <JobValidate jobId={jobId} isMrm={isMrm} cardWidth="22rem" />
                )}
                {RBA_CAD.indexOf(userInfo?.role) >= 0 && job && job?.owner?.inits && <JobCopyData job={job} />}
                {RBA_CAD.indexOf(userInfo?.role) >= 0 &&
                  job.statusCurrent === 'Validated' &&
                  job.cadCurrent == null && (
                    <button className="btn btn-warning mx-2" onClick={assignHandler}>
                      <i className="fas fa-briefcase"></i> Assign to self
                    </button>
                  )}
                {((RBA_CAD.indexOf(userInfo?.role) >= 0 && job.cadCurrent?._id == userInfo._id) ||
                  RBA_ADMIN.indexOf(userInfo?.role) >= 0) &&
                  job.statusCurrent === 'Working' &&
                  job.cadCurrent != null && (
                    <button className="btn btn-danger mx-2" onClick={unassignHandler}>
                      <i className="fas fa-undo"></i> Unassign Job
                    </button>
                  )}
              </div>
              <hr></hr>
            </Card.Body>
          </Card>
        </>
      ) : (
        ''
      )}
    </>
  )
}
