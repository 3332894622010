import React, { useState, useEffect } from 'react'
import {} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import MaterialTable from 'material-table'
import { updateClient } from '../../actions/clientActions'
import ErrorBoundary from '../ErrorBoundary'

export const ClientRatioTable = ({ client }) => {
  const [list, setList] = useState([])

  useEffect(() => {
    if (client && client.ratioLists != null) {
      setList(client.ratioLists)
    }
  }, [client])

  const dispatch = useDispatch()

  const handleSaveList = () => {
    console.log('update')
    const updatedClient = {
      _id: client._id,
      ratioLists: list,
    }
    dispatch(updateClient(updatedClient))
  }

  const handleRowAdd = (newData, resolve, reject) => {
    try {
      const tempList = [...list, newData]
      setList(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    try {
      const tempList = [...list]
      const index = tempList.indexOf(oldData)
      tempList[index] = newData
      setList(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }
  const handleCellUpdate = (newValue, rowData, columnDef, resolve) => {
    const newData = rowData
    newData[columnDef.field] = newValue
    console.log('newData')
    console.log(newData)
    let tempList = list
    tempList.filter((item) => item !== rowData).push(newData)
    setList(tempList)
    resolve()
  }
  const handleRowDelete = (oldData, resolve, reject) => {
    try {
      let tempList = [...list]
      const index = tempList.indexOf(oldData)
      tempList.splice(index, 1)
      setList(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }

  const handleBulkEdit = (changes, resolve, reject) => {
    try {
      console.log(changes)
      let tempList = [...list]
      for (const line in changes) {
        console.log(changes[line])
        const index = tempList.indexOf(changes[line].oldData)
        tempList[index] = changes[line].newData
      }
      setList(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }

  const downloadHeaders = [
    { label: 'Code', key: 'code' },
    { label: 'Item', key: 'item' },
    { label: 'Ratio', key: 'ratio' },
  ]

  return (
    <>
      {client && (
        <>
          <ErrorBoundary>
            <CSVLink data={list} headers={downloadHeaders} filename={'RatioList-download.csv'}>
              <Button className='mb-3' variant='secondary'>
                <i className='fas fa-file-download' /> Download Data
              </Button>
            </CSVLink>
          </ErrorBoundary>
          <div>
            Any edits directly in the table do NOT auto save! <br />
            Be sure to click Save early and often. <br />
            <Button className='my-3' variant='primary' onClick={handleSaveList}>
              <i className='fas fa-save' /> SAVE
            </Button>
          </div>
          <MaterialTable
            columns={[
              { title: 'Trim or Panel Code', field: 'code' },
              { title: 'Code for accessory to add', field: 'item' },
              { title: 'Ratio (per ft)', field: 'ratio' },
            ]}
            title='Accessory Ratios'
            data={list}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve) => {
                  handleCellUpdate(newValue, rowData, columnDef, resolve)
                })
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  handleRowUpdate(newData, oldData, resolve, reject)
                }),
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  handleRowAdd(newData, resolve, reject)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  handleRowDelete(oldData, resolve, reject)
                }),
              onBulkUpdate: (changes) =>
                new Promise((resolve, reject) => {
                  handleBulkEdit(changes, resolve, reject)
                }),
            }}
            options={{
              paging: true,
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
              searchFieldAlignment: 'left',
              toolbarButtonAlignment: 'left',
            }}
          />
        </>
      )}
    </>
  )
}
