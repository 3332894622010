import React, { useEffect } from 'react'
import {} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { Loader, Message } from '../../components/componentLib'
import { getAllUsers } from '../../actions/userActions'

export const UserIndex = ({ userInfo, history }) => {
  const userIndex = useSelector((state) => state.userIndex)
  const { loading, error, users } = userIndex

  const dispatch = useDispatch()
  useEffect(() => {
    if (userInfo) {
      console.log('dispatching...')
      dispatch(getAllUsers())
    }
  }, [dispatch, userInfo])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : users ? (
        <div style={{ maxWidth: '75%' }}>
          <MaterialTable
            columns={[
              {
                title: 'Name',
                field: 'name',
                render: (rowData) => <a href={`users/${rowData._id}`}>{rowData.name}</a>,
              },
              { title: 'Email', field: 'email' },
              { title: 'Company', field: 'companyName' },
              { title: 'Inits', field: 'initials' },
            ]}
            title='All Users'
            data={users}
            options={{
              paging: true,
              pageSize: 25, // make initial page size
              emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
            }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
