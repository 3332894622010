import React, { useState } from 'react'

export const ScrollArrow = ({ linesRef, cutlistRef }) => {
  const [showScroll, setShowScroll] = useState(true)

  // const checkScrollTop = () => {
  //   if (!showScroll && window.pageYOffset > 400) {
  //     setShowScroll(true)
  //   } else if (showScroll && window.pageYOffset <= 400) {
  //     setShowScroll(false)
  //   }
  // }
  //window.addEventListener('scroll', checkScrollTop)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const scrollLines = () => {
    console.log(linesRef)
    linesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const scrollPanel = () => {
    cutlistRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <i
        className='scrollTop fad fa-address-card fa-2x fa-theme'
        onClick={scrollTop}
        style={{
          transform: 'translate(0,-225%)',
          height: 40,
          display: showScroll ? 'flex' : 'none',
        }}
      />
      <i
        className='scrollTop fad fa-line-columns fa-2x fa-theme'
        onClick={scrollLines}
        style={{
          transform: 'translate(0,-112%)',
          height: 40,
          display: showScroll ? 'flex' : 'none',
        }}
      />
      <i
        className='scrollTop fad fa-cut fa-2x fa-theme'
        onClick={scrollPanel}
        style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
      />
    </>
  )
}
