import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateClient } from '../../actions/clientActions'

export const ClientMatchImportModal = ({ openState, client }) => {
  const dispatch = useDispatch()

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [file, setFile] = useState('')
  const [formData, setFormData] = useState('')
  const [overwriteAll, setOverwriteAll] = useState(false)
  const [fileWarning, setFileWarning] = useState(false)

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }

  const handleFileChange = (e) => {
    e.preventDefault()
    if (e.target.value.slice(-4) === '.csv') {
      setFileWarning(false)
      setFile(e.target.value)
      const tempFormData = new FormData()
      tempFormData.append(`file`, e.target.files[0], 'tempMatchFile.csv')
      setFormData(tempFormData)
    } else {
      console.log(`filetype: ${e.target.value.slice(-4)}`)
      setFileWarning(true)
    }
  }

  const handleOverwriteChange = () => {
    setOverwriteAll(!overwriteAll)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (fileWarning) {
    } else {
      const tempFormData = formData
      tempFormData.append('overwrite', overwriteAll)
      setFormData(tempFormData)
      dispatch(updateClient(formData, 'match', client._id))
      setIsOpen(false)
    }
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Import Match Table from File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          Upload CSV file below. The data must be arranged as such:
          <ul>
            <li>Column A: Code (ED, VF, etc)</li>
            <li>Column B: Panel </li>
            <li>Column C: Paint (SMP, GLM, etc) </li>
            <li>Column D: Metal (26, 24, 032, etc)</li>
            <li>Column E: Color</li>
            <li>Column F: SKU</li>
          </ul>
        </div>
        <div className='text-center'>
          <a href='https://files.metalroofquoter.com/public-resources/match%20import.csv'>
            Download Template CSV file
          </a>
          <div className='text-muted'>
            When you save the file, Excel may ask you if you want to keep using the CSV
            format--select YES.
          </div>
        </div>
        <hr />
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='file'>
            <Form.Label>Upload File Here</Form.Label>
            <Form.Control
              type='file'
              accept='.csv'
              value={file}
              onChange={handleFileChange}
              isInvalid={fileWarning}
              required
            ></Form.Control>
            <Form.Control.Feedback type='invalid'>
              Must be a CSV file! Please select again.
            </Form.Control.Feedback>
          </Form.Group>
          <span onClick={handleOverwriteChange} className={overwriteAll ? 'ml-2 mark' : 'ml-2 del'}>
            DELETE ALL DATA and replace with this file
          </span>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {overwriteAll}
        <Button variant='secondary' onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant='primary' onClick={submitHandler} disabled={fileWarning || file === ''}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
