import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export const FormContainer = ({ children }) => {
  return (
    <Container fluid>
      <Row className='justify-content-md-center'>
        <Col>{children}</Col>
      </Row>
    </Container>
  )
}
