import React, { useState } from 'react'
import Moment from 'react-moment'
import 'moment-timezone'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import Linkify from 'react-linkify'

import { Loader } from '../componentLib'

export const UserDetailAdminInfo = ({ user, userInfo }) => {
  const [isCopied, setIsCopied] = useState(false)
  const getPwLink = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(
      `/api/v1/admin/utils/get-pw-link/${user.email?.toLowerCase()}`,
      config
    )
    console.log(data)
    navigator.clipboard.writeText(data.pwLink)
    setIsCopied(true)
  }
  return (
    <>
      {!user ? (
        <Loader />
      ) : user ? (
        <Card className="mb-2">
          <h4>Info submitted by user:</h4>
          <p>
            Company Name: <strong>{user.introCompanyName}</strong>
          </p>
          <p>
            Company Type: <strong>{user.introCompanyType}</strong>
          </p>
          <p>
            Company Website:{' '}
            <a
              href={user.introCompanyWebsite}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkify>
                <strong>{user.introCompanyWebsite}</strong>
              </Linkify>
            </a>
          </p>
          <p>
            {' '}
            Notes: <strong>{user.introNotes}</strong>
          </p>
          <p>
            Last Login:{' '}
            <strong>
              <Moment format="MMMM Do YYYY, h:mm:ss a">{user.lastLogin}</Moment>
            </strong>
          </p>
          <p>
            Activated? <strong>{user.userSetupComplete?.toString()}</strong>
          </p>
          <p>
            Force pw reset? <strong>{user.forcePwReset?.toString()}</strong>
          </p>
          <p>
            Account on hold/Billing issue?{' '}
            <strong>{user.billingHold?.toString()}</strong>
          </p>
          <p>
            <span className="click-to-copy" onClick={getPwLink}>
              Get manual password reset link
            </span>
            <span className={isCopied && 'mark'}>
              {isCopied ? 'Copied!' : ''}
            </span>
          </p>
        </Card>
      ) : (
        ''
      )}
    </>
  )
}
