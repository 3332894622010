import React from 'react'

export const AdminPanelHome = () => {
  return (
    <div className='text-center'>
      <div className='my-2'>
        <a href='/jobs?activeOnly=true'>
          <i className='fad fa-briefcase fa-theme' /> ACTIVE jobs
        </a>
      </div>
      <div className='my-2'>
        <a href='/users'>
          <i className='fad fa-users fa-theme' /> Users
        </a>
      </div>
      <div className='my-2'>
        <a href='/clients'>
          <i className='fad fa-sitemap fa-theme' /> Clients
        </a>
      </div>
      <div className='my-2'>
        <a href='/admin/data'>
          {' '}
          <i className='fad fa-chart-line fa-theme' /> Data
        </a>
      </div>
      <div className='my-2'>
        <a href='admin/billing'>
          {' '}
          <i className='fad fa-funnel-dollar fa-theme' /> Billing Panel
        </a>
      </div>
    </div>
  )
}
