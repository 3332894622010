import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { BillingData, QboSetup } from '../../components/componentLib'
import {} from '../../actions/jobActions'
import { RBA_ADMIN } from '../../utils/rba'

export const AdminBillingLayout = ({ history }) => {
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    } else if (RBA_ADMIN.includes(userInfo?.role)) {
      //do nothing
    } else {
      history.push('/')
    }
  }, [userInfo, history])

  return (
    <>
      <BillingData />{' '}
      {/* <Row className='justify-content-md-center'>
        <Col lg={8}>
          Billing info
          
        </Col>
        <Col lg={4} md={4} s={6}>
          qbo setup
          {userInfo && RBA_ADMIN.indexOf(userInfo?.role) >= 0 && <QboSetup />}
        </Col>
      </Row> */}
    </>
  )
}
