import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import {
  CustomerTable,
  CustomerCreateModal,
  CustomerImportModal,
} from '../../components/componentLib'

export const CustomerIndexLayout = ({ history }) => {
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    }
  }, [userInfo, history])

  //LOCAL SCOPE
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)

  const dispatch = useDispatch()

  const handleCreateCustomer = (e) => {
    setCreateModalOpen(!createModalOpen)
  }

  const handleImportCustomer = (e) => {
    setImportModalOpen(!importModalOpen)
  }

  return (
    <>
      {userInfo ? (
        <>
          <div className='px-5 mx-5'>
            <Button className='mb-3' onClick={handleCreateCustomer}>
              <i className='fas fa-plus-circle' /> Create New Customer
            </Button>{' '}
            <Button className='mb-3' variant='info' onClick={handleImportCustomer}>
              <i className='fas fa-file-upload' /> Import From File
            </Button>{' '}
            <CustomerTable userInfo />
          </div>
          {createModalOpen && <CustomerCreateModal openState />}
          {importModalOpen && <CustomerImportModal openState />}
        </>
      ) : (
        ''
      )}
    </>
  )
}
