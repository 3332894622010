import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { duplicateJob } from '../../actions/jobActions'

export const JobDupeModal = ({ jobId, oldName, openState }) => {
  const dispatch = useDispatch()

  //Global State

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [name, setName] = useState(oldName)

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(duplicateJob(jobId, name))
    setIsOpen(false)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Create duplicate job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          For large complex jobs, or if the client asks for a job to be broken
          up, you can create duplicate entries of the job request, potentially
          one for each building or something.
        </p>

        <p>
          Just set the name below for the duplicated job, and it will be
          created. Aaron will take a quick look to approve, so there may be a
          slight delay in getting it back to you--shoot him an email/text if you
          need it right away.
        </p>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name of duplicate job:</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
