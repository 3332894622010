import axios from 'axios'
import {
  CLIENT_CREATE_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_GET_ALL_FAIL,
  CLIENT_GET_ALL_REQUEST,
  CLIENT_GET_ALL_SUCCESS,
  CLIENT_GET_DETAIL_FAIL,
  CLIENT_GET_DETAIL_REQUEST,
  CLIENT_GET_DETAIL_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
} from './constants'

export const createClient = (client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/v1/clients`, client, config)

    dispatch({
      type: CLIENT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CLIENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateClient =
  (client, importFile = false, clientId = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_UPDATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      console.log(`public url: ${process.env.PUBLIC_URL}`)
      console.log(`importFile: ${importFile}`)
      //const baseUrl = process.env.PUBLIC_URL
      const { data } =
        importFile === 'match'
          ? await axios.patch(`/api/v1/clients/${clientId}/importMatch`, client, config)
          : importFile === 'ratio'
          ? await axios.patch(`/api/v1/clients/${clientId}/importRatio`, client, config)
          : await axios.patch(`/api/v1/clients/${client._id}`, client, config)

      dispatch({
        type: CLIENT_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CLIENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getAllClients = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/clients`, config)

    dispatch({
      type: CLIENT_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CLIENT_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getClientDetailById = (clientId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_GET_DETAIL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/clients/${clientId}`, config)

    dispatch({
      type: CLIENT_GET_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CLIENT_GET_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//NON REST

export const updateClientLogo = (clientId, formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log('in action')
    const { data } = await axios.post(`/api/v1/clients/${clientId}/logo`, formData, config)

    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
