import MaterialTable from 'material-table'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { createItem, deleteItemById, updateItem, getAllItems } from '../../actions/itemActions'
import { Loader, Message } from '../../components/componentLib'

export const ItemTable = ({ history, userInfo }) => {
  const dispatch = useDispatch()

  const itemIndex = useSelector((state) => state.itemIndex)
  const { loading, error, items } = itemIndex

  useEffect(() => {
    if (userInfo) {
      dispatch(getAllItems())
    }
  }, [dispatch, userInfo])

  const handleRowAdd = (newData, resolve) => {
    console.log(newData)
    newData.sku = newData.sku.toUpperCase()
    dispatch(createItem(newData))
    resolve()
  }
  const handleRowUpdate = (newData, resolve) => {
    newData.sku = newData.sku.toUpperCase()
    console.log(newData)
    dispatch(updateItem(newData, newData._id))
    resolve()
  }
  const handleCellUpdate = (newValue, rowData, columnDef, resolve) => {
    const newData = rowData
    newData.sku = newData.sku.toUpperCase()
    newData[columnDef.field] = newValue
    console.log('newData')
    console.log(newData)
    dispatch(updateItem(newData, newData._id))
    resolve()
  }
  const handleRowDelete = (newData, resolve) => {
    console.log(newData)
    dispatch(deleteItemById(newData._id))
    resolve()
  }

  const handleBulkEdit = (changes, resolve) => {
    console.log(changes)
    for (const item in changes) {
      console.log(changes[item])
      dispatch(updateItem(changes[item].newData, changes[item].newData._id))
    }
    resolve()
  }

  const downloadHeaders = [
    { label: 'SKU', key: 'sku' },
    { label: 'Description', key: 'description' },
    { label: 'Unit of Measure', key: 'unit' },
    { label: 'Price', key: 'price' },
    { label: 'Price Code', key: 'priceCode' },
    { label: 'Type', key: 'type' },
    { label: 'Drop', key: 'drop' },
    { label: 'Metal', key: 'metal' },
    { label: 'Color', key: 'color' },
    { label: 'Item', key: 'item' },
    { label: 'Markup', key: 'markup' },
    { label: 'Panel', key: 'panel' },
    { label: 'Size', key: 'size' },
    { label: 'Count', key: 'count' },
    { label: 'Lock', key: 'lock' },
    { label: 'Cost', key: 'cost' },
    { label: 'Paint', key: 'paint' },
  ]

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : items ? (
        <>
          <CSVLink data={items} headers={downloadHeaders} filename={'item-download.csv'}>
            <Button className='mb-3' variant='secondary'>
              <i className='fas fa-file-download' /> Download Data
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              {
                title: 'SKU',
                field: 'sku',
              },
              {
                title: 'Description',
                field: 'description',
              },
              { title: 'UoM', field: 'unit' },
              { title: 'Price', field: 'price', type: 'currency' },
              { title: 'Price Code', field: 'priceCode' },
              { title: 'Type', field: 'type' },
              { title: 'Drop', field: 'drop' },
              { title: 'Metal', field: 'metal' },
              { title: 'Color', field: 'color' },
              { title: 'Item', field: 'item' },
              { title: 'Markup', field: 'markup' },
              { title: 'Panel', field: 'panel' },
              { title: 'Size', field: 'size' },
              { title: 'Count', field: 'count' },
              { title: 'Lock', field: 'lock' },
              { title: 'Cost', field: 'cost' },
              { title: 'Paint', field: 'paint' },
            ]}
            title='All Items'
            data={items}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve) => {
                  handleCellUpdate(newValue, rowData, columnDef, resolve)
                })
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve)
                }),
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  handleRowAdd(newData, resolve)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData, resolve)
                }),
              onBulkUpdate: (changes) =>
                new Promise((resolve) => {
                  handleBulkEdit(changes, resolve)
                }),
            }}
            options={{
              grouping: true,
              filtering: true,
              paging: true,
              pageSize: 25, // make initial page size
              emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
            }}
          />
        </>
      ) : (
        ''
      )}
    </>
  )
}
