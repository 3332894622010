import axios from 'axios'
import {
  CUSTOMERS_GET_ALL_REQUEST,
  CUSTOMERS_GET_ALL_SUCCESS,
  CUSTOMERS_GET_ALL_FAIL,
  CUSTOMER_GET_DETAIL_SUCCESS,
  CUSTOMER_GET_DETAIL_FAIL,
  CUSTOMER_GET_DETAIL_REQUEST,
  CUSTOMER_CREATE_REQUEST,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_IMPORT_SUCCESS,
} from './constants'

//CREATE Customer
export const createCustomer =
  (customer, importFile = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOMER_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } =
        importFile === true
          ? await axios.post(`/api/v1/customers/import`, customer, config)
          : await axios.post(`/api/v1/customers`, customer, config)
      const successType = importFile === true ? CUSTOMER_IMPORT_SUCCESS : CUSTOMER_CREATE_SUCCESS

      dispatch({
        type: successType,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CUSTOMER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

//GET ALL CUSTOMERS INDEX
export const getAllCustomers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMERS_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/customers`, config)

    dispatch({
      type: CUSTOMERS_GET_ALL_SUCCESS,
      payload: data,
    })

    localStorage.setItem('customerIndex', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: CUSTOMERS_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//GET Customer DETAILS SHOW
export const getCustomerById = (customerId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_GET_DETAIL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/customers/${customerId}`, config)

    dispatch({
      type: CUSTOMER_GET_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_GET_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//UPDATE Customer
export const updateCustomer = (customer, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.patch(`/api/v1/customers/${id}`, customer, config)

    dispatch({
      type: CUSTOMER_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//DELETE Customer DESTROY
export const deleteCustomerById = (customerId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/v1/customers/${customerId}`, config)

    dispatch({
      type: CUSTOMER_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: CUSTOMER_DELETE_FAIL,
      payload: message,
    })
  }
}
