import React, { useState, useEffect } from 'react'

import { Card } from 'react-bootstrap'

export const QuoteDetailCutlistPack = ({ cutlist, maxWeight, poundPerLf }) => {
  const [orderedList, setOrderedList] = useState([])
  const [packingList, setPackingList] = useState([])
  const [totalLength, setTotalLength] = useState(0)
  const [totalPanels, setTotalPanels] = useState(0)
  const [packListMeta, setPackListMeta] = useState([])

  useEffect(() => {
    if (cutlist) {
      updateLists(cutlist)
    }
  }, [cutlist, maxWeight, poundPerLf])

  const correctFtIn = (length) => {
    return [Math.floor(length / 12), length % 12]
  }

  const getOrderedList = (list) => {
    const positiveLen = list.filter((item) => item.linearFeet > 0)
    return positiveLen.sort((a, b) =>
      a.linearFeet > b.linearFeet ? -1 : b.linearFeet > a.linearFeet ? 1 : 0
    )
  }

  const explodeList = (list) => {
    if (list) {
      let explodedList = []
      list.forEach((line) => {
        let newEntry = line
        for (let x = 0; x < line.quantity; x++) {
          explodedList.push(newEntry)
        }
      })
      return explodedList
    }
    return list
  }

  const getPackingList = (list) => {
    if (list) {
      //packList is subset list of packingList
      let explodedList = explodeList(list)
      let weightCounter = 0
      let panelCounter = 1
      let packList = []
      let packingList = []
      explodedList.forEach((line, idx) => {
        weightCounter = weightCounter + line.weight
        let newEntry = line
        panelCounter++
        weightCounter <= maxWeight && packList.push(newEntry) //add new line
        //push existing packList to packingList and this is the first new line
        if (weightCounter > maxWeight) {
          panelCounter = 1
          weightCounter = line.weight
          packingList.push(packList)
          packList = []
          packList.push(newEntry)
        }
        //Push last line when at end of explodedlist
        idx === explodedList.length - 1 && packingList.push(packList)
      })
      return packingList
    }
    return list
  }

  const groupPackLists = (list) => {
    if (list) {
      let groupedPackingList = []
      list.forEach((line, idx) => {
        let groupedArray = groupArrays(line)
        groupedPackingList[idx] = groupedArray
      })
      return groupedPackingList
    }
    return list
  }

  const getPackListMeta = (packList) => {
    let packListMeta = []
    let metaTotal = []
    packList.forEach((group, idx) => {
      if (group.length === 1) {
        // console.log('metaData for one:')
        // console.log(group)
        packListMeta.push({
          weight: group[0].weight * group[0].quantity,
          linearFeet: group[0].linearFeet * group[0].quantity,
          panels: group[0].quantity,
        })
      } else {
        // console.log('metaData for one+:')
        // console.log(group)
        metaTotal = getTotalPansLength(group)
        // console.log(metaTotal)
        packListMeta.push({
          weight: metaTotal[1] * poundPerLf,
          linearFeet: metaTotal[1],
          panels: metaTotal[0],
        })
      }
    })
    return packListMeta
  }

  const groupArrays = (inputList) => {
    if (inputList) {
      let groupedArray = []
      let qtyCounter = 1
      let newEntry = {}
      if (inputList.length == 1) {
        newEntry = {
          quantity: qtyCounter,
          feet: inputList[0].feet,
          inch: inputList[0].inch,
          linearFeet: inputList[0].linearFeet,
          weight: inputList[0].weight,
        }
        groupedArray.push(newEntry)
        return groupedArray
      }
      inputList.forEach(function (item, idx) {
        if (idx > 0) {
          if (item.linearFeet == inputList[idx - 1].linearFeet) {
            qtyCounter++
          } else {
            newEntry = {
              quantity: qtyCounter,
              feet: inputList[idx - 1].feet,
              inch: inputList[idx - 1].inch,
              linearFeet: inputList[idx - 1].linearFeet,
              weight: inputList[idx - 1].weight,
            }
            qtyCounter = 1
            groupedArray.push(newEntry)
          }
          if (idx == inputList.length - 1) {
            newEntry = {
              quantity: qtyCounter,
              feet: inputList[idx].feet,
              inch: inputList[idx].inch,
              linearFeet: inputList[idx].linearFeet,
              weight: inputList[idx].weight,
            }
            groupedArray.push(newEntry)
          }
        }
      })
      return groupedArray
    }
    return inputList
  }

  const getTotalPansLength = (list) => {
    if (list.length > 0) {
      const qtyArray = list.map((item) => item.quantity)
      const lenArray = list.map((item) => item.linearFeet)
      // console.log('lenArray')
      // console.log(lenArray)
      // console.log('qtyArray')
      // console.log(qtyArray)
      const numPans = qtyArray.reduce((total, num) => total * 1 + num * 1)
      const totalLength = qtyArray.reduce(function (total, num, idx) {
        return total * 1 + num * lenArray[idx]
      }, 0)
      // console.log(`total len: ${totalLength}`)
      return [numPans, totalLength]
    } else return [0, 0]
  }

  const updateLists = (list) => {
    const orderedListInt = getOrderedList(list)
    // console.log('ordered list:')
    // console.log(orderedListInt)
    const packingList = getPackingList(orderedListInt)
    // console.log('packList')
    // console.log(packingList)
    const groupedPackingList = groupPackLists(packingList)
    // console.log('list in:')
    // console.log(list)
    // console.log('groupedPackingList')
    // console.log(groupedPackingList)
    const metaData = getPackListMeta(groupedPackingList)
    // console.log('meta data')
    // console.log(metaData)
    const metaTotal = getTotalPansLength(orderedListInt)
    // console.log('input list for updatelists')
    // console.log(orderedList)
    // console.log('metaTotal in updatelists')
    // console.log(metaTotal)
    setTotalPanels(metaTotal[0])
    setTotalLength(metaTotal[1])
    setPackListMeta(metaData)
    setOrderedList(orderedListInt)
    setPackingList(groupedPackingList)
  }

  const getTwoDigitFeet = (input, type = 'inch') => {
    if (type === 'inch') {
      return Math.round((input / 12) * 100) / 100
    } else {
      return Math.round(input * 100) / 100
    }
  }

  return (
    <>
      {cutlist ? (
        <>
          <Card>
            {orderedList && (
              <>
                {totalPanels} panel(s) | {getTwoDigitFeet(totalLength, 'feet')} LF |{' '}
                {getTwoDigitFeet(totalLength, 'feet') * poundPerLf} lbs
                {orderedList.map((line, index) => (
                  <li key={index}>
                    {line.quantity} @ {line.feet} ft {line.inch} in
                  </li>
                ))}
              </>
            )}
            <hr />
            {packingList && (
              <>
                {packingList.map((group, index) => (
                  <>
                    <li key={index}>
                      Pack: {index + 1}: {packListMeta[index].panels} panel(s) |{' '}
                      {getTwoDigitFeet(packListMeta[index].linearFeet, 'feet')} LF |{' '}
                      {getTwoDigitFeet(packListMeta[index].weight, 'feet')} lbs
                    </li>
                    {group.map((panel, index) => (
                      <li key={index}>
                        {panel.quantity} @ {panel.feet} ft {panel.inch} in
                      </li>
                    ))}
                  </>
                ))}
              </>
            )}
          </Card>
        </>
      ) : (
        ''
      )}
    </>
  )
}
