import axios from 'axios'
import {
  QUOTES_GET_ALL_REQUEST,
  QUOTES_GET_ALL_SUCCESS,
  QUOTES_GET_ALL_FAIL,
  QUOTE_GET_DETAIL_SUCCESS,
  QUOTE_GET_DETAIL_FAIL,
  QUOTE_GET_DETAIL_REQUEST,
  QUOTE_CREATE_REQUEST,
  QUOTE_CREATE_SUCCESS,
  QUOTE_CREATE_FAIL,
  QUOTE_UPDATE_REQUEST,
  QUOTE_UPDATE_SUCCESS,
  QUOTE_UPDATE_FAIL,
  QUOTE_DELETE_REQUEST,
  QUOTE_DELETE_SUCCESS,
  QUOTE_DELETE_FAIL,
  QUOTES_GET_BY_JOB_ID_REQUEST,
  QUOTES_GET_BY_JOB_ID_SUCCESS,
  QUOTES_GET_BY_JOB_ID_FAIL,
} from './constants'

//CREATE Quote
export const createQuote = (quote) => async (dispatch, getState) => {
  console.log('quote in CreateQuote')
  console.log(quote)
  try {
    dispatch({
      type: QUOTE_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/v1/quotes`, quote, config)

    dispatch({
      type: QUOTE_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: QUOTE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//GET ALL Quotes INDEX
export const getAllQuotes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTES_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/quotes`, config)

    dispatch({
      type: QUOTES_GET_ALL_SUCCESS,
      payload: data,
    })

    //localStorage.setItem('quoteIndex', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: QUOTES_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//GET Quotes by JOB ID
export const getQuotesByJobId = (jobId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTES_GET_BY_JOB_ID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/quotes/job/${jobId}`, config)

    dispatch({
      type: QUOTES_GET_BY_JOB_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: QUOTES_GET_BY_JOB_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//GET Quote DETAILS SHOW
export const getQuoteById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_GET_DETAIL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/quotes/${quoteId}`, config)

    dispatch({
      type: QUOTE_GET_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: QUOTE_GET_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//UPDATE Quote
export const updateQuote = (quote, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log('quote in update quote:')
    console.log(quote)
    const { data } = await axios.patch(`/api/v1/quotes/${id}`, quote, config)
    console.log(data)

    dispatch({
      type: QUOTE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: QUOTE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//Print Quote PDF (Using Update Quote)
export const printQuoteToPdf = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/v1/quotes/${id}/print`, id, config)
    console.log('data from print quote action')
    console.log(data)
    dispatch({
      type: QUOTE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: QUOTE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//DELETE Quote PDF (Using Update Quote)
export const deleteQuotePdf = (id, url) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      `/api/v1/quotes/${id}/delete`,
      { url },
      config
    )
    console.log('data from delete quote action')
    console.log(data)
    dispatch({
      type: QUOTE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: QUOTE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

//PUBLISH Quote PDF (Using Update Quote)
export const publishQuoteToJob =
  (jobId, quoteId, quote) => async (dispatch, getState) => {
    try {
      dispatch({
        type: QUOTE_UPDATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(
        `/api/v1/quotes/${quoteId}/publish`,
        { quote, jobId },
        config
      )
      console.log('data from publish pdf action')
      console.log(data)
      dispatch({
        type: QUOTE_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: QUOTE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

//DELETE Quote DESTROY
export const deleteQuoteById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUOTE_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/v1/quotes/${quoteId}`, config)

    dispatch({
      type: QUOTE_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: QUOTE_DELETE_FAIL,
      payload: message,
    })
  }
}
