import React from 'react'
import { Alert } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'

import { updateUserProfile } from '../actions/userActions'

import { useDispatch } from 'react-redux'

export const Message = ({ type, userId, variant, children, cb }) => {
  const dispatch = useDispatch()

  const handleLogoPromptDismiss = (userId) => {
    console.log(userId)
    dispatch(
      updateUserProfile(
        {
          id: userId,
          logoPrompt: false,
        },
        'profile' //profile is the user self edit option
      )
    )
    cb()
  }

  return (
    <CSSTransition timeout={1000} classNames="message">
      {/* <div className={`alert alert-dismissible alert-${variant}`}>
        <button type='button' className='btn-close' data-bs-dismiss='alert'></button>
        <strong>Oh snap!</strong> {children}
      </div> */}
      <Alert className="message" variant={variant}>
        {children}
        {type === 'logo-prompt' && (
          <button
            onClick={() => handleLogoPromptDismiss(userId)}
            type="button"
            className="btn-close float-end"
            data-bs-dismiss="alert"
          ></button>
        )}
      </Alert>
    </CSSTransition>
  )
}

Message.defaultProps = {
  variant: 'info',
}
