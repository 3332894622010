import React, { useEffect } from 'react'
import {} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { Loader, Message } from '../../components/componentLib'
import { getAllClients } from '../../actions/clientActions'

export const ClientIndex = ({ userInfo, history }) => {
  const clientIndex = useSelector((state) => state.clientIndex)
  const { loading, error, clients } = clientIndex

  console.log(clientIndex)

  const dispatch = useDispatch()
  useEffect(() => {
    if (userInfo) {
      console.log('dispatching...')
      dispatch(getAllClients())
    }
  }, [dispatch, userInfo])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : clients ? (
        <div style={{ maxWidth: '75%' }}>
          <MaterialTable
            columns={[
              {
                title: 'Name',
                field: 'companyName',
                render: (rowData) => <a href={`clients/${rowData._id}`}>{rowData.companyName}</a>,
              },
              { title: 'Inits', field: 'inits' },
              { title: 'Number', field: 'num' },
            ]}
            title='All Clients'
            data={clients}
            options={{
              paging: true,
              pageSize: 25, // make initial page size
              emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
            }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
