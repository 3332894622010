import {
  ITEMS_GET_ALL_REQUEST,
  ITEMS_GET_ALL_SUCCESS,
  ITEMS_GET_ALL_FAIL,
  // ITEM_GET_DETAIL_REQUEST,
  // ITEM_GET_DETAIL_SUCCESS,
  // ITEM_GET_DETAIL_FAIL,
  ITEM_CREATE_SUCCESS,
  ITEM_CREATE_REQUEST,
  ITEM_CREATE_FAIL,
  ITEM_UPDATE_REQUEST,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAIL,
  ITEM_DELETE_REQUEST,
  ITEM_DELETE_SUCCESS,
  ITEM_DELETE_FAIL,
  ITEM_IMPORT_SUCCESS,
} from '../actions/constants'

export const itemIndexReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case ITEMS_GET_ALL_REQUEST:
      return { itemLoading: true, ...state }
    case ITEMS_GET_ALL_SUCCESS:
      return { itemLoading: false, items: action.payload }
    case ITEMS_GET_ALL_FAIL:
      return { itemLoading: false, itemError: action.payload }
    case ITEM_CREATE_REQUEST:
      return { loadingCreate: true, ...state }
    case ITEM_CREATE_SUCCESS:
      return { loadingCreate: false, items: [action.payload, ...state.items] }
    case ITEM_IMPORT_SUCCESS:
      return { loadingCreate: false, items: action.payload }
    case ITEM_CREATE_FAIL:
      return { loadingCreate: false, errorCreate: action.payload }
    case ITEM_DELETE_REQUEST:
      return { loading: true, ...state }
    case ITEM_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        items: state.items.filter((item) => item._id !== action.payload.itemId),
      }
    case ITEM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case ITEM_UPDATE_REQUEST:
      return { loading: true, ...state }
    case ITEM_UPDATE_SUCCESS:
      const index = state.items.findIndex((item) => item._id === action.payload._id)
      const updatedItems = [...state.items]
      updatedItems[index] = action.payload
      return {
        loading: false,
        items: updatedItems,
      }
    case ITEM_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// export const itemDetailReducer = (state = { item: [] }, action) => {
//   switch (action.type) {
//     case ITEM_GET_DETAIL_REQUEST:
//       return { loading: true, ...state }
//     case ITEM_GET_DETAIL_SUCCESS:
//       return { loading: false, item: action.payload }
//     case ITEM_GET_DETAIL_FAIL:
//       return { loading: false, error: action.payload }
//     default:
//       return state
//   }
// }
