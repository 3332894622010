import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Col, Card } from 'react-bootstrap'
import { Message, Loader, FormContainer } from '../componentLib'
import { createClient } from '../../actions/clientActions'

export const ClientNewForm = ({ data }) => {
  //Global Scope
  const clientIndex = useSelector((state) => state.clientIndex)
  const { loadingCreate, errorCreate } = clientIndex

  //Component scope
  const [companyName, setCompanyName] = useState('')
  const [initials, setInitials] = useState('')
  const [validateInits, setValidateInits] = useState(false)
  const [gdriveFolderId, setGdriveFolderId] = useState('')

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    console.log('submit')
    e.preventDefault()
    const newClient = {
      companyName,
      inits: initials,
      gdriveFolderId,
    }
    dispatch(createClient(newClient))
    setCompanyName('')
    setInitials('')
    setGdriveFolderId('')
  }

  const handleInitials = (value) => {
    if (value.length > 3) return
    value.length === 3 ? setValidateInits(true) : setValidateInits(false)
    setInitials(value.toUpperCase())
  }

  return (
    <>
      {loadingCreate ? (
        <Loader />
      ) : errorCreate ? (
        <Message variant="danger">{errorCreate}</Message>
      ) : (
        <FormContainer>
          <Card>
            <h3 className="text-center">Create new Client</h3>
            <Form onSubmit={submitHandler}>
              <Col>
                <Form.Group controlId="companyName">
                  <Form.Label>CompanyName</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Some Metal Roofing Co"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="initials">
                  <Form.Label>initials</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="ABC"
                    value={initials}
                    onChange={(e) => handleInitials(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="gdriveFolderId">
                  <Form.Label>Google Drive Folder ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="1jGvxx2nx8xo-9J7P7PjplLYgxHvZqpDi"
                    value={gdriveFolderId}
                    onChange={(e) => setGdriveFolderId(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>

              <Form.Row>
                <Button
                  type="submit"
                  variant="primary"
                  block
                  disabled={!validateInits}
                >
                  Submit
                </Button>
                {validateInits === false && (
                  <div className="muted">
                    Initials must be exactly 3 letters
                  </div>
                )}
              </Form.Row>
            </Form>
          </Card>
        </FormContainer>
      )}
    </>
  )
}
