import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuoteTable } from '../../components/componentLib'

import axios from 'axios'

export const ToolFlatCalcLayout = ({ history, match }) => {
  const [flatArrIn, setFlatArrIn] = useState([])
  const [flatArrComp, setFlatArrComp] = useState([])
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    }
  }, [userInfo, history])
  console.log('flat calc')

  let url = decodeURIComponent(match.params.url)

  console.log(url)

  // Parsing happens on the server...move to client eventually
  useEffect(() => {
    const SERVER_URI = '/' //'http://localhost:3000/'
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log('flatsCalc')
    axios
      .post(`${SERVER_URI}api/v1/quotes/parseFlats`, { url }, config)
      .then((res) => {
        console.log('RESPONSE FROM SERVER TO CLIENT:', res.data)
        setFlatArrIn(res.data)
        // setSuccess(true)
      })
      .catch((err) => {
        console.log('SERVER ERROR TO CLIENT:', err)
        // setError(err)
      })
  }, [url, userInfo])

  // useEffect(() => {
  //   flat
  // }, [flatArrIn, flattArrComp])

  //Eventually open up as editable form to handjam
  //Set up include/exclude options
  //Set up option to optimize (fill largest first, then small ones to minimimize waste) OR standard for all of type on one sheet

  return (
    <>
      {userInfo ? (
        <>
          {flatArrIn !== [] ? (
            <div className="px-5 mx-5">
              Trim list:{' '}
              {flatArrIn.map((item) => (
                <div key={item?.code}>
                  {item?.code} : {item?.quantity}
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}
