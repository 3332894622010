import {
  QUOTES_GET_ALL_REQUEST,
  QUOTES_GET_ALL_SUCCESS,
  QUOTES_GET_ALL_FAIL,
  QUOTE_GET_DETAIL_REQUEST,
  QUOTE_GET_DETAIL_SUCCESS,
  QUOTE_GET_DETAIL_FAIL,
  QUOTE_CREATE_SUCCESS,
  QUOTE_CREATE_REQUEST,
  QUOTE_CREATE_FAIL,
  QUOTE_UPDATE_REQUEST,
  QUOTE_UPDATE_SUCCESS,
  QUOTE_UPDATE_FAIL,
  QUOTE_DELETE_REQUEST,
  QUOTE_DELETE_SUCCESS,
  QUOTE_DELETE_FAIL,
  QUOTES_GET_BY_JOB_ID_REQUEST,
  QUOTES_GET_BY_JOB_ID_SUCCESS,
  QUOTES_GET_BY_JOB_ID_FAIL,
} from '../actions/constants'

export const quoteIndexReducer = (state = { quotes: [] }, action) => {
  switch (action.type) {
    case QUOTES_GET_ALL_REQUEST:
      return { loading: true, ...state }
    case QUOTES_GET_ALL_SUCCESS:
      return { loading: false, quotes: action.payload }
    case QUOTES_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    case QUOTE_CREATE_REQUEST:
      return { loadingCreate: true, ...state }
    case QUOTE_CREATE_SUCCESS:
      return {
        loadingCreate: false,
        quotes: [action.payload, ...state.quotes] || [action.payload],
        newQuoteId: action.payload._id,
      }
    case QUOTE_CREATE_FAIL:
      return { loadingCreate: false, errorCreate: action.payload }
    default:
      return state
  }
}

export const quoteJobIndexReducer = (state = { quotes: [] }, action) => {
  switch (action.type) {
    case QUOTES_GET_BY_JOB_ID_REQUEST:
      return { loading: true, ...state }
    case QUOTES_GET_BY_JOB_ID_SUCCESS:
      return { loading: false, quotes: action.payload }
    case QUOTES_GET_BY_JOB_ID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const quoteDetailReducer = (state = { quote: [] }, action) => {
  switch (action.type) {
    case QUOTE_GET_DETAIL_REQUEST:
      return { loading: true, ...state }
    case QUOTE_GET_DETAIL_SUCCESS:
      return { loading: false, quote: action.payload }
    case QUOTE_GET_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case QUOTE_UPDATE_REQUEST:
      return { loading: true, ...state }
    case QUOTE_UPDATE_SUCCESS:
      return { loading: false, quote: action.payload }
    case QUOTE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const quoteDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case QUOTE_DELETE_REQUEST:
      return { loading: true }
    case QUOTE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case QUOTE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
