import React, { useEffect } from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  Message,
  JobSummary,
  JobNewForm,
  Loader,
  WeeklyData,
  DarkMode,
  AdminPanelHome,
  CadPanelHome,
  MongoDash,
} from '../components/componentLib'
import { getUserDetail } from '../actions/userActions'
import { RBA_CAD, RBA_CLIENT, RBA_ADMIN } from '../utils/rba'

export const HomeLayout = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetail = useSelector((state) => state.userDetail)
  const { loading, error, user } = userDetail

  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo & (!user || error)) {
      dispatch(getUserDetail(userInfo?._id))
    }
  }, [userInfo, user, error, dispatch])

  useEffect(() => {
    if (error || !userInfo) {
      history?.push('/login')
    }
  })

  const refreshUser = () => {
    console.log('user refresh triggered')
    user.logoPrompt = false
    dispatch(getUserDetail(userInfo?._id))
  }

  return (
    <>
      {user && userInfo?.name && !error ? (
        <>
          {user.role == null && (
            <div className="inline my-2">
              <Message className="inline my-2" variant="warning">
                Welcome to MRQ, {userInfo.name} We haven't yet activated your
                account.
              </Message>
            </div>
          )}
          {user.client == null && (
            <div className="inline my-2">
              <Message className="inline my-2" variant="warning">
                Hey, {userInfo.name}, we have not set up your company--please
                stand by!
              </Message>
            </div>
          )}
          {user.logoPrompt === true && RBA_CAD.indexOf(userInfo?.role) >= 0 && (
            <div className="inline my-2">
              <Message
                className="inline my-2"
                variant="warning"
                userId={user._id}
                type="logo-prompt"
                cb={refreshUser}
              >
                New logos have been uploaded/updated: run DOWNLOADLOGOS in brics
              </Message>
            </div>
          )}

          <Row>
            <Col lg={12}>
              <Card className="text-center">
                <h3 className="text-center">Metal Roof Quoter</h3>
                <div className="inline my-2">
                  <LinkContainer to="/jobs">
                    <Button className="btn btn-info inline mx-2">
                      <i className="fa fa-list-ul fa-theme" /> See{' '}
                      <span className="text-decoration-underline">
                        <strong>ALL</strong>
                      </span>{' '}
                      Estimates
                    </Button>
                  </LinkContainer>
                  {userInfo?.role === 'client' && (
                    <LinkContainer to="/jobs?mineOnly=true">
                      <Button className="btn btn-secondary inline mx-2">
                        <i className="fa fa-list-ul fa-theme" /> See{' '}
                        <span className="text-decoration-underline">
                          <strong>My</strong>
                        </span>{' '}
                        Estimates
                      </Button>
                    </LinkContainer>
                  )}

                  <span>
                    <div className="float-end">
                      <DarkMode />
                    </div>
                  </span>
                </div>
              </Card>
            </Col>
          </Row>
          {user.client ||
          user.costumerOf != [] ||
          RBA_CAD.indexOf(userInfo?.role) >= 0 ? (
            <Row className="justify-content-md-center">
              <Col sm={12} md={9} lg={8} className="pl-4 pt-3 ml-2">
                {loading ? (
                  <Loader />
                ) : user ? (
                  <JobSummary
                    userRole={userInfo.role}
                    userId={userInfo._id}
                    numJobsIndex={userInfo.numJobsIndex}
                    showCad={userInfo.showCadJobs}
                    showNonCad={userInfo.showNonCadJobs}
                    hasQuoter={userInfo.hasQuoter}
                    showSkittles={userInfo.showSkittles}
                  />
                ) : error ? (
                  <Message
                    variant="warning"
                    className="text-center center-block"
                  >
                    Please{' '}
                    <strong>
                      <LinkContainer to="/login">log back in</LinkContainer>
                    </strong>
                  </Message>
                ) : (
                  ''
                )}
              </Col>

              {RBA_CLIENT.indexOf(userInfo?.role) >= 0 && (
                <Col md={6} lg={4} className="pt-3 pr-4">
                  {RBA_CAD.indexOf(userInfo?.role) >= 0 && (
                    <Card className="mb-3">
                      <>
                        <WeeklyData userRole={userInfo?.role} />
                        {RBA_ADMIN.indexOf(userInfo?.role) >= 0 && (
                          <>
                            <div className="card-header borderless text-center">
                              <i className="fad fa-users-cog fa-theme" />{' '}
                              <strong>Admin Tools</strong>
                            </div>
                            <AdminPanelHome />
                          </>
                        )}
                      </>
                    </Card>
                  )}
                  {userInfo?.role === 'cad' && (
                    <Card className="mb-3">
                      {' '}
                      <>
                        <CadPanelHome />
                      </>
                    </Card>
                  )}
                  {userInfo?.role === 'cad' && (
                    <Card className="mb-3">
                      {' '}
                      <>
                        <MongoDash user={userInfo} />
                      </>
                    </Card>
                  )}
                  {userInfo?.role !== 'cad' && (
                    <JobNewForm clientId={userInfo.client} />
                  )}
                </Col>
              )}
            </Row>
          ) : (
            <>
              <p>We have not yet activated your account--please stand by!</p>
            </>
          )}
        </>
      ) : (
        <p>
          Please <a href="/login">login</a>
        </p>
      )}
    </>
  )
}
