import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createNoteByJobId } from '../../actions/noteActions'
import { Loader } from '../componentLib'
import { RBA_CAD } from '../../utils/rba'

export const NoteCreateModal = ({ jobId, noteAction, openState }) => {
  const dispatch = useDispatch()

  //Global State
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [text, setText] = useState('')
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false)

  //Note flagsLOCAL---
  const [flagCad, setFlagCad] = useState(false)
  const [flagReview, setFlagReview] = useState(false)
  const [flagCorrection, setFlagCorrection] = useState(false)
  const [flagRelease, setFlagRelease] = useState(false)
  const [flagRevision, setFlagRevision] = useState(false)
  const [flagRevisionResponse, setFlagRevisionResponse] = useState(false)
  const [flagRfi, setFlagRfi] = useState(false)
  const [flagRfiResponse, setFlagRfiResponse] = useState(false)
  const [requireText, setRequireText] = useState(false)
  const [headerText, setHeaderText] = useState('Leave a note here')
  const [fileFieldName, setFileFieldName] = useState('noteFile')

  //NOTE related logic---(set flags, set CAD flag, set requireText)

  useEffect(() => {
    if (noteAction === 'Review') {
      setFlagReview(true)
      setRequireText(false)
      setFlagCad(true)
      setFileFieldName('draftFile')
    }
    if (noteAction === 'Correction') {
      setFlagCorrection(true)
      setFlagCad(true)
      setHeaderText('Kick back for corrections')
      setFileFieldName('draftFile')
    }
    if (noteAction === 'Release') {
      setFlagRelease(true)
      setRequireText(false)
    }
    if (noteAction === 'Revision') {
      setFlagRevision(true)
      setFileFieldName('revFile')
      setHeaderText('Request a Revision')
    }
    if (noteAction === 'RevisionResponse') {
      setFlagRevisionResponse(true)
      setHeaderText('Explain to the customer why this is not a revision')
      setRequireText(false)
    }
    if (noteAction === 'Rfi') {
      setFlagRfi(true)
      setHeaderText('Request more info from the customer--')
      setRequireText(false)
    }
    if (noteAction === 'RfiResponse') {
      setFlagRfiResponse(true)
      setHeaderText('Respond to RFI')
      setRequireText(false)
    }
  }, [noteAction])

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    const note = {
      text,
      files,
      flagCad,
      flagReview,
      flagCorrection,
      flagRelease,
      flagRevision,
      flagRevisionResponse,
      flagRfi,
      flagRfiResponse,
    }
    dispatch(createNoteByJobId(jobId, note))
    setIsOpen(false)
  }

  const uploadFilesHandler = async (e) => {
    let totalFileSize = 0
    for (let file in e.target.files) {
      if (Number.isInteger(e.target.files[file].size)) {
        totalFileSize += e.target.files[file].size
      }
    }
    console.log(`total file size: ${totalFileSize}`)
    if (totalFileSize < 25000000) {
      setUploading(true)
      //let files = []

      const formData = new FormData()
      for (let x = 0; x < e.target.files.length; x++) {
        //files.push(e.target.files[x])
        formData.append(
          fileFieldName,
          e.target.files[x],
          e.target.files[x].filename
        )
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const { data } = await axios.post('/api/v1/upload', formData, config)

        setFiles([...files, ...data])
        setUploading(false)
        console.log(files)
      } catch (error) {
        console.error(error)
        setUploading(false)
      }
    } else {
      alert(
        `Those files are too big for the server to handle! (over 25MB!) \n\r Please try emailing, or send us a share link or something.`
      )
    }
  }

  const handleFlagCad = (e) => {
    e.preventDefault()
    setFlagCad(!flagCad)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="text">
            <Form.Label>Add comments/questions/etc</Form.Label>
            <Form.Control
              as="textarea"
              autoFocus
              rows={3}
              placeholder="Note here..."
              value={text}
              required={requireText}
              onChange={(e) => setText(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="files">
            <Form.Label>Upload Files</Form.Label>
            <Form.File
              multiple
              id="file-input"
              label="Choose File(s)"
              custom
              onChange={uploadFilesHandler}
            ></Form.File>
            {/* -OR link to a shared folder- */}
            <Form.Control
              hidden
              type="text"
              placeholder="Enter folder URL"
              value={files}
              onChange={(e) => setFiles(e.target.value)}
            ></Form.Control>
            {uploading && <Loader />}
            {files &&
              files.map((file) => (
                <div index={file}>
                  {decodeURI(file)
                    .split(/(\\|\/)/g)
                    .pop()
                    .substring(20)}
                </div>
              ))}
          </Form.Group>
          {RBA_CAD.indexOf(userInfo.role) >= 0 && (
            <div className="text-muted text-center">
              <span
                onClick={handleFlagCad}
                className={flagCad ? 'ml-2 mark' : 'ml-2 del'}
              >
                Internal CAD only note
              </span>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submitHandler} disabled={uploading}>
          Submit
        </Button>
        {uploading && (
          <Form.Text id="formUploadingHelp" muted>
            Hang on just a sec while these files upload...
          </Form.Text>
        )}
      </Modal.Footer>
    </Modal>
  )
}
