import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import { createNoteByJobId } from '../../actions/noteActions'
import { updateJobStatus } from '../../actions/jobActions'
import { Loader } from '../componentLib'
import { RBA_CAD } from '../../utils/rba'

export const NoteCreateModalMRM = ({ jobId, releaseFiles, noteAction, openState }) => {
  const dispatch = useDispatch()

  //Global State
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [text, setText] = useState('')

  //NOTE related logic---(set flags, set CAD flag, set requireText)

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateJobStatus(jobId, 'release', releaseFiles, { mrmNotes: text }))
    setIsOpen(false)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Notes to MRM Platform</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="text">
            <Form.Label>This is the only note that the MRM user will see</Form.Label>
            <Form.Control
              as="textarea"
              autoFocus
              rows={3}
              placeholder="Note here..."
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
