import React, { useState, useEffect } from 'react'
import {} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import MaterialTable from 'material-table'
import { updateClient } from '../../actions/clientActions'

export const ClientPanelList = ({ client }) => {
  const [panels, setPanels] = useState([])

  useEffect(() => {
    if (client && client.panels != null) {
      setPanels(client.panels)
    }
  }, [client])

  const dispatch = useDispatch()

  const handleSavePanels = () => {
    console.log('update')
    const updatedClient = {
      _id: client._id,
      panels: panels,
    }
    dispatch(updateClient(updatedClient))
  }

  const handleRowAdd = (newData, resolve, reject) => {
    try {
      const tempList = [...panels, newData]
      setPanels(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    try {
      const tempList = [...panels]
      const index = tempList.indexOf(oldData)
      tempList[index] = newData
      setPanels(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }
  const handleCellUpdate = (newValue, rowData, columnDef, resolve) => {
    const newData = rowData
    newData[columnDef.field] = newValue
    console.log('newData')
    console.log(newData)
    let tempList = [...panels]
    tempList.filter((item) => item !== rowData).push(newData)
    setPanels(tempList)
    resolve()
  }
  const handleRowDelete = (oldData, resolve, reject) => {
    try {
      let tempList = [...panels]
      const index = tempList.indexOf(oldData)
      tempList.splice(index, 1)
      setPanels(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }

  const handleBulkEdit = (changes, resolve, reject) => {
    try {
      console.log(changes)
      let tempList = [...panels]
      for (const line in changes) {
        console.log(changes[line])
        const index = tempList.indexOf(changes[line].oldData)
        tempList[index] = changes[line].newData
      }
      setPanels(tempList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }

  return (
    <>
      {client && (
        <>
          <div>
            Any edits directly in the table do NOT auto save! <br />
            Be sure to click Save early and often. <br />
            <Button className='my-3' variant='primary' onClick={handleSavePanels}>
              <i className='fas fa-save' /> SAVE
            </Button>
          </div>
          <MaterialTable
            columns={[
              { title: 'Code', field: 'code' },
              { title: 'Name', field: 'name' },
              { title: 'Coverage (In)', field: 'coverage' },
              {
                title: 'Type',
                field: 'panType',
                lookup: { EF: 'Exposed Fastener', SS: 'Standing seam' },
              },
            ]}
            title='Panels'
            data={panels}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve) => {
                  handleCellUpdate(newValue, rowData, columnDef, resolve)
                })
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  handleRowUpdate(newData, oldData, resolve, reject)
                }),
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  handleRowAdd(newData, resolve, reject)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  handleRowDelete(oldData, resolve, reject)
                }),
              onBulkUpdate: (changes) =>
                new Promise((resolve, reject) => {
                  handleBulkEdit(changes, resolve, reject)
                }),
            }}
            options={{
              paging: true,
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [5, 10, 25], // rows selection options
              searchFieldAlignment: 'left',
              toolbarButtonAlignment: 'left',
            }}
          />
        </>
      )}
    </>
  )
}
