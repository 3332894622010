import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createItem } from '../../actions/itemActions'

export const ItemCreateModal = ({ openState }) => {
  const dispatch = useDispatch()

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [unit, setUnit] = useState('')
  const [price, setPrice] = useState('')
  const [priceCode, setPriceCode] = useState('')

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    const item = {
      sku,
      description,
      unit,
      price,
      priceCode,
    }
    console.log(item)
    dispatch(createItem(item))
    setIsOpen(false)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Create a new Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='sku'>
            <Form.Label>SKU or stock code</Form.Label>
            <Form.Control
              type='text'
              autoFocus
              placeholder='stock code'
              value={sku}
              onChange={(e) => setSku(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='description'>
            <Form.Label>Item description</Form.Label>
            <Form.Control
              type='text'
              placeholder='Describe the item...'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='unit'>
            <Form.Label>Unit of measure</Form.Label>
            <Form.Control
              type='text'
              placeholder='LF, ea, etc'
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='price'>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type='number'
              step='0.01'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='priceCode'>
            <Form.Label>Price Code</Form.Label>
            <Form.Control
              type='text'
              placeholder='A, B, BB, etc'
              value={priceCode}
              onChange={(e) => setPriceCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant='primary' onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
