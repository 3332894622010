import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Container, Card } from 'react-bootstrap'
import axios from 'axios'
import { FormContainer } from '../../components/componentLib'

export const LostPasswordLayout = () => {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    axios.post(`api/v1/users/lost-password/${email}`)
    setEmail('')
    setSubmitted(true)
  }

  return (
    <Container>
      <Card style={{ width: '40rem' }}>
        <FormContainer>
          <h5 style={{ paddingBottom: '1.25rem' }}>Reset your password here</h5>
          {submitted ? (
            <div>
              <p>
                We'll check our records, and if we have that email address, you should have a link
                in your email momentarily.
              </p>
              <Link to='/login' className='ghost-btn'>
                Return to sign in
              </Link>
            </div>
          ) : (
            <div>
              <p>Enter your email and we'll send you a link to set or reset your password</p>
              <Form onSubmit={submitHandler}>
                <Form.Control
                  type='email'
                  onChange={handleChange}
                  value={email}
                  autoComplete='email'
                  placeholder='Email address'
                />
                <Button type='submit' variant='primary' className='mt-2'>
                  Send password reset email
                </Button>
              </Form>
              <hr />
              <Link to='/login'>Back to login</Link>
            </div>
          )}
        </FormContainer>
      </Card>
    </Container>
  )
}
