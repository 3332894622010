import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { getAllQuotes, createQuote } from '../../actions/quoteActions'
import { Loader, Message, QuoteNewModal } from '../componentLib'
import MaterialTable from 'material-table'

export const QuoteTable = ({ history, userInfo }) => {
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()

  const quoteIndex = useSelector((state) => state.quoteIndex)
  const { loading, error, quotes, newQuoteId } = quoteIndex

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (userInfo) {
      dispatch(getAllQuotes())
    }
  }, [dispatch, userInfo])

  const handleCreateQuote = () => {
    setModalOpen(true)
    setRedirect(true)
  }

  //Need a quick delay here so quote can be created before trying to pull it
  useEffect(() => {
    if (redirect && newQuoteId) {
      setTimeout(() => {
        history?.push(`/quotes/${newQuoteId}`)
      }, 300)
    }
  }, [redirect, newQuoteId, history])

  let columns = [
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Project',
      field: 'jobName',
      render: (rowData) => (
        <>
          {rowData.num} | <strong>{rowData.jobName}</strong>
        </>
      ),
    },
    {
      title: 'Quote Name',
      field: 'name',
    },
    { title: 'SubTotal', field: 'subTotal', type: 'currency', grouping: false },
  ]

  userInfo.role !== 'customer' &&
    columns.push(
      { title: 'Customer', field: 'customer[name]' },
      { title: 'Price Code', field: 'priceCode' }
    )

  let actions = [
    {
      icon: 'edit',
      tooltip: 'Edit',
      onClick: (e, rowData) => history?.push(`quotes/${rowData._id}`),
      width: '10px',
    },
  ]

  return (
    <>
      {userInfo.role !== 'customer' && (
        <Button className='mb-3' onClick={handleCreateQuote}>
          <i className='fas fa-plus-circle' /> New Quote
        </Button>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : quotes ? (
        <>
          <MaterialTable
            columns={columns}
            title='All Quotes'
            data={quotes}
            options={{
              grouping: true,
              paging: true,
              pageSize: 25, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
              tableLayout: 'fixed',
            }}
            actions={actions}
          />
        </>
      ) : (
        ''
      )}
      {modalOpen && <QuoteNewModal openState />}
    </>
  )
}
