import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateClient } from '../../actions/clientActions'

export const ClientMatchCreateModal = ({ openState, client }) => {
  const dispatch = useDispatch()

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [code, setCode] = useState('')
  const [panel, setPanel] = useState('')
  const [paint, setPaint] = useState('')
  const [metal, setMetal] = useState('')
  const [color, setColor] = useState('')
  const [sku, setSku] = useState('')

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    const newMatch = {
      code,
      panel,
      paint,
      metal,
      color,
      sku,
    }
    const updatedClient = {
      _id: client._id,
      matchLists: [...client.matchLists, newMatch],
    }
    dispatch(updateClient(updatedClient))
    setIsOpen(false)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Create a new Match</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='code'>
            <Form.Label>Code</Form.Label>
            <Form.Control
              type='text'
              autoFocus
              placeholder='ED, VF, etc'
              value={code}
              onChange={(e) => setCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='panel'>
            <Form.Label className='mb-0'>Panel</Form.Label>
            <Form.Control
              type='text'
              placeholder='TR, AG, 675SL, etc'
              value={panel}
              onChange={(e) => setPanel(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='paint'>
            <Form.Label>Paint</Form.Label>
            <Form.Control
              type='text'
              placeholder='SMP, GLM, etc'
              value={paint}
              onChange={(e) => setPaint(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='metal'>
            <Form.Label>Metal</Form.Label>
            <Form.Control
              type='text'
              placeholder='26, 24, 032, etc'
              value={metal}
              onChange={(e) => setMetal(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='color'>
            <Form.Label>Color</Form.Label>
            <Form.Control
              type='text'
              placeholder='color code...'
              value={color}
              onChange={(e) => setColor(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='sku'>
            <Form.Label>SKU</Form.Label>
            <Form.Control
              type='text'
              placeholder='24GLMTR, etc'
              value={sku}
              onChange={(e) => setSku(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant='primary' onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
