import React from 'react'
import { Container, Col } from 'react-bootstrap'

export function Footer() {
  return (
    <footer>
      <Container>
        <Col className='text-center py-3'>Copyright &copy; 2021</Col>
      </Container>
    </footer>
  )
}
