import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createQuote } from '../../actions/quoteActions'

export const QuoteNewModal = ({ openState }) => {
  const dispatch = useDispatch()

  //Global State

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [name, setName] = useState('')

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createQuote({ name }))
    setIsOpen(false)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Set Job/Project Name Here</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type='text'
              autoFocus
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant='primary' onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
