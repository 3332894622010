import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuote, getQuoteById } from '../../actions/quoteActions'
import { getAllCustomers } from '../../actions/customerActions'
import { Loader, Message } from '../componentLib'
import { Card } from 'react-bootstrap'

import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

export const QuoteDetailHeader = ({ userInfo, quoteId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo) {
      dispatch(getQuoteById(quoteId))
      userInfo.role !== 'customer' && dispatch(getAllCustomers())
    }
  }, [dispatch, quoteId, userInfo])

  const quoteDetail = useSelector((state) => state.quoteDetail)
  const { loading, error, quote } = quoteDetail
  const customerIndex = useSelector((state) => state.customerIndex)
  const { customers } = customerIndex

  //LOCAL STATE
  const [customerId, setCustomerId] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerNum, setCustomerNum] = useState('')

  const [name, setName] = useState('')
  const [jobName, setJobName] = useState('')
  const [num, setNum] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')

  const [tax, setTax] = useState('')

  const [status, setStatus] = useState('')

  useEffect(() => {
    if (quote?._id) {
      setCustomerId(quote.customer?._id)
      quote.customer && setCustomerName([quote.customer])
      console.log('set customer name to: ')
      console.log([quote.customer])
      setCustomerNum(quote.customer?.customerNumForClient)
      setName(quote.name || '')
      setJobName(quote.jobName || '')
      setNum(quote.num || '')
      setStreet(quote.street || '')
      setCity(quote.city || '')
      setState(quote.state || '')
      setTax(quote.tax || '')
      setStatus(quote.status)
    }
  }, [quote])

  const updateTopHandler = () => {
    console.log('update')
    if (quote._id) {
      dispatch(
        updateQuote(
          {
            customer: customerId,
            name,
            jobName,
            street,
            city,
            state,
            tax,
          },
          quote._id
        )
      )
    }
  }

  // Autocomplete for CUSTOMER LOOKUP----
  const handleValueCustomer = (input) => {
    const newValue = input[0] //input from selection is always an array of strings or objects
    console.log('newValue', newValue)
    // setCustomerName(newValue.name)
    if (newValue) {
      setCustomerNum(newValue.customerNumForClient)
      setCustomerId(newValue._id)
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : quote && customers ? (
        <>
          <Card>
            <div>
              <button
                type="button"
                className="btn btn-primary inline"
                onClick={updateTopHandler}
              >
                <i className="far fa-save"></i> Save
              </button>
            </div>
            <form className="form-inline">
              {/* Top Row: Customer */}
              {userInfo.role !== 'customer' && (
                <div className="py-2 row">
                  <label for="typeahead-customer">Customer</label>
                  <div className="col form-floating">
                    <Typeahead
                      id="typeahead-customer"
                      labelKey={(option) =>
                        `${option.name} | ${option.shortName}`
                      }
                      onChange={(selected) => {
                        setCustomerName(selected)
                        console.log('selected', selected)
                        handleValueCustomer(selected)
                      }}
                      options={customers}
                      placeholder="Customer..."
                      selected={customerName || ''}
                      onBlur={updateTopHandler}
                    />
                  </div>

                  <div className="col form-floating">
                    <input
                      className="form-control"
                      value={customerNum}
                      id="customer-number"
                      readOnly
                      tabIndex={'-1'}
                      placeholder=" "
                    />
                    <label for="customer-number">Customer #</label>
                  </div>
                </div>
              )}
              {/* Project data row 1 of 3 */}
              <div className="py-2 row">
                <div className=" form-floating col">
                  <input
                    className="form-control"
                    value={jobName}
                    id="project-name"
                    placeholder=" "
                    readOnly
                    tabIndex={'-1'}
                  />
                  <label for="project-name">Project Name</label>
                </div>
                <div className="form-floating  col">
                  <input
                    className="form-control"
                    value={num}
                    id="job-number"
                    placeholder=" "
                    readOnly
                    tabIndex={'-1'}
                  />
                  <label for="job-number">Job #</label>
                </div>
              </div>
              {/* Project data row 2 of 3 */}
              <div className="py-2 row">
                <div className="form-floating col">
                  <input
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={updateTopHandler}
                    id="quote-name"
                    placeholder=" "
                  />
                  <label for="quote-name">QUOTE Name</label>
                </div>
                <div className="form-floating col">
                  <input
                    className="form-control"
                    value={status}
                    readOnly
                    tabIndex={'-1'}
                    id="quote-status"
                    placeholder=" "
                  />
                  <label for="quote-status">Quote Status</label>
                </div>
              </div>
              {/* Project data row 3 of 3 */}
              <div className="row">
                <div className="form-floating col">
                  <input
                    className="form-control"
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    onBlur={updateTopHandler}
                    id="street"
                    placeholder=" "
                  />
                  <label for="street">Street</label>
                </div>
                <div className="form-floating col">
                  <input
                    className="form-control"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    onBlur={updateTopHandler}
                    id="city"
                    placeholder=" "
                  />
                  <label for="city">City</label>
                </div>
                <div className="form-floating col">
                  <input
                    className="form-control"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    onBlur={updateTopHandler}
                    id="state"
                    placeholder=" "
                  />
                  <label for="state">State</label>
                </div>
              </div>
            </form>
          </Card>

          {/* Dialogs to create customer and SKU */}
        </>
      ) : (
        ''
      )}
    </>
  )
}
