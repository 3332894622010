import React from 'react'
import { MongoDash } from './MongoDash'

export const DashboardData = (user) => {
  return (
    <>
      <MongoDash user={user} />
    </>
  )
}
