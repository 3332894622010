import React, { useState, useEffect, useRef } from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import { RBA_ADMIN } from '../../utils/rba'

export const MongoDash = ({ user }) => {
  console.log('user in mongodash 1')
  console.log(user)
  user = user.user || user
  const getLoggedInToken = () => {
    if (user?.mongoToken) {
      return user.mongoToken
    } else {
      return null
    }
  }
  console.log('user in mongodash')
  console.log(user)

  //Base URL and Dashboard ID is for Tela/CAD view
  let dashBaseUrl = 'https://charts.mongodb.com/charts-mrq-react-atlas-xuxzh'
  let dashDashboardId = '64d79fa2-e312-44f5-89be-4857d2b1035d'

  // Set to admin dash board if applicable
  if (RBA_ADMIN.indexOf(user?.role) >= 0) {
    console.log('user is admin')
    dashBaseUrl = 'https://charts.mongodb.com/charts-mrq-react-atlas-xuxzh'
    dashDashboardId = '64038e2e-058e-4b3c-8bc1-ecc495336a20'
  }

  const sdk = new ChartsEmbedSDK({
    baseUrl: dashBaseUrl,
    getUserToken: async function () {
      return getLoggedInToken()
    },
  })
  const chartDiv = useRef(null)
  const [rendered, setRendered] = useState(false)
  const [chart] = useState(
    sdk.createDashboard({
      dashboardId: dashDashboardId,
      height: '1400px',
      widthMode: 'scale',
      heightMode: 'fixed',
    })
  )

  useEffect(() => {
    chart
      .render(chartDiv.current)
      .then(() => setRendered(true))
      .catch((err) => console.log('Error during Charts rendering.', err))
  }, [chart])

  return <div className="chart" ref={chartDiv} />
}
