import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ResponsiveEmbed, Button } from 'react-bootstrap'
import { Message, Loader } from '../componentLib'
import { createJob } from '../../actions/jobActions'
import { getAllCustomers } from '../../actions/customerActions'

import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { NetworkManager } from 'aws-sdk'

export const JobNewForm = ({ clientId }) => {
  //Global Scope
  const jobIndex = useSelector((state) => state.jobIndex)
  const { loadingCreate, errorCreate } = jobIndex

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const customerIndex = useSelector((state) => state.customerIndex)
  const { loading, error, customers } = customerIndex

  //Component scope
  const [name, setName] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [specialReq, setSpecialReq] = useState('')
  const [pan1Width, setPan1Width] = useState('')
  const [pan1Name, setPan1Name] = useState('')
  const [files, setFiles] = useState([])
  const [pan2Width, setPan2Width] = useState('')
  const [pan2Name, setPan2Name] = useState('')
  const [clearForm, setClearForm] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [customer, setCustomer] = useState('')
  const [customerId, setCustomerId] = useState('')

  const [errorPan1Name, setErrorPan1Name] = useState('')
  const [errorPan2Name, setErrorPan2Name] = useState('')

  const [errorPan1Width, setErrorPan1Width] = useState('')
  const [errorPan2Width, setErrorPan2Width] = useState('')

  const googleMaps = `https://www.google.com/maps/embed/v1/place?key=AIzaSyBcPnYoR66oQP2iTGs0j_3OTbs0_0u4tNA&q=${street},${city},${state}&maptype=satellite&zoom=20`

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllCustomers())
  }, [dispatch])

  const handleCustomer = (input) => {
    const newValue = input[0] //input from selection is always an array of strings or objects
    console.log('newValue', newValue)
    if (newValue) {
      setCustomerId(newValue._id)
    }
  }

  const clearFormHandler = () => {
    setName('')
    setStreet('')
    setCity('')
    setState('')
    setSpecialReq('')
    setPan1Width('')
    setPan1Name('')
    setFiles('')
    setPan2Width('')
    setPan2Name('')
    setCustomer('')
    setCustomerId('')
  }

  const submitHandler = (e) => {
    console.log('submit')
    console.log(files)
    e.preventDefault()
    const newJob = {
      files,
      name,
      street,
      city,
      state,
      specialReq,
      pan1Width,
      pan1Name,
      pan2Width,
      pan2Name,
      customerId,
    }
    console.log(newJob)
    dispatch(createJob(newJob))
    clearForm && clearFormHandler()
  }

  const updatePan1Name = (name) => {
    const specialRegex = /[^\w\s]/gi
    const found = name.match(specialRegex)
    console.log('found', found)
    if (found == null) {
      setPan1Name(name)
      setErrorPan1Name('')
    } else {
      setErrorPan1Name('Please use only letters, numbers, and spaces')
    }
  }

  const updatePan2Name = (name) => {
    const specialRegex = /[^\w\s]/gi
    const found = name.match(specialRegex)
    console.log('found', found)
    if (found == null) {
      setPan2Name(name)
      setErrorPan2Name('')
    } else {
      setErrorPan2Name('Please use only letters, numbers, and spaces')
    }
  }

  const updatePan1Width = (width) => {
    setPan1Width(width)
    if (width < 12 && width !== '') {
      setErrorPan1Width('Be sure to enter panel width in INCHES')
    } else {
      setErrorPan1Width('')
    }
  }

  const updatePan2Width = (width) => {
    setPan2Width(width)
    if (width < 12 && width !== '') {
      setErrorPan2Width('Be sure to enter panel width in INCHES')
    } else {
      setErrorPan2Width('')
    }
  }

  const uploadFilesHandler = async (e) => {
    //let files = []

    const formData = new FormData()
    let totalFileSize = 0
    for (let file in e.target.files) {
      if (Number.isInteger(e.target.files[file].size)) {
        totalFileSize += e.target.files[file].size
      }
    }
    console.log(`total file size: ${totalFileSize}`)
    if (totalFileSize < 25000000) {
      for (let x = 0; x < e.target.files.length; x++) {
        //files.push(e.target.files[x])
        formData.append(
          `subFile`,
          e.target.files[x],
          e.target.files[x].filename
        )
      }
      setUploading(true)
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const { data } = await axios.post('/api/v1/upload', formData, config)
        console.log('data')
        console.log(data)
        setFiles([...files, ...data])
        setUploading(false)
        console.log(files)
      } catch (error) {
        console.error(error)
        setUploading(false)
      }
    } else {
      alert(
        `Those files are too big for the server to handle! (over 25MB!) \n\r Please send us a share link or something.`
      )
    }
  }

  return (
    <>
      {loadingCreate ? (
        <Loader />
      ) : errorCreate ? (
        <Message variant="danger">{errorCreate}</Message>
      ) : (
        <Card className="p-4">
          <h3 className="text-center">
            <i className="fad fa-plus-circle fa-theme" /> Request New Estimate
          </h3>
          <form onSubmit={submitHandler}>
            <div className="form-floating col">
              <input
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="project-name"
                placeholder=" "
                autofocus
                required
              />
              <label for="project-name">Project Name *</label>
            </div>

            {customers && !loading && !error && (
              <>
                <div className="my-0">
                  <label for="typeahead-customer">Customer</label>
                  <Typeahead
                    className="rbt-tight"
                    id="typeahead-customer"
                    labelKey={(option) =>
                      `${option.name} | ${option.shortName}`
                    }
                    onChange={(selected) => {
                      setCustomer(selected)
                      console.log('selected', selected)
                      handleCustomer(selected)
                    }}
                    options={customers}
                    selected={customer || ''}
                    placeholder="Select a customer"
                  />
                </div>
              </>
            )}

            <div className="py-2 row">
              <div className="form-floating col-6 pe-0">
                <input
                  className="form-control"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  id="project-street"
                  placeholder=" "
                />
                <label for="project-street">Street</label>
              </div>

              <div className="form-floating col-4 px-0">
                <input
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  id="project-city"
                  placeholder=" "
                />
                <label for="project-city">City</label>
              </div>
              <div className="form-floating col-2 ps-0">
                <input
                  className="form-control"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  id="project-state"
                  placeholder=" "
                />
                <label for="project-street">State</label>
              </div>
            </div>

            {street ? (
              <div className="py-2">
                <ResponsiveEmbed aspectRatio="16by9">
                  <iframe title="map" src={googleMaps} allowFullScreen>
                    {' '}
                  </iframe>
                </ResponsiveEmbed>
              </div>
            ) : (
              ''
            )}

            <div className="py-2 row">
              <div className="form-floating col-7 pe-0">
                <input
                  className="form-control"
                  value={pan1Name}
                  onChange={(e) => updatePan1Name(e.target.value)}
                  id="panel1Name"
                  placeholder=" "
                />
                <label for="panel1Name">Panel Name</label>
              </div>

              <div className="form-floating input-group col-5 ps-0">
                <input
                  className="form-control"
                  type="number"
                  step="0.01"
                  value={pan1Width}
                  onChange={(e) => updatePan1Width(e.target.value)}
                  id="panel1Width"
                  placeholder=" "
                  required
                />
                <label for="panel1Width">Panel Width *</label>
                <span id="pan1widthhelp" className="input-group-text">
                  in inches
                </span>
              </div>
            </div>
            {errorPan1Name !== '' && (
              <small className="form-text text-warning">{errorPan1Name}</small>
            )}
            {errorPan1Width !== '' && (
              <small className="form-text text-warning float-end">
                {errorPan1Width}
              </small>
            )}

            <div className="form-floating">
              <textarea
                className="form-control"
                placeholder=" "
                id="special-req"
                onChange={(e) => setSpecialReq(e.target.value)}
                value={specialReq}
                style={{ height: '75px' }}
              ></textarea>
              <label for="special-req">
                Notes, Special Requirements, Etc...
              </label>
            </div>

            <div className="my-2">
              <input
                class="form-control"
                type="file"
                id="formFileMultiple"
                onChange={uploadFilesHandler}
                multiple
              />
            </div>
            {/* 
            <TextField
              className='white-bg'
              multiple
              type='file'
              id='file-input'
              label='Choose File(s)'
              onChange={uploadFilesHandler}
              helperText={uploading && 'Hang on just a sec while these files upload...'}
              error={uploading}
              inputProps={{
                multiple: 'multiple',
              }}
            /> */}

            {uploading && <Loader />}
            {files && files.length > 0 && (
              <span>Files uploaded: (click Choose Files to add more)</span>
            )}
            {files &&
              files.map((file) => (
                <div index={file}>
                  {decodeURI(file)
                    .split(/(\\|\/)/g)
                    .pop()
                    .substring(19)}
                </div>
              ))}

            <label className="mt-2">Alternate Panel Option</label>
            <div className="py-2 row">
              <div className="form-floating col-7 pe-0">
                <input
                  className="form-control"
                  value={pan2Name}
                  onChange={(e) => updatePan2Name(e.target.value)}
                  id="panel2Name"
                  placeholder=" "
                />
                <label for="panel2Name">Panel Name</label>
              </div>

              <div className="form-floating input-group col-5 ps-0">
                <input
                  className="form-control"
                  type="number"
                  step="0.01"
                  value={pan2Width}
                  onChange={(e) => updatePan2Width(e.target.value)}
                  id="panel2Width"
                  placeholder=" "
                />
                <label for="panel2Width">Alt Width</label>
                <span id="pan2widthhelp" className="input-group-text">
                  in inches
                </span>
              </div>
            </div>
            {errorPan2Name !== '' && (
              <small className="form-text text-warning">{errorPan2Name}</small>
            )}
            {errorPan2Width !== '' && (
              <small className="form-text text-warning float-end">
                {errorPan2Width}
              </small>
            )}
            <div>
              <div className="d-grid gap-2">
                <button
                  type="submit"
                  className="mx-3 mt-2 btn btn-lg btn-primary"
                  disabled={uploading}
                >
                  Submit
                </button>
              </div>
              <div className="ml-5 mt-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="clear-form-check"
                    checked={clearForm}
                    onChange={(e) => setClearForm(!clearForm)}
                  />
                  <label className="form-check-label" for="clear-form-check">
                    Clear form on submit
                  </label>
                </div>
                <span className="text-muted">
                  Uncheck if entering many jobs with only a few differences.
                </span>
              </div>
            </div>
          </form>
        </Card>
      )}
    </>
  )
}
