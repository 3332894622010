import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col, Card, Container } from 'react-bootstrap'

import { login } from '../../actions/userActions'
import { Message, Loader, FormContainer } from '../../components/componentLib'

export const LoginLayout = ({ history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [resetPassword, setResetPassword] = useState(false)

  const redirect = document.referrer
  console.log(redirect)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userDetail = useSelector((state) => state.userDetail)
  const { user } = userDetail

  useEffect(() => {
    if (user && userInfo?.name && !error) {
      //history.goBack()
      history?.push('/')
    }
  }, [history, user, userInfo, error])

  useEffect(() => {
    if (error && error.includes('reset your password')) {
      setResetPassword(true)
    }
  }, [error])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }
  return (
    <Container>
      <Card style={{ width: '40rem' }}>
        <FormContainer>
          <h1>Sign In</h1>
          {error && !resetPassword && (
            <>
              <Message variant="danger">{error} </Message>{' '}
              {/* <Message variant='info'>
                <Link to='/forgot-password'>
                  If you're having trouble logging in, please reset your password by clicking here.
                </Link>
              </Message> */}
            </>
          )}
          {error && resetPassword && (
            <Message variant="info">
              <Link to="/forgot-password">
                Please reset your password by clicking here.
              </Link>
            </Message>
          )}

          {loading && <Loader />}
          <form onSubmit={submitHandler}>
            <div className="form-floating mb-3">
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder=" "
                value={email}
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label for="email">EMAIL address</label>
            </div>
            <div className="form-floating">
              <input
                id="password"
                className="form-control"
                type="password"
                placeholder=" "
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <label for="password">Password</label>
            </div>
            <Button className="mt-3" type="submit" variant="primary">
              Sign in
            </Button>
          </form>
          <Row className="py-3">
            <Col>
              <Link to="/register">Need to sign up? Click to register.</Link>
            </Col>
            <Col>
              <Link to="/forgot-password">Forgot your password?</Link>
            </Col>
          </Row>
        </FormContainer>
      </Card>
    </Container>
  )
}
