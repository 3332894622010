import React, { useEffect, useState } from 'react'
import {} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import Moment from 'react-moment'
import 'moment-timezone'
import { Loader, Message } from '../../components/componentLib'
import { getAllJobs } from '../../actions/jobActions'
import { updateUserProfile } from '../../actions/userActions'
import { RBA_CAD, RBA_ADMIN } from '../../utils/rba'

export const JobIndex = ({
  userInfo,
  history,
  activeOnly,
  mineOnly,
  status,
}) => {
  const jobIndex = useSelector((state) => state.jobIndex)
  const { loading, error, jobs } = jobIndex

  const [showCadJobs, setShowCadJobs] = useState(userInfo?.showCadJobs)
  const [showNonCadJobs, setShowNonCadJobs] = useState(userInfo?.showNonCadJobs)

  const defaultPageSize = activeOnly ? 50 : 25

  const dispatch = useDispatch()
  useEffect(() => {
    if (userInfo) {
      //0 results in all jobs being pulled
      dispatch(
        getAllJobs(0, showCadJobs, showNonCadJobs, activeOnly, mineOnly, status)
      )
    }
  }, [
    dispatch,
    userInfo,
    showCadJobs,
    showNonCadJobs,
    activeOnly,
    mineOnly,
    status,
  ])

  //Color stuff below...
  let jobColors = []
  if (jobs) {
    jobColors = getJobColors(jobs, userInfo, activeOnly)
  }

  const handleShowCadJobs = (e) => {
    e.preventDefault()
    setShowCadJobs(!showCadJobs)
    //this is reversed for some reason...
    dispatch(updateUserProfile({ showCadJobs: !showCadJobs }, 'profile'))
  }

  const handleShowNonCadJobs = (e) => {
    e.preventDefault()
    setShowNonCadJobs(!showNonCadJobs)
    //this is reversed for some reason...
    dispatch(updateUserProfile({ showNonCadJobs: !showNonCadJobs }, 'profile'))
  }

  const columnsStart = [
    {
      title: 'Number',
      field: 'num',
      render: (rowData) => (
        <>
          <strong>
            <a href={`jobs/${rowData._id}`} className="black-href">
              {rowData.num}-R{rowData.revision}
            </a>
          </strong>
        </>
      ),
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <strong>
          {rowData.customer &&
            rowData.customer.shortName &&
            userInfo.role !== 'customer' &&
            `[${rowData.customer.shortName}]`}{' '}
          {rowData.name}
        </strong>
      ),
    },
    {
      title: (
        <>
          <i className="fas fa-ruler-combined"></i> Takeoff
        </>
      ),
      field: 'statusCurrent',
    },
  ]

  const columnsEnd = [
    { title: 'Address', field: 'street' },
    { title: 'City', field: 'city' },
    { title: 'State', field: 'state' },
  ]

  let columnsEndSort = []
  if (activeOnly) {
    columnsEndSort = [
      {
        title: 'Updated',
        field: 'statusTimeCurrent',
        render: (rowData) => (
          <Moment format="ddd MMM Do hh:mm a">
            {rowData.statusTimeCurrent}
          </Moment>
        ),
      },
    ]
  } else {
    columnsEndSort = [
      {
        title: 'Updated',
        field: 'statusTimeCurrent',
        render: (rowData) => (
          <Moment format="ddd MMM Do hh:mm a">
            {rowData.statusTimeCurrent}
          </Moment>
        ),
      },
    ]
  }

  const columnsQuoter = [
    {
      title: <i className="fas fa-clipboard-list"> Quote</i>,
      field: 'quoteStatusCurrent',
    },
    {
      title: <i className="fas fa-industry"> Production</i>,
      field: 'productionStatusCurrent',
    },
  ]

  let columns =
    (userInfo.hasQuoter || RBA_ADMIN.indexOf(userInfo.role) >= 0) &&
    userInfo.showSkittles === true
      ? columnsStart.concat(columnsQuoter).concat(columnsEnd)
      : columnsStart.concat(columnsEnd).concat(columnsEndSort)

  // Admin cols
  const adminCols = [
    {
      title: 'CAD',
      field: 'cadCurrent.name',
    },
  ]
  columns =
    RBA_ADMIN.indexOf(userInfo.role) >= 0 ? columns.concat(adminCols) : columns

  //console.log(columnsStart)

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        ''
      )}

      {jobs && (
        <div style={{ maxWidth: '100%' }}>
          {(userInfo.hasQuoter || RBA_ADMIN.indexOf(userInfo.role) >= 0) && (
            <div className="text-muted text-center">
              <span
                onClick={handleShowCadJobs}
                className={showCadJobs ? 'ml-2 mark' : 'ml-2 del'}
              >
                Show Jobs with CAD
              </span>
              <span
                onClick={(evt, selectedRow) =>
                  handleShowNonCadJobs(selectedRow._id)
                }
                className={showNonCadJobs ? 'ml-2 mark' : 'ml-2 del'}
              >
                Show Jobs withOUT CAD
              </span>
            </div>
          )}
          <MaterialTable
            className="white-bg"
            columns={columns}
            title="All Estimates"
            data={jobColors}
            // actions={[
            //   {
            //     icon: 'info',
            //     toolTip: 'Click for Details',
            //     onClick: (event, rowData) => history?.push(`jobs/${rowData._id}`),
            //   },
            // ]}
            options={{
              filtering: true,
              paging: true,
              searchFieldAlignment: 'left',
              pageSize: defaultPageSize, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
              rowStyle: (rowData) => ({
                backgroundColor: rowData.color,
              }),
            }}
          />
        </div>
      )}
    </>
  )
}

// Color declarations...
const red = 'rgba(153,51,51,0.5)'
const teal = 'rgba(0,150,150,0.5)'
const cyan = 'rgba(0,255,255,0.3)'
const yellow = 'rgba(255,255,0,0.5)'
const lightYellow = 'rgba(255,255,0,0.25)'
const lightBeige = 'rgba(250,240,230,0.5)'
const darkGray = 'rgba(153,153,153,1)'
const gray = 'rgba(153,153,153,0.5)'
const green = 'rgba(0,255,0,0.5)'

const active = '#c1c9d0'
const primary = '#d6dfe7'
const secondary = '#e8e8e6'
const success = '#e9f3db'
const danger = '#f7dddc'
const warning = '#fde5d8'
const info = '#d4eef9'
const light = '#f8f5f0'
const dark = '#3e3f3a'
const rush = '#ffc6fe'

const aRFI = '#606060'
const aRELEASED = '#A0A0A0'
const aSUBMITTED = '#FFFFFF'
const aREVISION = '#FFFF00'
const aVALIDATED = '#00FFFF'
const aWORKING = '#0080FF'
const aRUSH = '#00FF00'
const aCORRECTION = '#FFBD33'
const aREVIEW = '#33FFBD'

const colorTableAdmin = {
  Submitted: aSUBMITTED,
  RFI: aRFI,
  Validated: aVALIDATED,
  Working: aWORKING,
  Review: aREVIEW,
  Correction: aCORRECTION,
  Released: aRELEASED,
  Revision: aREVISION,
}
const colorTableCad = {
  Submitted: aSUBMITTED,
  RFI: aRFI,
  Validated: aVALIDATED,
  Working: aWORKING,
  Review: aCORRECTION,
  Correction: aCORRECTION,
  Released: aRELEASED,
  Revision: aREVISION,
}
// const colorTableCad = {
//   Submitted: light,
//   RFI: success,
//   Validated: danger,
//   Working: info,
//   Review: active,
//   Correction: danger,
//   Released: success,
//   Revision: danger,
// }
const colorTableUser = {
  Submitted: warning,
  RFI: danger,
  Validated: secondary,
  Working: secondary,
  Review: secondary,
  Correction: secondary,
  Released: green,
  Revision: lightYellow,
}

const getJobColors = (jobs, userInfo, activeOnly) => {
  let jobColors = jobs

  if (RBA_ADMIN.indexOf(userInfo.role) >= 0) {
    jobColors.forEach((job) => {
      let color = colorTableAdmin[job.statusCurrent]
      job.rush === true ? (job.color = aRUSH) : (job.color = color)
    })
  } else if (RBA_CAD.indexOf(userInfo.role) >= 0) {
    jobColors.forEach((job) => {
      let color = colorTableCad[job.statusCurrent]
      job.rush === true ? (job.color = rush) : (job.color = color)
    })
  } else {
    jobColors.forEach((job) => {
      let color = colorTableUser[job.statusCurrent]
      job.color = color
    })
  }

  // if (activeOnly) {
  //   jobColors = jobColors.sort((a, b) => {
  //     if (a.statusCurrent === 'RFI' || a.statusCurrent === 'Submitted') {
  //       return 1
  //     } else if (
  //       a.statusTimeCurrent < b.statusTimeCurrent &&
  //       a.statusCurrent !== 'RFI' &&
  //       a.statusCurrent !== 'Submitted'
  //     ) {
  //       return -1
  //     } else {
  //       return 0
  //     }
  //   })
  // }
  return jobColors
}
