//CLIENTS
export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST'
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS'
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL'

export const CLIENT_GET_ALL_REQUEST = 'CLIENT_GET_ALL_REQUEST'
export const CLIENT_GET_ALL_SUCCESS = 'CLIENT_GET_ALL_SUCCESS'
export const CLIENT_GET_ALL_FAIL = 'CLIENT_GET_ALL_FAIL'

export const CLIENT_GET_DETAIL_REQUEST = 'CLIENT_GET_DETAIL_REQUEST'
export const CLIENT_GET_DETAIL_SUCCESS = 'CLIENT_GET_DETAIL_SUCCESS'
export const CLIENT_GET_DETAIL_FAIL = 'CLIENT_GET_DETAIL_FAIL'

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST'
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS'
export const CLIENT_UPDATE_FAIL = 'CLIENT_UPDATE_FAIL'

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST'
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS'
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL'

//USERS
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_FAIL = 'USER_PROFILE_UPDATE_FAIL'
export const USER_PROFILE_UPDATE_RESET = 'USER_PROFILE_UPDATE_RESET'

export const USER_UPDATE_BY_ID_REQUEST = 'USER_ UPDATE_BY_ID_REQUEST'
export const USER_UPDATE_BY_ID_SUCCESS = 'USER_ UPDATE_BY_ID_SUCCESS'
export const USER_UPDATE_BY_ID_FAIL = 'USER_UPDATE_BY_ID_FAIL'

export const USER_GET_ALL_REQUEST = 'USER_GET_ALL_REQUEST'
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS'
export const USER_GET_ALL_FAIL = 'USER_GET_ALL_FAIL'

//JOBS
export const JOB_CREATE_REQUEST = 'JOB_CREATE_REQUEST'
export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS'
export const JOB_CREATE_FAIL = 'JOB_CREATE_FAIL'

export const JOBS_GET_ALL_REQUEST = 'JOBS_GET_ALL_REQUEST'
export const JOBS_GET_ALL_SUCCESS = 'JOBS_GET_ALL_SUCCESS'
export const JOBS_GET_ALL_FAIL = 'JOBS_GET_ALL_FAIL'

export const JOB_GET_DETAIL_REQUEST = 'JOB_GET_DETAIL_REQUEST'
export const JOB_GET_DETAIL_SUCCESS = 'JOB_GET_DETAIL_SUCCESS'
export const JOB_GET_DETAIL_FAIL = 'JOB_GET_DETAIL_FAIL'

export const JOB_UPDATE_STATUS_REQUEST = 'JOB_UPDATE_STATUS_REQUEST'
export const JOB_UPDATE_STATUS_SUCCESS = 'JOB_UPDATE_STATUS_SUCCESS'
export const JOB_UPDATE_STATUS_FAIL = 'JOB_UPDATE_STATUS_FAIL'

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST'
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS'
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL'

export const JOB_INVOICE_REQUEST = 'JOB_INVOICE_REQUEST'
export const JOB_INVOICE_SUCCESS = 'JOB_INVOICE_SUCCESS'
export const JOB_INVOICE_FAIL = 'JOB_INVOICE_FAIL'

export const JOB_DATA_REQUEST = 'JOB_DATA_REQUEST'
export const JOB_DATA_SUCCESS = 'JOB_DATA_SUCCESS'
export const JOB_DATA_FAIL = 'JOB_DATA_FAIL'

export const BILLING_DATA_REQUEST = 'BILLING_DATA_REQUEST'
export const BILLING_DATA_SUCCESS = 'BILLING_DATA_SUCCESS'
export const BILLING_DATA_FAIL = 'BILLING_DATA_FAIL'

export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST'
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS'
export const DASHBOARD_DATA_FAIL = 'DASHBOARD_DATA_FAIL'

//NOTES
export const NOTES_GET_REQUEST = 'NOTES_GET_REQUEST'
export const NOTES_GET_SUCCESS = 'NOTES_GET_SUCCESS'
export const NOTES_GET_FAIL = 'NOTES_GET_FAIL'

export const NOTE_CREATE_REQUEST = 'NOTE_CREATE_REQUEST'
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS'
export const NOTE_CREATE_FAIL = 'NOTE_CREATE_FAIL'

//FILES

//ADMIN
export const QBO_SETUP_GET_REQUEST = 'QBO_SETUP_GET_REQUEST'
export const QBO_SETUP_GET_SUCCESS = 'QBO_SETUP_GET_SUCCESS'
export const QBO_SETUP_GET_FAIL = 'QBO_SETUP_GET_FAIL'

//ITEMS
export const ITEM_CREATE_REQUEST = 'ITEM_CREATE_REQUEST'
export const ITEM_CREATE_SUCCESS = 'ITEM_CREATE_SUCCESS'
export const ITEM_IMPORT_SUCCESS = 'ITEM_IMPORT_SUCCESS'
export const ITEM_CREATE_FAIL = 'ITEM_CREATE_FAIL'

export const ITEMS_GET_ALL_REQUEST = 'ITEMS_GET_ALL_REQUEST'
export const ITEMS_GET_ALL_SUCCESS = 'ITEMS_GET_ALL_SUCCESS'
export const ITEMS_GET_ALL_FAIL = 'ITEMS_GET_ALL_FAIL'

export const ITEM_GET_DETAIL_REQUEST = 'ITEM_GET_DETAIL_REQUEST'
export const ITEM_GET_DETAIL_SUCCESS = 'ITEM_GET_DETAIL_SUCCESS'
export const ITEM_GET_DETAIL_FAIL = 'ITEM_GET_DETAIL_FAIL'

export const ITEM_UPDATE_REQUEST = 'ITEM_UPDATE_REQUEST'
export const ITEM_UPDATE_SUCCESS = 'ITEM_UPDATE_SUCCESS'
export const ITEM_UPDATE_FAIL = 'ITEM_UPDATE_FAIL'

export const ITEM_DELETE_REQUEST = 'ITEM_DELETE_REQUEST'
export const ITEM_DELETE_SUCCESS = 'ITEM_DELETE_SUCCESS'
export const ITEM_DELETE_FAIL = 'ITEM_DELETE_FAIL'

//CUSTOMERS
export const CUSTOMER_CREATE_REQUEST = 'CUSTOMER_CREATE_REQUEST'
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS'
export const CUSTOMER_IMPORT_SUCCESS = 'CUSTOMER_IMPORT_SUCCESS'
export const CUSTOMER_CREATE_FAIL = 'CUSTOMER_CREATE_FAIL'

export const CUSTOMERS_GET_ALL_REQUEST = 'CUSTOMERS_GET_ALL_REQUEST'
export const CUSTOMERS_GET_ALL_SUCCESS = 'CUSTOMERS_GET_ALL_SUCCESS'
export const CUSTOMERS_GET_ALL_FAIL = 'CUSTOMERS_GET_ALL_FAIL'

export const CUSTOMER_GET_DETAIL_REQUEST = 'CUSTOMER_GET_DETAIL_REQUEST'
export const CUSTOMER_GET_DETAIL_SUCCESS = 'CUSTOMER_GET_DETAIL_SUCCESS'
export const CUSTOMER_GET_DETAIL_FAIL = 'CUSTOMER_GET_DETAIL_FAIL'

export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST'
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export const CLIENT_MATCH_IMPORT_SUCCESS = 'CLIENT_MATCH_IMPORT_SUCCESS'
export const CUSTOMER_UPDATE_FAIL = 'CUSTOMER_UPDATE_FAIL'

export const CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST'
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS'
export const CUSTOMER_DELETE_FAIL = 'CUSTOMER_DELETE_FAIL'

//QUOTES
export const QUOTE_CREATE_REQUEST = 'QUOTE_CREATE_REQUEST'
export const QUOTE_CREATE_SUCCESS = 'QUOTE_CREATE_SUCCESS'
export const QUOTE_CREATE_FAIL = 'QUOTE_CREATE_FAIL'

export const QUOTES_GET_ALL_REQUEST = 'QUOTES_GET_ALL_REQUEST'
export const QUOTES_GET_ALL_SUCCESS = 'QUOTES_GET_ALL_SUCCESS'
export const QUOTES_GET_ALL_FAIL = 'QUOTES_GET_ALL_FAIL'

export const QUOTES_GET_BY_JOB_ID_REQUEST = 'QUOTES_GET_BY_JOB_ID_REQUEST'
export const QUOTES_GET_BY_JOB_ID_SUCCESS = 'QUOTES_GET_BY_JOB_ID_SUCCESS'
export const QUOTES_GET_BY_JOB_ID_FAIL = 'QUOTES_GET_BY_JOB_ID_FAIL'

export const QUOTE_GET_DETAIL_REQUEST = 'QUOTE_GET_DETAIL_REQUEST'
export const QUOTE_GET_DETAIL_SUCCESS = 'QUOTE_GET_DETAIL_SUCCESS'
export const QUOTE_GET_DETAIL_FAIL = 'QUOTE_GET_DETAIL_FAIL'

export const QUOTE_UPDATE_REQUEST = 'QUOTE_UPDATE_REQUEST'
export const QUOTE_UPDATE_SUCCESS = 'QUOTE_UPDATE_SUCCESS'
export const QUOTE_UPDATE_FAIL = 'QUOTE_UPDATE_FAIL'

export const QUOTE_DELETE_REQUEST = 'QUOTE_DELETE_REQUEST'
export const QUOTE_DELETE_SUCCESS = 'QUOTE_DELETE_SUCCESS'
export const QUOTE_DELETE_FAIL = 'QUOTE_DELETE_FAIL'
