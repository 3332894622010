import axios from 'axios'
import {
  ITEMS_GET_ALL_REQUEST,
  ITEMS_GET_ALL_SUCCESS,
  ITEMS_GET_ALL_FAIL,
  ITEM_GET_DETAIL_SUCCESS,
  ITEM_GET_DETAIL_FAIL,
  ITEM_GET_DETAIL_REQUEST,
  ITEM_CREATE_REQUEST,
  ITEM_CREATE_SUCCESS,
  ITEM_CREATE_FAIL,
  ITEM_UPDATE_REQUEST,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAIL,
  ITEM_DELETE_REQUEST,
  ITEM_DELETE_SUCCESS,
  ITEM_DELETE_FAIL,
  ITEM_IMPORT_SUCCESS,
} from './constants'

//CREATE ITEM
export const createItem =
  (item, importFile = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ITEM_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } =
        importFile === true
          ? await axios.post(`/api/v1/items/import`, item, config)
          : await axios.post(`/api/v1/items`, item, config)
      const successType = importFile === true ? ITEM_IMPORT_SUCCESS : ITEM_CREATE_SUCCESS

      dispatch({
        type: successType,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ITEM_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

//GET ALL ITEMS INDEX
export const getAllItems = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ITEMS_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/items`, config)

    dispatch({
      type: ITEMS_GET_ALL_SUCCESS,
      payload: data,
    })

    localStorage.setItem('itemIndex', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: ITEMS_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//GET ITEM DETAILS SHOW
export const getItemById = (itemId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ITEM_GET_DETAIL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/items/${itemId}`, config)

    dispatch({
      type: ITEM_GET_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ITEM_GET_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//UPDATE Item
export const updateItem = (item, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ITEM_UPDATE_REQUEST,
    })
    console.log('in update item action')
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.patch(`/api/v1/items/${id}`, item, config)

    dispatch({
      type: ITEM_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ITEM_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

//DELETE ITEM DESTROY
export const deleteItemById = (itemId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ITEM_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/v1/items/${itemId}`, config)

    dispatch({
      type: ITEM_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: ITEM_DELETE_FAIL,
      payload: message,
    })
  }
}
