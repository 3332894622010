import React from 'react'

export const CadPanelHome = () => {
  return (
    <div className="text-center">
      <div className="my-2">
        <a href="/jobs?activeOnly=true">
          <i className="fad fa-briefcase fa-theme" /> ACTIVE jobs
        </a>
      </div>
    </div>
  )
}
