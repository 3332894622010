import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col, Card, Container } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'

import { register } from '../../actions/userActions'
import { Message, Loader, FormContainer } from '../../components/componentLib'

export const RegisterLayout = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  // const [password, setPassword] = useState('')
  const [introCompanyName, setIntroCompanyName] = useState('')
  const [introCompanyWebsite, setIntroCompanyWebsite] = useState('')
  const [introCompanyType, setIntroCompanyType] = useState('')
  const [introNotes, setIntroNotes] = useState('')
  const [regisNotifOptIn, setRegisNotifOptIn] = useState(false)
  // const [message, setMessage] = useState(null) //not in use

  const dispatch = useDispatch()
  const recaptchaRef = useRef()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, success, userInfo } = userRegister

  // const userLogin = useSelector((state) => state.userLogin)
  // const userInfoLogin = userLogin.userInfo
  // useEffect(() => {
  //   if (userInfo || userInfoLogin) {
  //     history?.push('/login')
  //   }
  // }, [history, userInfo, userInfoLogin])

  const onChange = (value) => {
    console.log('Captcha value:', value)
  }

  const clearForm = () => {
    setName('')
    setEmail('')
    setPhone('')
    setIntroCompanyName('')
    setIntroCompanyWebsite('')
    setIntroCompanyType('')
    setIntroNotes('')
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    if (recaptchaValue) {
      const registration = {
        name,
        email,
        phone,
        introCompanyName,
        introCompanyWebsite,
        introCompanyType,
        introNotes,
        recaptchaValue,
        regisNotifOptIn,
      }
      dispatch(register(registration))
      clearForm()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
  return (
    <Container>
      <Card style={{ width: '33rem' }}>
        <FormContainer>
          <h1>Register</h1>
          {error && <Message variant="danger">{error}</Message>}
          {success && (
            <Message variant="success">Registration received! Check your email for a verification link.</Message>
          )}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="mb-1">
              <Form.Label className="mb-0">Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="email" className="mb-1">
              <Form.Label className="mb-0 mt-4">Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="email@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Check
              className="pt-1 text-muted"
              inline
              type="switch"
              id="custom-switch"
              label="  I agree to receive emails about my takeoff requests"
              required
              onChange={(e) => setRegisNotifOptIn(!regisNotifOptIn)}
            />
            <div className="text-muted pb-2">(You can change this in the app)</div>
            {/* <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group> */}
            <Form.Group controlId="phone" className="mb-1">
              <Form.Label className="mb-0 mt-4">
                Phone #.{' '}
                <span className="text-muted">We'll only call you if we have an urgent question about an estimate.</span>
              </Form.Label>
              <Form.Control
                placeholder="352-123-4567"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="introCompanyName" className="mb-1 mt-4">
              <Form.Label className="mb-0">What's your company name?</Form.Label>
              <Form.Control
                required
                placeholder="Metal Roof Company"
                value={introCompanyName}
                onChange={(e) => setIntroCompanyName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="introCompanyType" className="mb-1 mt-4">
              <Form.Label className="mb-0">Are you a roofer, manufacturer, both, or something else?</Form.Label>
              <Form.Control
                placeholder="Manufacturer, roofer, something else??"
                required
                value={introCompanyType}
                onChange={(e) => setIntroCompanyType(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="introCompanyWebsite" className="mb-1 mt-4">
              <Form.Label className="mb-0">What's your website/FB page/etc? If you have one.</Form.Label>
              <Form.Control
                placeholder="www.MetalRoofCompany.com"
                value={introCompanyWebsite}
                onChange={(e) => setIntroCompanyWebsite(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="introNotes" className="mb-1 mt-4">
              <Form.Label className="mb-0">Anything else you'd like us to know?</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={introNotes}
                onChange={(e) => setIntroNotes(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <ReCAPTCHA ref={recaptchaRef} sitekey="6LcGBgAaAAAAAJrFghmOLY2XN5253vgu44nGX9h3" onChange={onChange} />
            <Button type="submit" variant="primary">
              Sign Up!
            </Button>
            <div className="text-center">Once you sign up, please check your email for an activation link!</div>
          </Form>
          <Row className="py-3">
            <Col>
              <Link to="/login">Already set up? Click to Login.</Link>{' '}
            </Col>
          </Row>
        </FormContainer>
      </Card>
    </Container>
  )
}
