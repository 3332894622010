import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuoteTable } from '../../components/componentLib'

export const QuoteIndexLayout = ({ history }) => {
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    }
  }, [userInfo, history])

  return (
    <>
      {userInfo ? (
        <>
          <div className='px-5 mx-5'>
            <QuoteTable userInfo={userInfo} history={history} />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  )
}
