import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuote, getQuoteById } from '../../actions/quoteActions'
import { getAllItems } from '../../actions/itemActions'
import { Loader, Message, QuoteAccModal } from '../componentLib'

import { IconButton } from '@material-ui/core'
import { Card } from 'react-bootstrap'

import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { useCallbackState } from '../../utils/useCallBackState'

import DeleteIcon from '@material-ui/icons/Delete'
import { getClientDetailById } from '../../actions/clientActions'

export const QuoteDetailLines = ({ userInfo, quoteId }) => {
  const dispatch = useDispatch()

  const emptyLine = {
    code: '',
    quantity: '',
    description: '',
    drop: '',
    flats: '',
    price: '',
    total: '',
    comments: '',
    inputValue: '',
    value: '',
  }
  const emptyLines = [emptyLine, emptyLine]
  //const [test, setTest] = useCallbackState < boolean > true

  const quoteDetail = useSelector((state) => state.quoteDetail)
  const { loading, error, quote } = quoteDetail
  const itemIndex = useSelector((state) => state.itemIndex)
  const { itemLoading, itemError, items } = itemIndex
  const clientDetail = useSelector((state) => state.clientDetail)
  const { client } = clientDetail

  useEffect(() => {
    if (userInfo) {
      dispatch(getQuoteById(quoteId))
      dispatch(getAllItems())
    }
  }, [dispatch, quoteId, userInfo])

  useEffect(() => {
    if (items) {
      setFilteredItemList(items.filter((item) => item.priceCode === priceCode))
      setFilteredItemList(
        items
          .filter((item) => item.priceCode === priceCode)
          .map((option) => option.sku)
      )
      //console.log(filteredItemList)
      //console.log(filteredSkuList)
    }
  }, [items])

  //LOCAL STATE

  const [subTotal, setSubTotal] = useState('')
  const [flatWidth, setFlatWidth] = useState(48)
  const [filteredItemList, setFilteredItemList] = useState('')
  const [filteredSkuList, setFilteredSkuList] = useState('')

  const [accModalOpen, setAccModalOpen] = useState(false)
  const [accList, setAccList] = useState('')

  const [lines, setLines] = useState(emptyLines)
  const latestLines = useRef(lines)

  const [panelsRaw, setPanelsRaw] = useState('')

  //Need for calcs/functions, but not set here--
  const [panelClass, setPanelClass] = useState('')
  const [panelClassInput, setPanelClassInput] = useState('')
  const [coating, setCoating] = useState('')
  const [color, setColor] = useState('')
  const [metal, setMetal] = useState('')
  const [priceCode, setPriceCode] = useState('')

  useEffect(() => {
    if (quote?._id) {
      setSubTotal(quote.subTotal || '')
      setPriceCode(quote.priceCode)
      setPanelClass(quote.panelClass || '')
      setPanelClassInput(quote.panelClassInput || '')
      setCoating(quote.coating || '')
      setColor(quote.color || '')
      setMetal(quote.metal || '')
      updateLines(quote.lines?.length > 0 ? quote.lines : emptyLines)
      setPanelsRaw(quote.panelsRaw)
      setFlatWidth(quote.flatWidth)
    }
    console.log('useEffect sets lines')
  }, [quote])

  useEffect(() => {
    if (client && client.matchLists == null) {
      dispatch(getClientDetailById(userInfo.client))
    }
    if (client.flatWidth) {
      setFlatWidth(client.flatWidth)
    }
  }, [client])

  const updateMidHandler = () => {
    console.log('update')
    if (quote._id) {
      dispatch(
        updateQuote(
          {
            subTotal,
            lines,
            panelsRaw,
            panelClass,
            panelClassInput,
            coating,
            color,
            metal,
          },
          quote._id
        )
      )
    }
  }

  const matchCodes = () => {
    let linesTemp = lines
    console.log('linesTemp.length: ' + linesTemp.length)
    for (let i = 0; i < linesTemp.length; i++) {
      let lineTemp = linesTemp[i]
      if (lineTemp.code != null && lineTemp.code !== '') {
        console.log('panelClass: ' + panelClass)
        let drop = client.matchLists.filter(
          (item) =>
            (item.code === lineTemp.code && item.panel === panelClass) ||
            (item.code === lineTemp.code && item.panel === 'ALL')
        )
        console.log('line pre', i, ': ', drop)
        if (drop.length > 1) {
          drop = drop.filter((item) => item.panel !== 'ALL')
        }
        console.log('line after', i, ': ', drop)
        linesTemp[i] = { ...lineTemp, drop: drop[0].drop }
      }
    }
    updateLines(linesTemp)
    calcSubTotal(linesTemp)
  }

  const handleAccessory = () => {
    let linesTemp = lines
    let accTemp = []
    for (let line in linesTemp) {
      if (linesTemp[line].code != null && linesTemp[line].code !== '') {
        // console.log(linesTemp[line].code)
        console.log('line:')
        console.log(linesTemp[line])
        for (let ratio in client.ratioLists) {
          console.log('ratio:')
          console.log(client.ratioLists[ratio])
          if (linesTemp[line].code === client.ratioLists[ratio].code) {
            accTemp.push({
              code: client.ratioLists[ratio].code,
              item: client.ratioLists[ratio].item,
              ratio: client.ratioLists[ratio].ratio,
              quantity: linesTemp[line].quantity,
            })
          }
        }
      }
    }
    setAccList(accTemp)
    console.log(accList)
    console.log(accTemp)
    setAccModalOpen(!accModalOpen)
  }

  //Handlers for order form LINES-----
  const calcSubTotal = (linesInput) => {
    try {
      const linesTotal = linesInput.reduce(
        (acc, line) => acc + (Number(line.total) || Number(0)),
        0
      )
      setSubTotal(linesTotal)
    } catch (err) {}
  }

  function updateLines(newState) {
    latestLines.current = newState
    console.log('lines at updateLines: ', latestLines.current)
    setLines(newState)
  }

  useEffect(() => {
    console.log(latestLines)
  }, [latestLines])

  // const handleDrop = (value, idx) => {
  //   console.log('handleDrop')
  //   console.log('value: ' + value)
  //   console.log('idx: ' + idx)
  //   const updatedLines = lines.map((line, sidx) => {
  //     if (idx !== sidx) {
  //       return line
  //     } else {
  //       return { ...line, drop: value }
  //     }
  //   })
  //   updateLines(updatedLines)
  //   calcSubTotal(updatedLines)
  // }

  const handleLineQty = (value, idx) => {
    const updatedLines = lines.map((line, sidx) => {
      if (idx !== sidx) {
        return line
      } else {
        const lineTotal = value * line.price || 0
        return { ...line, quantity: value, total: lineTotal }
      }
    })
    updateLines(updatedLines)
    calcSubTotal(updatedLines)
  }

  const handleLineCode = (value, idx) => {
    const updatedLines = lines.map((line, sidx) => {
      if (idx !== sidx) return line
      return { ...line, code: value }
    })
    updateLines(updatedLines)
  }

  const handleLineComment = (value, idx) => {
    const updatedLines = lines.map((line, sidx) => {
      if (idx !== sidx) return line
      return { ...line, comments: value }
    })
    updateLines(updatedLines)
  }

  //Set focus to new line
  const skuInput = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      skuInput.current && skuInput.current.focus()
      //console.log(skuInput.current)
    }, 10)
  }, [lines.length])

  const handleNewLine = () => {
    console.log('new line')
    updateLines(lines.concat(emptyLine))
  }

  const doNothing = () => {
    console.log('doing nuthin')
  }

  const handleDeleteLine = (index) => {
    console.log('deleting ' + index)
    const updatedLines = lines.filter((line, sidx) => index !== sidx)
    updateLines(updatedLines)
    calcSubTotal(updatedLines)
  }

  const handlePanelClass = (value) => {
    // console.log('panelClass: ', panelClass)
    // console.log('client panels: ', client.panels)
    // console.log('value in: ', value)
    // let panCode = client.panels.filter((item) => item.name === value)
    // console.log('panode found: ', panCode)
    setPanelClass(value)
    setPanelClassInput(value)
  }

  //End handlers for order form LINEs-----

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : quote && items ? (
        <>
          <Card>
            <div>
              <button
                type="button"
                className="btn btn-primary inline"
                onClick={updateMidHandler}
              >
                <i className="far fa-save"></i> Save
              </button>
            </div>

            {itemLoading && <Message>Loading catalog</Message>}
            {itemError && <Message>Error loading item catalog</Message>}
            <form>
              {/* Begin order form */}
              <div id="order-container" className="pb-4">
                {/* Third row: panel/metal/etc */}
                <div className="py-3 row">
                  <div className="form-floating col">
                    <select
                      className="form-select"
                      id="panel-type"
                      aria-label="Select Panel Type"
                      value={panelClassInput}
                      onChange={(e) => handlePanelClass(e.target.value)}
                    >
                      {client.panels &&
                        client.panels.map((item) => (
                          <option value={item.code}>{item.name}</option>
                        ))}
                    </select>
                    <label for="panel-type">Panel</label>
                  </div>
                  <div className="form-floating col">
                    <select
                      className="form-select"
                      id="paint-type"
                      aria-label="Select Paint Type"
                      value={coating}
                      onChange={(e) => setCoating(e.target.value)}
                    >
                      {client.paints &&
                        client.paints.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                    <label for="paint-type">Paint Type</label>
                  </div>
                  <div className="form-floating col">
                    <select
                      className="form-select"
                      id="metal-type"
                      aria-label="Select Metal Type"
                      value={metal}
                      onChange={(e) => setMetal(e.target.value)}
                    >
                      {client.metals &&
                        client.metals.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                    <label for="metal-type">Metal Type</label>
                  </div>
                  <div className="form-floating col">
                    <select
                      className="form-select"
                      id="colors-type"
                      aria-label="Select Color"
                      value={coating}
                      onChange={(e) => setCoating(e.target.value)}
                    >
                      {client.colors &&
                        client.colors.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                    </select>
                    <label for="colors-type">Color</label>
                  </div>
                  {client && client.ratioLists && (
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-info inline mx-3"
                        onClick={handleAccessory}
                      >
                        <i className="fas fa-calculator" /> Add Accessories
                      </button>
                    </div>
                  )}
                  {client && client.matchLists && (
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-warning inline"
                        onClick={matchCodes}
                      >
                        <i className="fas fa-ticket" /> Calculate Flats
                      </button>
                    </div>
                  )}{' '}
                </div>

                {lines.map((line, index) => (
                  <div className="row" key={line._id}>
                    <div className="col form-floating  px-0">
                      <input
                        className="form-control"
                        value={line.code}
                        onChange={(e) => handleLineCode(e.target.value, index)}
                        id={`line-${index}-code`}
                        placeholder=" "
                      />
                      <label for={`line-${index}-code`}>Code</label>
                    </div>

                    <div className="col form-floating px-0">
                      <input
                        className="form-control"
                        value={line.quantity}
                        onChange={(e) => handleLineQty(e.target.value, index)}
                        id={`line-${index}-qty`}
                        placeholder=" "
                        ref={skuInput}
                      />
                      <label for={`line-${index}-qty`}>Qty</label>
                    </div>

                    {/* onBlur={index === lines?.length - 1 ? handleNewLine : doNothing} */}

                    <div className="col form-floating px-0">
                      <input
                        className="form-control"
                        value={line.comments}
                        onChange={(e) =>
                          handleLineComment(e.target.value, index)
                        }
                        id={`line-${index}-comments`}
                        placeholder=" "
                        onBlur={
                          index === lines?.length - 1
                            ? handleNewLine
                            : doNothing
                        }
                      />
                      <label for={`line-${index}-comments`}>Notes</label>
                    </div>

                    <div className="col form-floating px-0">
                      <input
                        className="form-control"
                        value={line.description}
                        id={`line-${index}-description`}
                        placeholder=" "
                        readOnly
                        tabIndex="-1"
                      />
                      <label for={`line-${index}-description`}>
                        Description
                      </label>
                    </div>

                    <div className="col form-floating px-0">
                      <input
                        className="form-control"
                        value={
                          typeof line.drop == 'number'
                            ? line.drop.toFixed(2)
                            : line.drop
                        }
                        id={`line-${index}-drop`}
                        placeholder=" "
                        readOnly
                        tabIndex="-1"
                        type="number"
                        step="0.01"
                      />
                      <label for={`line-${index}-drop`}>Drop</label>
                    </div>

                    <div className="col form-floating px-0">
                      <input
                        className="form-control"
                        value={
                          typeof line.flats == 'number'
                            ? line.flats.toFixed(2)
                            : line.flats
                        }
                        id={`line-${index}-flats`}
                        placeholder=" "
                        readOnly
                        tabIndex="-1"
                        type="number"
                        step="0.01"
                      />
                      <label for={`line-${index}-flats`}>Flats</label>
                    </div>

                    <div className="col form-floating px-0">
                      <input
                        className="form-control"
                        value={
                          typeof line.price == 'number'
                            ? line.price.toFixed(2)
                            : line.price
                        }
                        id={`line-${index}-price`}
                        placeholder=" "
                        readOnly
                        tabIndex="-1"
                        type="number"
                        step="0.01"
                      />
                      <label for={`line-${index}-price`}>Price</label>
                    </div>

                    <div className="col form-floating  px-0">
                      <input
                        className="form-control"
                        value={
                          typeof line.total == 'number'
                            ? line.total.toFixed(2)
                            : line.total
                        }
                        id={`line-${index}-total`}
                        placeholder=" "
                        readOnly
                        tabIndex="-1"
                        type="number"
                        step="0.01"
                      />
                      <label for={`line-${index}-total`}>total</label>
                    </div>
                    <div className="col">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteLine(index)}
                        tabIndex="-1"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
                {/* End of map/forEach */}
              </div>

              <div>
                <div className="form-floating float-end">
                  <input
                    className="form-control"
                    value={
                      typeof subTotal == 'number'
                        ? subTotal.toFixed(2)
                        : subTotal
                    }
                    id="subtotal"
                    placeholder=" "
                    readOnly
                    tabIndex="-1"
                    type="number"
                    step="0.01"
                  />
                  <label for="subtotal">Subtotal</label>
                </div>
              </div>
            </form>
          </Card>
          {accModalOpen && (
            <QuoteAccModal
              openState
              accList={accList}
              restOfQuote={{
                subTotal,
                panelsRaw,
                panelClass,
                coating,
                color,
                metal,
              }}
              linesRaw={lines}
              quoteId={quoteId}
            />
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}
