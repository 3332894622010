import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row, Button, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import {
  JobDetail,
  NoteIndex,
  NoteCreateModal,
  NoteCreateForm,
  Loader,
  Message,
  JobValidate,
  JobDuplicate,
} from '../../components/componentLib'
import { getJobById, deleteJobById, updateJobStatus } from '../../actions/jobActions'
import { RBA_CAD, RBA_ADMIN } from '../../utils/rba'
import Moment from 'react-moment'

export const JobDetailLayout = ({ history, match }) => {
  let jobId = match.params.id

  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    }
  }, [userInfo, history])

  //GET Job info (this is redundant, as this also takes place in the jobDetail component)
  const dispatch = useDispatch()
  useEffect(() => {
    if (userInfo) {
      dispatch(getJobById(jobId))
    }
  }, [dispatch, userInfo, jobId])
  const jobDetail = useSelector((state) => state.jobDetail)
  const { loading, error, job } = jobDetail
  let jobStatus = job?.statusCurrent
  let jobName = job?.name
  let isMrm = job?.mrmProjectId ? true : false

  //Note Modal LOCAL SCOPE
  const [noteAction, setNoteAction] = useState('none')
  const [noteVariant, setNoteVariant] = useState('success')
  const [noteDescription, setNoteDescription] = useState(' Add a Note')
  const [noteIcon, setNoteIcon] = useState('fas fa-sticky-note')
  const [noteModalIsOpen, setNoteModalIsOpen] = useState(false)
  const [showReviewForm, setShowReviewForm] = useState(false)
  const [noteExplicit, setNoteExplicit] = useState(false)
  const handleCreateNote = (e) => {
    console.log('ntoe modal open')
    setNoteExplicit(false)
    setNoteModalIsOpen(!noteModalIsOpen)
  }

  const handleCreateNoteExplicit = (e) => {
    console.log('ntoe modal open Explict')
    setNoteExplicit(true)
    setNoteModalIsOpen(!noteModalIsOpen)
  }

  useEffect(() => {
    if (jobStatus === 'Released') {
      setNoteAction('Revision')
      setNoteVariant('warning')
      setNoteDescription(' Request a Revision')
      setNoteIcon('fas fa-marker')
    } else {
      setNoteAction('none')
      setNoteVariant('success')
      setNoteDescription(' Add a Note')
      setNoteIcon('fas fa-sticky-note')
    }
    if (['Working', 'Correction', 'Revision'].indexOf(jobStatus) >= 0 && RBA_CAD.includes(userInfo?.role)) {
      setShowReviewForm(true)
    } else {
      setShowReviewForm(false)
    }
  }, [jobStatus, userInfo?.role])

  const deleteJobHandler = () => {
    console.log(`delete: ${jobId}`)
    dispatch(deleteJobById(jobId))
  }

  const rushJobHandler = () => {
    console.log(`rush: ${jobId}`)
    dispatch(updateJobStatus(jobId, 'rush'))
  }

  const setRevHandler = (e) => {
    e.preventDefault()
    console.log(`rev: ${e.target.value}`)
    dispatch(updateJobStatus(jobId, 'maxRevs', e.target.value))
  }

  return (
    <>
      {jobName && (
        <Helmet>
          <title>MRQ | {jobName}</title>
          <meta name="description" content="Metal roof takeoffs" />
        </Helmet>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">Layout error: {error}</Message>
      ) : job && job._id ? (
        <Row>
          {isMrm && job && <Message variant="warning">{'This is an MRM job'}</Message>}
          <Col lg={showReviewForm ? 6 : 8} className="pr-3 pl-1">
            <JobDetail jobId={jobId} history={history} />
          </Col>
          {showReviewForm && (
            <Col lg={2} className="pr-1 pl-1">
              <NoteCreateForm
                jobId={jobId}
                noteAction="Review"
                num={job?.num}
                pan1Width={job?.pan1Width}
                pan2Width={job?.pan2Width}
                userRole={userInfo?.role}
              />
            </Col>
          )}
          <Col lg={4} className="pr-1 pl-3">
            <Card>
              {/* CAD panel */}
              {userInfo?.role === 'cad' && (
                <Card className="text-center mb-2">
                  <p className="ml-1 mb-0">
                    Time in:{' '}
                    <strong>
                      <Moment format="ddd MMM Do hh:mm">{job.timeIn}</Moment>
                    </strong>
                    {job.timeOut && (
                      <p className="ml-1 mb-0">
                        Time out:{' '}
                        <strong>
                          <Moment format="ddd MMM Do hh:mm">{job.timeOut}</Moment>
                        </strong>
                      </p>
                    )}
                  </p>
                  <div>
                    <JobValidate
                      jobId={jobId}
                      admin={true}
                      billingStatusCurrent={job.billingStatusCurrent}
                      jobName={job.name}
                      jobStatusCurrent={job.statusCurrent}
                      isCad={true}
                      isMrm={isMrm}
                    />
                  </div>
                </Card>
              )}
              {/* End CAD panel */}
              {/* Admin panel */}
              {RBA_ADMIN.includes(userInfo?.role) && (
                <Card className="text-center">
                  <p className="mb-0">
                    Max Revs: <strong>{job.maxRevisions}</strong> | Set new max Revs to:{' '}
                    <Button className="m-0 py-1 btn btn-danger" value={job.maxRevisions + 1} onClick={setRevHandler}>
                      {job.maxRevisions + 1}
                    </Button>
                  </p>
                  <p className="ml-1 mb-0">
                    <Button
                      className="btn btn-primary py-1 mx-1"
                      onClick={rushJobHandler}
                      style={{ maxWidth: '150px' }}
                    >
                      <i className="fa-solid fa-truck-fast"></i> {job?.rush ? 'Set to no Rush' : 'Set to Rush'}
                    </Button>
                    Time in:{' '}
                    <strong>
                      <Moment format="ddd MMM Do hh:mm a">{job.timeIn}</Moment>
                    </strong>{' '}
                    {job.timeOut && (
                      <>
                        <br />
                        Time out:{' '}
                        <strong>
                          <Moment format="ddd MMM Do hh:mm">{job.timeOut}</Moment>
                        </strong>
                      </>
                    )}
                  </p>
                  <p className="mb-0">Billing Status: {job.billingStatusCurrent} </p>
                  <p className="mb-0">
                    Current Level: <strong>{job.level}</strong>{' '}
                    <span className="text-muted">Regrade level (no status change)</span>
                  </p>
                  {job.billingStatusCurrent == 'Invoiced' ? (
                    <>
                      <p>Job has been invoiced</p>
                      <div className="no-flex align-items-center">
                        <JobDuplicate jobId={jobId} jobName={job.name} isMrm={isMrm} />
                      </div>
                    </>
                  ) : (
                    <div className="align-items-center">
                      <JobValidate
                        jobId={jobId}
                        admin={true}
                        billingStatusCurrent={job.billingStatusCurrent}
                        jobName={job.name}
                        jobStatusCurrent={job.statusCurrent}
                        isMrm={isMrm}
                      />
                    </div>
                  )}

                  {job.billingStatusCurrent !== 'Invoiced' && job.statusCurrent !== 'Released' && (
                    <div className="align-items-center">
                      <Button className="btn btn-danger" onClick={deleteJobHandler} style={{ maxWidth: '150px' }}>
                        <i className="fas fa-trash"></i> Delete
                      </Button>
                    </div>
                  )}
                </Card>
              )}
              {/* End admin panel */}
              {/* ALWAYS HAVE A NOTE BUTTON FOR CAD/ADMIN */}
              {RBA_CAD.includes(userInfo?.role) && jobStatus === 'Released' && isMrm !== true && (
                <Button className="my-2" variant="success" block onClick={handleCreateNoteExplicit}>
                  <i className="fas fa-sticky-note" /> Add a Note
                </Button>
              )}
              {/* CREATE NOTE BUTTON */}
              {(isMrm !== true || jobStatus === 'Released') && (
                <Button className="my-2" variant={noteVariant} block onClick={handleCreateNote}>
                  <i className={noteIcon} />
                  {noteDescription}
                </Button>
              )}
              {/* NOTE INDEX */}
              {isMrm && (
                <Message variant="warning">
                  {'Notes are not visible to the MRM customer. Only the information from Submit RFI is visible'}
                </Message>
              )}
              <NoteIndex
                jobId={jobId}
                jobRev={job.revision}
                jobCorr={job.correction}
                jobStatus={jobStatus}
                cadId={job.cadCurrent?._id}
                isMrm={isMrm}
              />
            </Card>

            {/* MODAL */}
            {noteModalIsOpen && (
              <NoteCreateModal jobId={jobId} openState noteAction={noteExplicit ? 'none' : noteAction} />
            )}
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  )
}
