import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { Card, TextField, InputLabel, FormControl, Select, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import { Autocomplete } from '@material-ui/lab'

import { Loader, Message } from '../../components/componentLib'
import { updateUserProfile, deleteUserById } from '../../actions/userActions'
import { getAllClients } from '../../actions/clientActions'

export const UserDetailAdmin = ({ user, history }) => {
  //LOCAL scope for form

  const [userRole, setUserRole] = useState('')
  const [userClient, setUserClient] = useState('')
  const [userClientNum, setUserClientNum] = useState('')
  const [userClientName, setUserClientName] = useState('')
  const [userCompanyName, setUserCompanyName] = useState('')
  const [userClientInits, setUserClientInits] = useState('')
  const [userClientNameInput, setUserClientNameInput] = useState('')
  const [loadingLocal, setLoadingLocal] = useState(false)
  const [addClient, setAddClient] = useState(false)
  const [removeClient, setRemoveClient] = useState(false)
  const [userHasQuoter, setUserHasQuoter] = useState(user.hasQuoter)

  const error = false
  const dispatch = useDispatch()

  const clientIndex = useSelector((state) => state.clientIndex)
  const { clients } = clientIndex

  useEffect(() => {
    setUserRole(user?.role || '')
    setUserHasQuoter(user.hasQuoter)
    dispatch(getAllClients())
  }, [user, dispatch])

  const deleteHandler = (e) => {
    e.preventDefault()
    dispatch(deleteUserById(user._id))
    history?.push('/users')
  }

  const submitHandler = (e) => {
    e.preventDefault()
    console.log('submit')
    console.log(userRole)
    console.log(userCompanyName)
    dispatch(
      updateUserProfile(
        {
          id: user._id,
          client: userClient,
          clientNum: userClientNum,
          role: userRole,
          companyName: userCompanyName,
          initials: userClientInits,
          addClient: addClient,
          removeClient: removeClient,
          hasQuoter: userHasQuoter,
        },
        user._id
      )
    )
  }

  const clientSelectHandler = (clientSelect) => {
    if (clientSelect) {
      setUserClientNum(clientSelect.num)
      setUserClient(clientSelect._id)
      setUserClientInits(clientSelect.inits)
      setUserCompanyName(clientSelect.companyName)
      console.log(userCompanyName)
    } else {
      setUserClientNum('')
      setUserClient('')
      setUserClientInits('')
      setUserCompanyName('')
    }
  }

  return (
    <>
      {loadingLocal ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : user && user?._id && clients ? (
        <Card>
          <h4>Admin Control Panel</h4>
          <h6>To activate new users: Set role to user, select client</h6>
          {user.clientNum}
          <Form onSubmit={submitHandler}>
            <FormControl style={{ minWidth: 300 }}>
              <InputLabel htmlFor='user-role'>Select User Role</InputLabel>
              <Select
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
                inputProps={{
                  name: 'role',
                  id: 'user-role',
                }}
              >
                <option value='viewer'>viewer</option>
                <option value='client'>client</option>
                <option value='customer'>customer</option>
                <option value='cad'>cad</option>
                <option value='admin'>admin </option>
                <option value='super'>super</option>
              </Select>
            </FormControl>
            <div>
              Current Client Assigned:{' '}
              {user.companyName ? (
                <strong>
                  {user.companyName} ({user.initials})
                </strong>
              ) : (
                <strong>Not Assigned</strong>
              )}
            </div>
            {userHasQuoter ? (
              <div className='py-2'>
                <span className='mark' onClick={(e) => setUserHasQuoter(!userHasQuoter)}>
                  Quoter Access: Authorized (click to unauthorize)
                </span>
              </div>
            ) : (
              <div className='py-2'>
                <span className='ml-2 del' onClick={(e) => setUserHasQuoter(!userHasQuoter)}>
                  Quoter Access: NOT Authorized (click to authorize)
                </span>
              </div>
            )}
            <Form.Group controlId='add-remove'>
              <Form.Check
                type='radio'
                label='Add user to client'
                checked={addClient}
                onChange={(e) => setAddClient(!addClient)}
                name='clientAction'
              />
              <Form.Check
                type='radio'
                label='Remove user from client'
                checked={removeClient}
                onChange={(e) => setRemoveClient(!removeClient)}
                name='clientAction'
              />
            </Form.Group>
            {addClient && (
              <>
                <FormControl>
                  <Autocomplete
                    id='combo-box-demo'
                    options={clients}
                    getOptionLabel={(option) => `${option.companyName} | ${option.num?.toString()}`}
                    style={{ width: 300 }}
                    value={userClientName}
                    onChange={(event, newValue) => {
                      setUserClientName(newValue)
                      clientSelectHandler(newValue)
                    }}
                    inputValue={userClientNameInput}
                    onInputChange={(event, newInputValue) => {
                      setUserClientNameInput(newInputValue)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Assign Client' variant='outlined' />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    label='Client Num'
                    value={userClientNum}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </FormControl>
                <FormControl>
                  <TextField
                    label='Client Inits'
                    value={userClientInits}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </FormControl>
                <FormControl>
                  <TextField
                    label='Client ID'
                    value={userClient}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                </FormControl>
              </>
            )}
            {/* --end client form */}
            <div>
              <Button
                variant='contained'
                color='primary'
                startIcon={<SaveIcon />}
                onClick={submitHandler}
              >
                Update User Info
              </Button>
            </div>
          </Form>
          <hr></hr>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<DeleteIcon />}
            onClick={deleteHandler}
          >
            Delete User
          </Button>
        </Card>
      ) : (
        ''
      )}
    </>
  )
}
