import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
// import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { Header, Footer } from './components/componentLib'
import {
  HomeLayout,
  LoginLayout,
  RegisterLayout,
  UserDetailLayout,
  UserIndexLayout,
  JobDetailLayout,
  JobIndexLayout,
  ClientIndexLayout,
  ClientDetailLayout,
  LostPasswordLayout,
  ResetPasswordLayout,
  AdminBillingLayout,
  AdminDashboardLayout,
  ItemIndexLayout,
  CustomerIndexLayout,
  QuoteDetailLayout,
  QuoteIndexLayout,
  ToolFlatCalcLayout,
  ToolPackListLayout,
} from './layouts/layoutLib'

const App = () => {
  return (
    <Router>
      <Helmet>
        <title>Metal Roof Quoter</title>
        <meta name="description" content="Metal roof takeoffs" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <Header />
      <main className="py-3">
        <Container fluid>
          <Route path="/" component={HomeLayout} exact />
          <Route path="/login" component={LoginLayout} />
          <Route path="/register" component={RegisterLayout} />
          <Route path="/forgot-password" component={LostPasswordLayout} />
          <Route
            path="/password/reset/:userId/:token"
            component={ResetPasswordLayout}
          />
          <Route path="/profile" component={UserDetailLayout} />
          <Route path="/users" component={UserIndexLayout} exact />
          <Route path="/users/:id" component={UserDetailLayout} />
          <Route path="/jobs/" component={JobIndexLayout} exact />
          <Route path="/jobs/:id" component={JobDetailLayout} />
          <Route path="/clients/" component={ClientIndexLayout} exact />
          <Route path="/clients/:id" component={ClientDetailLayout} />
          <Route path="/admin/billing" component={AdminBillingLayout} exact />
          <Route path="/admin/data" component={AdminDashboardLayout} exact />
          <Route path="/items" component={ItemIndexLayout} exact />
          <Route path="/customers" component={CustomerIndexLayout} exact />
          <Route path="/quotes" component={QuoteIndexLayout} exact />
          <Route path="/quotes/:id" component={QuoteDetailLayout} />
          <Route path="/tools/flatcalc/:url" component={ToolFlatCalcLayout} />
          <Route path="/tools/packlist/:url" component={ToolPackListLayout} />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
