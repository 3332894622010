import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'

export const JobCopyData = ({ job }) => {
  const [tableClass, setTableClass] = useState(true)
  let table = document.querySelector('#dataTableCopy')
  const selectNode = (node) => {
    let range = document.createRange()
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  }
  const deselectNode = (node) => {
    let range = document.createRange()
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
  }

  const copyHandler = () => {
    setTableClass(!tableClass)
    selectNode(table)
    document.execCommand('copy')
    deselectNode(table)
  }

  return (
    <>
      <div className="py-2">
        Job Folder:
        <a
          href={`https://drive.google.com/drive/folders/${job.gdriveFolder}`}
          target="_blank"
          rel="noreferrer"
        >
          Drive: {job.gdriveFolder}
        </a>
      </div>

      <Button variant="info" onClick={copyHandler}>
        <i class="fas fa-copy"></i> Copy/Toggle Data Row
      </Button>
      <div
        id="dataTableCopyDiv"
        className={tableClass ? 'display-offscreen' : 'table-responsive-md'}
      >
        <Table id="dataTableCopy">
          <tbody>
            <tr>
              <td className="p-1 mw-10">{job.owner?.inits}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1 mw-10">{job.num}</td>
              <td className="p-1 mw-10">{job.owner?.inits}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1 mw-10">{job.owner?.companyName}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.name}</td>
              <td className="p-1">
                {job.street} {job.city} {job.state}
              </td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.pan1Name}</td>
              <td className="p-1">{job.pan1Width}</td>
              <td className="p-1">{job.pan2Name}</td>
              <td className="p-1">{job.pan2Width}</td>
              <td className="p-1">{job.specialReq}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.owner?.inits}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
