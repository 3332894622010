import {
  QBO_SETUP_GET_FAIL,
  QBO_SETUP_GET_REQUEST,
  QBO_SETUP_GET_SUCCESS,
} from '../actions/constants'

export const qboSetupReducer = (state = {}, action) => {
  switch (action.type) {
    case QBO_SETUP_GET_REQUEST:
      return { loading: true }
    case QBO_SETUP_GET_SUCCESS:
      return { loading: false, qboInit: action.payload }
    case QBO_SETUP_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
