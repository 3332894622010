import {
  JOBS_GET_ALL_REQUEST,
  JOBS_GET_ALL_SUCCESS,
  JOBS_GET_ALL_FAIL,
  JOB_GET_DETAIL_REQUEST,
  JOB_GET_DETAIL_SUCCESS,
  JOB_GET_DETAIL_FAIL,
  JOB_CREATE_SUCCESS,
  JOB_CREATE_REQUEST,
  JOB_CREATE_FAIL,
  JOB_UPDATE_STATUS_REQUEST,
  JOB_UPDATE_STATUS_SUCCESS,
  JOB_UPDATE_STATUS_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SUCCESS,
  JOB_DELETE_FAIL,
  JOB_INVOICE_REQUEST,
  JOB_INVOICE_SUCCESS,
  JOB_INVOICE_FAIL,
  JOB_DATA_REQUEST,
  JOB_DATA_SUCCESS,
  JOB_DATA_FAIL,
  BILLING_DATA_REQUEST,
  BILLING_DATA_SUCCESS,
  BILLING_DATA_FAIL,
  DASHBOARD_DATA_REQUEST,
  DASHBOARD_DATA_SUCCESS,
  DASHBOARD_DATA_FAIL,
} from '../actions/constants'

export const jobIndexReducer = (state = { jobs: [] }, action) => {
  switch (action.type) {
    case JOBS_GET_ALL_REQUEST:
      return { loading: true, ...state }
    case JOBS_GET_ALL_SUCCESS:
      return { loading: false, jobs: action.payload }
    case JOBS_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    case JOB_CREATE_REQUEST:
      return { loadingCreate: true, ...state }
    case JOB_CREATE_SUCCESS:
      return {
        loadingCreate: false,
        jobs: [action.payload, ...state.jobs] || [action.payload],
      }
    case JOB_CREATE_FAIL:
      return { loadingCreate: false, errorCreate: action.payload }
    case JOB_UPDATE_STATUS_SUCCESS:
      return {
        jobs: state.jobs?.map((x) => {
          const job = x._id === action.payload._id ? action.payload : null
          return job ? job : x
        }),
      }
    default:
      return state
  }
}

export const jobDetailReducer = (state = { job: [] }, action) => {
  switch (action.type) {
    case JOB_GET_DETAIL_REQUEST:
      return { loading: true, ...state }
    case JOB_GET_DETAIL_SUCCESS:
      return { loading: false, job: action.payload }
    case JOB_GET_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case JOB_UPDATE_STATUS_REQUEST:
      return { loading: true, ...state }
    case JOB_UPDATE_STATUS_SUCCESS:
      return { loading: false, job: action.payload }
    case JOB_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload }
    case JOB_INVOICE_REQUEST:
      return { loading: true, ...state }
    case JOB_INVOICE_SUCCESS:
      return { loading: false, job: action.payload }
    case JOB_INVOICE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const jobDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_DELETE_REQUEST:
      return { loading: true }
    case JOB_DELETE_SUCCESS:
      return { loading: false, success: true }
    case JOB_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const jobDataReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_DATA_REQUEST:
      return { loading: true }
    case JOB_DATA_SUCCESS:
      return { loading: false, dataWeekly: action.payload }
    case JOB_DATA_FAIL:
      return { loading: false, error: action.payload }
    case BILLING_DATA_REQUEST:
      return { loadingBilling: true }
    case BILLING_DATA_SUCCESS:
      return { loadingBilling: false, dataBilling: action.payload }
    case BILLING_DATA_FAIL:
      return { loadingBilling: false, error: action.payload }
    case DASHBOARD_DATA_REQUEST:
      return { loadingDashboard: true }
    case DASHBOARD_DATA_SUCCESS:
      return { loadingDashboard: false, dataDashboard: action.payload }
    case DASHBOARD_DATA_FAIL:
      return { loadingDashboard: false, errorDashboard: action.payload }
    default:
      return state
  }
}
