import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment-timezone'

import { Button } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BackspaceIcon from '@material-ui/icons/Backspace'
import {
  deleteQuotePdf,
  getQuotesByJobId,
  printQuoteToPdf,
  publishQuoteToJob,
} from '../../actions/quoteActions'

export const QuotePdf = ({ userInfo, quoteId }) => {
  const dispatch = useDispatch()
  console.log(`quoteId in: ${quoteId}`)

  useEffect(() => {
    if (userInfo) {
      dispatch(getQuotesByJobId(quoteId))
    }
  }, [dispatch, quoteId, userInfo])

  const quoteDetail = useSelector((state) => state.quoteDetail)
  const { loading, error, quote } = quoteDetail

  const handleSavePdf = () => {
    dispatch(printQuoteToPdf(quoteId))
    console.log('printed')
  }

  const handlePublishPdfToJob = (item) => {
    if (item.published) {
      item.published = false
      dispatch(publishQuoteToJob(quote.job, quote._id, item))
      console.log('unpub')
    } else {
      item.published = true
      dispatch(publishQuoteToJob(quote.job, quote._id, item))
      console.log(quote.job)
      console.log(quote._id)
      console.log(item)
      console.log('emailed')
    }
  }

  const handleDeletePdf = (url) => {
    dispatch(deleteQuotePdf(quoteId, url))
    console.log('deleted')
  }

  return (
    <>
      {quote ? (
        <>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={handleSavePdf}
            startIcon={<PrintIcon />}
          >
            Print to PDF
          </Button>
          <div>
            {quote && quote.quotePdf && (
              <>
                Printed Quotes:
                {quote.quotePdf.map((item, index) => (
                  <div key={`div-${index}`}>
                    <a key={`lin-${index}`} href={item.url} target='blank'>
                      <Moment key={`mom-${index}`} format='ddd MMM Do, h:mm:ss a'>
                        {item.time}
                      </Moment>
                      : {item.name}
                    </a>{' '}
                    <Button
                      key={`pub-${index}`}
                      variant={item.published ? 'outlined' : 'outlined'}
                      size='small'
                      color={item.published ? 'default' : 'primary'}
                      onClick={(e) => handlePublishPdfToJob(item)}
                      endIcon={item.published ? <BackspaceIcon /> : <MailOutlineIcon />}
                    >
                      {item.published ? 'UN-Publish' : 'Publish'}
                    </Button>{' '}
                    <Button
                      key={`del-${index}`}
                      variant='outlined'
                      size='small'
                      color='secondary'
                      onClick={(e) => handleDeletePdf(item.url)}
                      startIcon={<DeleteOutlineIcon />}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className='pt-5'>
            <Button component={Link} to={`/jobs/${quote.job}`} variant='outlined' color='primary'>
              Go to Estimate/Takeoff page
            </Button>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  )
}
