import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Button, Form } from 'react-bootstrap'
import Moment from 'react-moment'
import 'moment-timezone'

import { RBA_ADMIN, RBA_CLIENT } from '../../utils/rba'
import { updateClient } from '../../actions/clientActions'
//import { Discovery } from 'aws-sdk'

export const ClientDetails = ({ client, userInfo }) => {
  //const LOGO_URI = process.env.REACT_APP_CLIENT_LOGO_URL
  const LOGO_URI =
    'https://mrq-react-file-space.nyc3.digitaloceanspaces.com/logos'

  const dispatch = useDispatch()

  const handleUpdate = (e) => {
    e.preventDefault()
    console.log('update')
    const updatedClient = {
      _id: client._id,
      companyName,
      gdriveFolderId,
      officeAddressOne: addressOne,
      officeAddressTwo: addressTwo,
      metals,
      paints,
      colors,
      defaultFlat,
      clientNotes,
    }
    console.log(updatedClient)
    dispatch(updateClient(updatedClient))
    setEditActive(false)
  }

  // LOCAL state for form...
  const [companyName, setCompanyName] = useState('')
  const [addressOne, setAddressOne] = useState('')
  const [addressTwo, setAddressTwo] = useState('')
  const [metals, setMetals] = useState('')
  const [paints, setPaints] = useState('')
  const [colors, setColors] = useState('')
  const [defaultFlat, setDefaultFlat] = useState('')
  const [gdriveFolderId, setGdriveFolderId] = useState('')
  const [clientNotes, setClientNotes] = useState('')

  useEffect(() => {
    if (client) {
      setCompanyName(client.companyName)
      setAddressOne(client.officeAddressOne)
      setAddressTwo(client.officeAddressTwo)
      setMetals(client.metals)
      setPaints(client.paints)
      setColors(client.colors)
      setDefaultFlat(client.defaultFlat)
      setGdriveFolderId(client.gdriveFolderId)
      setClientNotes(client.clientNotes)
    }
  }, [client])

  const [estPricing, setEstPricing] = useState('')

  const [editActive, setEditActive] = useState(false)

  return (
    <>
      {client && (
        <Card>
          <Card.Header className="text-center">
            <img
              className="image-oversize-50"
              src={`${LOGO_URI}/${client.inits}.png`}
              alt={`${client.companyName} Logo`}
            ></img>
            <h5 className="card-title mb-2 text-center">
              <p className="mb-1">
                <strong>
                  {client.companyName} ({client.inits})
                </strong>
              </p>
            </h5>

            {editActive ? (
              <Form onSubmit={handleUpdate}>
                <Form.Group className="mb-1" controlId="companyName">
                  <Form.Control
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  ></Form.Control>
                  <Form.Label className="mb-1">Name</Form.Label>
                </Form.Group>
                <Form.Group className="mb-1" controlId="addressOne">
                  <Form.Control
                    type="text"
                    value={addressOne}
                    onChange={(e) => setAddressOne(e.target.value)}
                  ></Form.Control>
                  <Form.Label className="mb-0">Address 1</Form.Label>
                </Form.Group>
                <Form.Group className="mb-1" controlId="addressTwo">
                  <Form.Control
                    type="text"
                    value={addressTwo}
                    onChange={(e) => setAddressTwo(e.target.value)}
                  ></Form.Control>
                  <Form.Label className="mb-1">Address 2</Form.Label>
                </Form.Group>
                {/* End user access, begin quoter access */}
                {((userInfo.hasQuoter && RBA_CLIENT.includes(userInfo?.role)) ||
                  RBA_ADMIN.includes(userInfo?.role)) && (
                  <>
                    Separate with commas, no spaces.
                    <Form.Group className="mb-1" controlId="metals">
                      <Form.Control
                        type="text"
                        value={metals}
                        onChange={(e) =>
                          setMetals(String(e.target.value).split(','))
                        }
                      ></Form.Control>
                      <Form.Label className="mb-1">Metal Types</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="paints">
                      <Form.Control
                        type="text"
                        value={paints}
                        onChange={(e) => setPaints(e.target.value.split(','))}
                      ></Form.Control>
                      <Form.Label className="mb-1">Paint Types</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="colors">
                      <Form.Control
                        type="text"
                        value={colors}
                        onChange={(e) => setColors(e.target.value.split(','))}
                      ></Form.Control>
                      <Form.Label className="mb-1">Colors</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="colors">
                      <Form.Control
                        type="number"
                        value={defaultFlat}
                        onChange={(e) => setDefaultFlat(e.target.value)}
                      ></Form.Control>
                      <Form.Label className="mb-1">
                        Default Flat Width (INCHES!)
                      </Form.Label>
                    </Form.Group>
                  </>
                )}

                {/* End quoter access, begin admin access */}
                {RBA_ADMIN.indexOf(userInfo.role) >= 0 && (
                  <>
                    <Form.Group className="mb-1" controlId="clientNotes">
                      <Form.Control
                        type="text"
                        value={clientNotes}
                        onChange={(e) => setClientNotes(e.target.value)}
                      ></Form.Control>
                      <Form.Label className="mb-1">CLIENT NOTES</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="gdriveFolderId">
                      <Form.Control
                        type="text"
                        value={gdriveFolderId}
                        onChange={(e) => setGdriveFolderId(e.target.value)}
                      ></Form.Control>
                      <Form.Label className="mb-1">GDrive folder</Form.Label>
                    </Form.Group>
                  </>
                )}
                <Button onClick={handleUpdate} variant="primary">
                  Update Client
                </Button>
                <Button
                  variant="secondary"
                  onClick={(e) => setEditActive(!editActive)}
                >
                  Cancel
                </Button>
              </Form>
            ) : (
              // SHOW WHEN EDIT NOT ACTIVE
              <>
                <p>
                  {client.officeAddressOne} <br />
                  {client.officeAddressTwo}
                </p>
                {((userInfo.hasQuoter && RBA_CLIENT.includes(userInfo?.role)) ||
                  RBA_ADMIN.includes(userInfo?.role)) && (
                  <p>
                    Metal Types: <br />
                    {client.metals} <br />
                    Paint Types: <br />
                    {client.paints} <br />
                    Colors: <br />
                    {client.colors} <br />
                    Default Flat Width: <br />
                    {client.defaultFlat} inches <br />
                  </p>
                )}
                {/* End user access, begin admin access */}
                {RBA_ADMIN.indexOf(userInfo.role) >= 0 && (
                  <div>
                    Company Number: <strong>{client.num}</strong> <br />
                    Client Notes: <strong>{client.clientNotes}</strong> <br />
                    Drive Folder:{' '}
                    <a
                      href={`https://drive.google.com/drive/folders/${client.gdriveFolderId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {client.gdriveFolderId}{' '}
                    </a>
                    {client.estPricing && (
                      <div>
                        Pricing Scheme:
                        <strong>{client.estPricing[0]['scheme']}</strong> <br />
                        Level 1: <strong>{client.estPricing[0].L1} </strong>
                        Level 2: <strong>{client.estPricing[0].L2} </strong>
                        Level 3: <strong>{client.estPricing[0].L3} </strong>
                        Level 4: <strong>{client.estPricing[0].L4} </strong>
                      </div>
                    )}
                    {client.lastJobRequest && (
                      <>
                        Last job Request:{' '}
                        <Moment format="MMMM Do YYYY, h:mm:ss a">
                          {client.lastJobRequest}
                        </Moment>
                      </>
                    )}
                  </div>
                )}
                {/* End admin access */}
              </>
            )}
            {/* End show when EDIT not active */}
          </Card.Header>
          <Card.Body>
            {/* <strong>Users Assigned:</strong>
            {client.users?.map((user) => (
              <span>
                <br /> {user.name}
              </span>
            ))} */}
            <br />
            <strong>Users/Emails:</strong>
            {client.emails?.map((email) => (
              <span key={email}>
                <br /> {email}
              </span>
            ))}
          </Card.Body>
          <Card.Footer>
            {!editActive ? (
              <Button onClick={(e) => setEditActive(!editActive)}>
                Edit Client
              </Button>
            ) : (
              <span>
                Please contact us to add or remove users from your company
                account
              </span>
            )}
          </Card.Footer>
        </Card>
      )}
    </>
  )
}
