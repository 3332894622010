import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createCustomer } from '../../actions/customerActions'

export const CustomerCreateModal = ({ openState }) => {
  const dispatch = useDispatch()

  //LOCAL state
  const [isOpen, setIsOpen] = useState(openState)
  const [name, setName] = useState('')
  const [customerNumForClient, setCustomerNumForClient] = useState('')
  const [priceCode, setPriceCode] = useState('')
  const [emails, setEmails] = useState('')
  const [shortName, setShortName] = useState('')

  //Handlers, etc-----
  const closeHandler = () => {
    console.log('close handler')
    setIsOpen(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    const customer = {
      name,
      customerNumForClient,
      priceCode,
      emails,
      shortName,
    }
    dispatch(createCustomer(customer))
    setIsOpen(false)
  }

  return (
    <Modal show={isOpen} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Create a new Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type='text'
              autoFocus
              placeholder='ABC Roofing...'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='shortName'>
            <Form.Label className='mb-0'>Customer shortName</Form.Label>
            <small className='form-text text-muted'>
              Your customer won't see this. It's just a shorter reference to use.
            </small>
            <Form.Control
              type='text'
              placeholder='ABC'
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='customerNumForClient'>
            <Form.Label>Customer #</Form.Label>
            <Form.Control
              type='text'
              placeholder='123'
              value={customerNumForClient}
              onChange={(e) => setCustomerNumForClient(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='priceCode'>
            <Form.Label>Price Code</Form.Label>
            <Form.Control
              type='text'
              placeholder='A, B, BB, etc'
              value={priceCode}
              onChange={(e) => setPriceCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='emails'>
            <Form.Label>Emails</Form.Label>
            <Form.Control
              type='text'
              placeholder='steve@email.com, jill@email.com, etc'
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant='primary' onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
