import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Button, Container, Card } from 'react-bootstrap'
import axios from 'axios'
import { FormContainer } from '../../components/componentLib'

export const ResetPasswordLayout = ({ match, history }) => {
  const SERVER_URI = '/' //'http://localhost:3000/'
  const [password, setPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  // useEffect(() => {
  //   if (userInfo) {
  //     history?.push('/')
  //   }
  // }, [userInfo, history])

  const submitHandler = (e) => {
    e.preventDefault()
    const userId = match.params.userId
    const token = match.params.token
    axios
      .post(`${SERVER_URI}api/v1/users/reset-password/${userId}/${token}`, {
        password,
      })
      .then((res) => {
        console.log('RESPONSE FROM SERVER TO CLIENT:', res)
        setSuccess(true)
      })
      .catch((err) => {
        console.log('SERVER ERROR TO CLIENT:', err)
        setError(err)
      })
    setSubmitted(true)
  }
  return (
    <Container>
      <Card style={{ width: '33rem' }}>
        <FormContainer>
          <h5 style={{ paddingBottom: '1.25rem' }}>Update your password</h5>
          {submitted ? (
            <div>
              <p>Your password has been saved.</p>
              <Link to="/login">Sign in now</Link>
            </div>
          ) : (
            <div>
              <Form onSubmit={submitHandler}>
                <Form.Control
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="New password"
                  type="password"
                />
                <Button type="submit" variant="primary" className="mt-2">
                  Update password
                </Button>
              </Form>
            </div>
          )}
        </FormContainer>
      </Card>
    </Container>
  )
}
