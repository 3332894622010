import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Card, Form } from 'react-bootstrap'
import Moment from 'react-moment'
import 'moment-timezone'
import { Link } from 'react-router-dom'

import { getAllJobs } from '../../actions/jobActions'
import { updateUserProfile } from '../../actions/userActions'
import {
  Message,
  Loader,
  JobStatusBadge,
  JobAssignBadge,
} from '../componentLib'
import { RBA_ADMIN, RBA_CLIENT, RBA_CAD } from '../../utils/rba'

export const JobSummary = ({
  history,
  userId,
  userRole,
  numJobsIndex,
  showCad,
  showNonCad,
  hasQuoter,
  showSkittles,
}) => {
  const [numJobsInput, setNumJobsInput] = useState(numJobsIndex)
  const [showCadJobs, setShowCadJobs] = useState(showCad)
  const [showNonCadJobs, setShowNonCadJobs] = useState(showNonCad)
  const [showSkittlesLocal, setShowSkittlesLocal] = useState(showSkittles)

  const dispatch = useDispatch()

  const jobIndex = useSelector((state) => state.jobIndex)
  const { loading, error, jobs } = jobIndex

  useEffect(() => {
    if (numJobsIndex) {
      dispatch(getAllJobs(numJobsIndex, showCadJobs, showNonCadJobs))
    }
  }, [dispatch, numJobsIndex, showCadJobs, showNonCadJobs])

  const handleNumJobs = (e) => {
    e.preventDefault()
    dispatch(updateUserProfile({ numJobsIndex: numJobsInput }, 'profile'))
  }

  const handleShowCadJobs = (e) => {
    e.preventDefault()
    setShowCadJobs(!showCadJobs)
    //this is reversed for some reason...
    dispatch(updateUserProfile({ showCadJobs: !showCadJobs }, 'profile'))
  }

  const handleShowNonCadJobs = (e) => {
    e.preventDefault()
    setShowNonCadJobs(!showNonCadJobs)
    //this is reversed for some reason...
    dispatch(updateUserProfile({ showNonCadJobs: !showNonCadJobs }, 'profile'))
  }

  const handleShowSkittles = (e) => {
    e.preventDefault()
    setShowSkittlesLocal(!showSkittlesLocal)
    //this is reversed for some reason...
    dispatch(updateUserProfile({ showSkittles: !showSkittlesLocal }, 'profile'))
  }

  //Interval function to update periodically
  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const delay = RBA_CAD.indexOf(userRole) >= 0 ? 5 * 1000 : 5 * 60 * 1000

  useInterval(() => {
    // Make the request here
    dispatch(getAllJobs(numJobsIndex, showCadJobs, showNonCadJobs))
  }, delay)

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : jobs ? (
        <Card>
          <h3 className="text-center">
            Last{' '}
            <Form inline className="inline">
              <Form.Control
                type="text"
                className="inline input-narrow p-1"
                value={numJobsInput}
                onChange={(e) => setNumJobsInput(e.target.value)}
                onBlur={handleNumJobs}
              />
            </Form>{' '}
            Jobs
          </h3>
          {(hasQuoter || RBA_ADMIN.indexOf(userRole) >= 0) && (
            <div className="text-muted text-center">
              <div>
                Debug: hasQuoter: {hasQuoter} Role: {userRole}
              </div>
              {/* <span
                onClick={handleShowCadJobs}
                className={showCadJobs ? 'ml-2 mark' : 'ml-2 del'}
              >
                Show Jobs with CAD
              </span>{' '}
              <span
                onClick={handleShowNonCadJobs}
                className={showNonCadJobs ? 'ml-2 mark' : 'ml-2 del'}
              >
                Show Jobs withOUT CAD
              </span>{' '}
              <span
                onClick={handleShowSkittles}
                className={showSkittlesLocal ? 'ml-2 mark' : 'ml-2 del'}
              >
                Show Quotes & Status Production
              </span> */}
            </div>
          )}

          <div style={{ borderWidth: '0px', fontSize: '1.2rem' }}>
            {jobs.map((job) => (
              <div
                key={job._id}
                className="row mb-3"
                style={{ borderWidth: '0px' }}
              >
                <div className="col-9 d-flex justify-content-end">
                  <div
                    className={`text-end ${
                      job.rush && RBA_CAD.indexOf(userRole) >= 0
                        ? 'mark-rush'
                        : ''
                    }`}
                  >
                    <Link to={`/jobs/${job._id}`} className="text-end">
                      {job.num}-R{job.revision}:{' '}
                      <strong>
                        {job.customer &&
                          job.customer.shortName &&
                          userRole !== 'customer' &&
                          `[${job.customer.shortName || job.customer.name}] `}
                        {job.name}
                      </strong>
                    </Link>{' '}
                    | <Moment format="ddd MMM D">{job.timeIn}</Moment>
                    {RBA_CAD.indexOf(userRole) >= 0 && (
                      <JobAssignBadge
                        cadCurrentName={job.cadCurrent?.name || 'Unassigned'}
                        cadCurrentId={job.cadCurrent?._id || 'Unassigned'}
                        userId={userId}
                      />
                    )}
                  </div>
                </div>

                <div className="col-3">
                  <JobStatusBadge
                    userRole={userRole}
                    jobId={job._id}
                    jobStatus={job.statusCurrent}
                    quoteStatus={job.quoteStatusCurrent}
                    productionStatus={job.productionStatusCurrent}
                    quickClick={RBA_CLIENT.indexOf(userRole) >= 0}
                    hasQuoter={hasQuoter}
                    showSkittles={showSkittles}
                  />
                </div>
              </div>
            ))}
          </div>
        </Card>
      ) : (
        ''
      )}
    </>
  )
}
