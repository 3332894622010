import {
  NOTES_GET_REQUEST,
  NOTES_GET_SUCCESS,
  NOTES_GET_FAIL,
  NOTE_CREATE_REQUEST,
  NOTE_CREATE_SUCCESS,
  NOTE_CREATE_FAIL,
} from '../actions/constants'

export const noteIndexReducer = (state = { notes: [] }, action) => {
  switch (action.type) {
    case NOTES_GET_REQUEST:
      return { loading: true, ...state }
    case NOTES_GET_SUCCESS:
      return { loading: false, notes: action.payload }
    case NOTES_GET_FAIL:
      return { loading: false, error: action.payload }
    case NOTE_CREATE_REQUEST:
      return { loading: true, ...state }
    case NOTE_CREATE_SUCCESS:
      return {
        loading: false,
        notes: [action.payload, ...state.notes],
      }
    case NOTE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
