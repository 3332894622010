import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateJobStatus } from '../../actions/jobActions'
import { RBA_ADMIN } from '../../utils/rba'

export const JobStatusBadge = ({
  userRole,
  jobId,
  jobStatus,
  quoteStatus,
  productionStatus,
  quickClick,
  hasQuoter,
  showSkittles,
}) => {
  const dispatch = useDispatch()

  const updateQuoteStatus = (e) => {
    e.preventDefault()
    // console.log(`quote status to: ${quoteUpdate}`)
    dispatch(updateJobStatus(jobId, 'quoteStatus', quoteUpdate))
  }

  const updateProductionStatus = (e) => {
    e.preventDefault()
    dispatch(updateJobStatus(jobId, 'productionStatus', productionUpdate))
  }

  let quoteUpdate
  let quoteVariant
  switch (quoteStatus) {
    case 'Imported':
      quoteUpdate = 'Awt Takeoff'
      quoteVariant = 'light'
      break
    case 'Awt Takeoff':
      quoteUpdate = 'Rdy to Quote'
      quoteVariant = 'light'
      break
    case 'Rdy to Quote':
      quoteUpdate = 'Emailed'
      quoteVariant = 'danger'
      break
    case 'Emailed':
      quoteUpdate = 'Sold'
      quoteVariant = 'warning'
      break
    case 'Sold':
      quoteUpdate = 'Awt Takeoff'
      quoteVariant = 'success'
      break
    default:
      quoteUpdate = 'NA'
      quoteVariant = 'light'
  }

  let productionUpdate
  let productionVariant
  switch (productionStatus) {
    case 'Imported':
      productionUpdate = 'Awt Approval'
      productionVariant = 'light'
      break
    case 'Awt Approval':
      productionUpdate = 'Awt Material'
      productionVariant = 'light'
      break
    case 'Awt Material':
      productionUpdate = 'Scheduled'
      productionVariant = 'warning'
      break
    case 'Scheduled':
      productionUpdate = 'Built'
      productionVariant = 'info'
      break
    case 'Built':
      productionUpdate = 'Delivered'
      productionVariant = 'info'
      break
    case 'Delivered':
      productionUpdate = 'Awt Approval'
      productionVariant = 'success'
      break
    default:
      productionUpdate = 'NA'
      productionVariant = 'light'
  }

  let estimateVariant
  let estimateProgress
  switch (jobStatus) {
    case 'Submitted':
      estimateVariant =
        userRole === 'cad'
          ? 'secondary'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'secondary'
          : 'warning'
      estimateProgress = 0
      break
    case 'RFI':
      estimateVariant =
        userRole === 'cad'
          ? 'success'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'success'
          : 'danger'
      estimateProgress = 15
      break
    case 'Validated':
      estimateVariant =
        userRole === 'cad'
          ? 'danger'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'primary'
          : 'secondary'
      estimateProgress = 25
      break
    case 'Working':
      estimateVariant =
        userRole === 'cad'
          ? 'info'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'info'
          : 'secondary'
      estimateProgress = 35
      break
    case 'Correction':
      estimateVariant =
        userRole === 'cad'
          ? 'danger'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'dark'
          : 'secondary'
      estimateProgress = 50
      break
    case 'Review':
      estimateVariant =
        userRole === 'cad'
          ? 'secondary'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'danger'
          : 'secondary'
      estimateProgress = 65
      break
    case 'Released':
      estimateVariant =
        userRole === 'cad'
          ? 'success'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'light'
          : 'success'
      estimateProgress = 100
      break
    case 'Revision':
      estimateVariant =
        userRole === 'cad'
          ? 'danger'
          : RBA_ADMIN.indexOf(userRole) >= 0
          ? 'warning'
          : 'warning'
      estimateProgress = 75
      break
    case 'No Takeoff':
      estimateVariant = 'light'
      estimateProgress = 100
      break

    default:
      estimateVariant = 'secondary'
      estimateProgress = 100
  }

  return (
    <>
      <div className="row px-0">
        <div className="col-12 px-0">
          <span className={`badge rounded-pill bg-${estimateVariant} `}>
            <i className="fas fa-ruler-combined"></i> {jobStatus}
          </span>
        </div>
        {(hasQuoter || RBA_ADMIN.indexOf(userRole) >= 0) && showSkittles && (
          <>
            <div className="col-4 px-0">
              <span
                className={`btn btn-${quoteVariant} badge rounded-pill`}
                //variant={quoteVariant}
                onClick={quickClick && updateQuoteStatus}
              >
                <i className="fas fa-clipboard-list"></i> {quoteStatus}
              </span>
            </div>
            <div className="col-4 px-0">
              <span
                className={`btn btn-${productionVariant} badge rounded-pill`}
                //variant={productionVariant}
                onClick={quickClick && updateProductionStatus}
              >
                <i className="fas fa-industry"></i> {productionStatus}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )
}
