import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { JobIndex } from '../../components/componentLib'
import {} from '../../actions/jobActions'

export const JobIndexLayout = ({
  history,
  match,
  activeOnly = false,
  mineOnly = false,
}) => {
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    }
  }, [userInfo, history])

  const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()

  return (
    <>
      <Helmet>
        <title>MRQ: All Estimates </title>
        <meta name="description" content="Metal roof takeoffs" />
      </Helmet>
      <Row className="justify-content-md-center">
        <Col lg={12}>
          <JobIndex
            userInfo={userInfo}
            history={history}
            activeOnly={query.get('activeOnly')}
            mineOnly={query.get('mineOnly')}
            status={query.get('status')}
          />
        </Col>
        <Col lg={4}></Col>
      </Row>
    </>
  )
}
