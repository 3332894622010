import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { JobDupeModal } from '../componentLib.js'

export const JobDuplicate = ({ jobId, jobName }) => {
  // let cardWidth
  // admin ? (cardWidth = '26rem') : (cardWidth = '24rem')

  //Duplicate Modal
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false)
  const duplicateHandler = () => {
    console.log('duplicate')
    console.log(jobName)
    setDuplicateModalOpen(!duplicateModalOpen)
  }

  return (
    <>
      <Button onClick={duplicateHandler} className="mx-3 btn btn-info">
        <i className="far fa-clone"></i> Duplicate/Clone
      </Button>

      {duplicateModalOpen && (
        <JobDupeModal jobId={jobId} openState oldName={jobName} />
      )}
    </>
  )
}
