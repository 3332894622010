import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailReducer,
  userProfileUpdateReducer,
  userIndexReducer,
  userDeleteReducer,
} from './reducers/userReducers'
import {
  jobIndexReducer,
  jobDetailReducer,
  jobDeleteReducer,
  jobDataReducer,
} from './reducers/jobReducers'
import { noteIndexReducer } from './reducers/noteReducers'
import {
  clientDetailReducer,
  clientIndexReducer,
} from './reducers/clientReducers'
import { qboSetupReducer } from './reducers/qboReducers'
import { itemIndexReducer } from './reducers/itemReducers'
import {
  quoteIndexReducer,
  quoteDetailReducer,
  quoteDeleteReducer,
  quoteJobIndexReducer,
} from './reducers/quoteReducers'
import { customerIndexReducer } from './reducers/customerReducers'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetail: userDetailReducer,
  userUpdateProfile: userProfileUpdateReducer,
  userIndex: userIndexReducer,
  userDelete: userDeleteReducer,
  jobIndex: jobIndexReducer,
  jobDetail: jobDetailReducer,
  jobDelete: jobDeleteReducer,
  jobData: jobDataReducer,
  noteIndex: noteIndexReducer,
  clientIndex: clientIndexReducer,
  clientDetail: clientDetailReducer,
  qboSetup: qboSetupReducer,
  itemIndex: itemIndexReducer,
  quoteIndex: quoteIndexReducer,
  quoteIndexJob: quoteJobIndexReducer,
  quoteDetail: quoteDetailReducer,
  quoteDelete: quoteDeleteReducer,
  customerIndex: customerIndexReducer,
})

// Load local storage items
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const jobIndexFromStorage = localStorage.getItem('jobIndex')
  ? JSON.parse(localStorage.getItem('jobIndex'))
  : null

const itemIndexFromStorage = localStorage.getItem('itemIndex')
  ? JSON.parse(localStorage.getItem('itemIndex'))
  : null

// const quoteIndexFromStorage = localStorage.getItem('quoteIndex')
//   ? JSON.parse(localStorage.getItem('quoteIndex'))
//   : null

const customerIndexFromStorage = localStorage.getItem('customerIndex')
  ? JSON.parse(localStorage.getItem('customerIndex'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  jobIndex: { jobIndexFromStorage },
  itemIndex: { itemIndexFromStorage },
  //quoteIndex: { quoteIndexFromStorage },
  customerIndex: { customerIndexFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
