import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

import { getUserDetail, updateUserProfile } from '../../actions/userActions'
import {
  Message,
  Loader,
  UserDetailAdmin,
  UserDetailAdminInfo,
} from '../../components/componentLib'
import { RBA_ADMIN } from '../../utils/rba'

export const UserDetailLayout = ({ location, history, match }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [notifSubmit, setNotifSubmit] = useState('')
  const [notifRfi, setNotifRfi] = useState('')
  const [notifRelease, setNotifRelease] = useState('')
  const [notifSubmitAll, setNotifSubmitAll] = useState('')
  const [notifRfiAll, setNotifRfiAll] = useState('')
  const [notifReleaseAll, setNotifReleaseAll] = useState('')
  const [showSkittles, setShowSkittles] = useState('')
  const [showCadJobs, setShowCadJobs] = useState('')
  const [showNonCadJobs, setShowNonCadJobs] = useState('')

  //This layout feeds for /profile and /user/:id. If profile, then it's a self-edit only.
  const userId = match.params.id ? match.params.id : 'profile'

  const dispatch = useDispatch()

  const userDetail = useSelector((state) => state.userDetail)
  const { loading, error, user } = userDetail

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile
  const loadingUpdate = userUpdateProfile.loading
  const errorUpdate = userUpdateProfile.error

  useEffect(() => {
    if (!userInfo || error) {
      history.push('/login')
    }
  }, [history, dispatch, userInfo, error])

  useEffect(() => {
    dispatch(getUserDetail(userId))
  }, [])

  useEffect(() => {
    if (user) {
      setName(user.name)
      setEmail(user.email)
      setPhone(user.phone)
      setNotifSubmit(user.notifSubmit)
      setNotifRfi(user.notifRfi)
      setNotifRelease(user.notifRelease)
      setNotifSubmitAll(user.notifSubmitAll)
      setNotifRfiAll(user.notifRfiAll)
      setNotifReleaseAll(user.notifReleaseAll)
      setShowSkittles(user.showSkittles)
      setShowCadJobs(user.showCadJobs)
      setShowNonCadJobs(user.showNonCadJobs)
    }
  }, [user])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateUserProfile(
        {
          id: user._id,
          name,
          email,
          phone,
          password,
          notifSubmit: notifSubmit,
          notifRfi: notifRfi,
          notifRelease: notifRelease,
          notifSubmitAll: notifSubmitAll,
          notifRfiAll: notifRfiAll,
          notifReleaseAll: notifReleaseAll,
          showSkittles: showSkittles,
          showCadJobs: showCadJobs,
          showNonCadJobs: showNonCadJobs,
        },
        userId
      )
    )
  }
  return (
    <Row>
      {userId === userInfo._id ||
        (user.email === userInfo.email && userId !== 'profile' && (
          <Message variant="danger" className="m-2">
            You are editing your own user profile
          </Message>
        ))}
      <Col md={4}>
        <Card>
          <h5>User Profile</h5>
          {error || errorUpdate ? (
            <Message variant="danger">{error}</Message>
          ) : (
            ''
          )}
          {success && (
            <Message variant="success">User Profile Updated!</Message>
          )}
          {loading || loadingUpdate ? <Loader /> : ''}
          {user && (
            <>
              <Form onSubmit={submitHandler} autoComplete="nope">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="phone&#8203">
                  <Form.Label>Phone #</Form.Label>
                  <Form.Control
                    value={phone}
                    autoComplete="new-phone"
                    onChange={(e) => setPhone(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                  <Form.Text id="passwordHelp" muted>
                    Leave blank unless you want to change your password
                  </Form.Text>
                </Form.Group>
                <div>
                  <u>Notification Settings</u>
                </div>
                <Form.Group controlId="notifSubmit" className="mb-1">
                  <Form.Check
                    type="checkbox"
                    label="Notify me by email when a job is submitted"
                    checked={notifSubmit}
                    onChange={(e) => setNotifSubmit(!notifSubmit)}
                  />
                </Form.Group>
                <Form.Group controlId="notifRfi" className="mb-1">
                  <Form.Check
                    type="checkbox"
                    label="Notify me by email when more information is required"
                    checked={notifRfi}
                    onChange={(e) => setNotifRfi(!notifRfi)}
                  />
                </Form.Group>
                <Form.Group controlId="notifRelease" className="mb-1">
                  <Form.Check
                    type="checkbox"
                    label="Notify me by email when a job is finished"
                    checked={notifRelease}
                    onChange={(e) => setNotifRelease(!notifRelease)}
                  />
                </Form.Group>
                <div>
                  <u>
                    Notify for all jobs? (uncheck to only be emailed for jobs
                    you submitted)
                  </u>
                </div>
                <Form.Group controlId="notifSubmitAll" className="mb-1">
                  <Form.Check
                    type="checkbox"
                    label="Notify for all jobs submitted?"
                    checked={notifSubmitAll}
                    onChange={(e) => setNotifSubmitAll(!notifSubmitAll)}
                  />
                </Form.Group>
                <Form.Group controlId="notifRfiAll" className="mb-1">
                  <Form.Check
                    type="checkbox"
                    label="Notify for all jobs that need more info?"
                    checked={notifRfiAll}
                    onChange={(e) => setNotifRfiAll(!notifRfiAll)}
                  />
                </Form.Group>
                <Form.Group controlId="notifReleaseAll" className="mb-1">
                  <Form.Check
                    type="checkbox"
                    label="Notify for all jobs completed?"
                    checked={notifReleaseAll}
                    onChange={(e) => setNotifReleaseAll(!notifReleaseAll)}
                  />
                </Form.Group>
                {userInfo.hasQuoter && (
                  <>
                    <div>
                      <u>Homepage/Dashboard Settings</u>
                    </div>
                    <Form.Group controlId="showSkittles" className="mb-1">
                      <Form.Check
                        type="checkbox"
                        label="Show Quote and Production Status"
                        checked={showSkittles}
                        onChange={(e) => setShowSkittles(!showSkittles)}
                      />
                    </Form.Group>
                    <Form.Group controlId="showCadJobs" className="mb-1">
                      <Form.Check
                        type="checkbox"
                        label="Show Jobs with CAD/Takeoff"
                        checked={showCadJobs}
                        onChange={(e) => setShowCadJobs(!showCadJobs)}
                      />
                    </Form.Group>
                    <Form.Group controlId="showNonCadJobs" className="mb-1">
                      <Form.Check
                        type="checkbox"
                        label="Show jobs withOUT CAD/Takeoff (quote only)"
                        checked={showNonCadJobs}
                        onChange={(e) => setShowNonCadJobs(!showNonCadJobs)}
                      />
                    </Form.Group>
                  </>
                )}
                <Button type="submit" variant="primary">
                  Update User Info
                </Button>
              </Form>
            </>
          )}
        </Card>
      </Col>
      {location.pathname.includes('user') &&
        RBA_ADMIN.indexOf(userInfo.role) >= 0 && (
          <>
            <Col md={4}>
              <UserDetailAdmin user={user} />
            </Col>
            <Col md={4}>
              <UserDetailAdminInfo user={user} userInfo={userInfo} />
            </Col>
          </>
        )}
    </Row>
  )
}
