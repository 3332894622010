import {
  CUSTOMERS_GET_ALL_REQUEST,
  CUSTOMERS_GET_ALL_SUCCESS,
  CUSTOMERS_GET_ALL_FAIL,
  // CUSTOMER_GET_DETAIL_REQUEST,
  // CUSTOMER_GET_DETAIL_SUCCESS,
  // CUSTOMER_GET_DETAIL_FAIL,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_CREATE_REQUEST,
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_IMPORT_SUCCESS,
} from '../actions/constants'

export const customerIndexReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case CUSTOMERS_GET_ALL_REQUEST:
      return { loading: true, ...state }
    case CUSTOMERS_GET_ALL_SUCCESS:
      return { loading: false, customers: action.payload }
    case CUSTOMERS_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOMER_CREATE_REQUEST:
      return { loadingCreate: true, ...state }
    case CUSTOMER_CREATE_SUCCESS:
      return {
        loadingCreate: false,
        customers: [action.payload, ...state.customers],
      }
    case CUSTOMER_IMPORT_SUCCESS:
      return { loadingCreate: false, customers: action.payload }
    case CUSTOMER_CREATE_FAIL:
      return { loadingCreate: false, errorCreate: action.payload }
    case CUSTOMER_DELETE_REQUEST:
      return { loading: true, ...state }
    case CUSTOMER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        customers: state.customers.filter((customer) => customer._id !== action.payload.customerId),
      }
    case CUSTOMER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOMER_UPDATE_REQUEST:
      return { loading: true, ...state }
    case CUSTOMER_UPDATE_SUCCESS:
      const index = state.customers.findIndex((customer) => customer._id === action.payload._id)
      const updatedCustomers = [...state.customers]
      updatedCustomers[index] = action.payload
      return {
        loading: false,
        customers: updatedCustomers,
      }
    case CUSTOMER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// export const customerDetailReducer = (state = { customer: [] }, action) => {
//   switch (action.type) {
//     case CUSTOMER_GET_DETAIL_REQUEST:
//       return { loading: true, ...state }
//     case CUSTOMER_GET_DETAIL_SUCCESS:
//       return { loading: false, customer: action.payload }
//     case CUSTOMER_GET_DETAIL_FAIL:
//       return { loading: false, error: action.payload }
//     default:
//       return state
//   }
// }
