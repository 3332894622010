import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ItemTable, ItemCreateModal, ItemImportModal } from '../../components/componentLib'

export const ItemIndexLayout = ({ history }) => {
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    if (!userInfo) {
      history?.push('/login')
    }
  }, [userInfo, history])

  //LOCAL SCOPE
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)

  const handleCreateItem = (e) => {
    setCreateModalOpen(!createModalOpen)
  }

  const handleImportItem = (e) => {
    setImportModalOpen(!importModalOpen)
  }

  return (
    <>
      {userInfo ? (
        <>
          <div className='px-5 mx-5'>
            <Button className='mb-3' onClick={handleCreateItem}>
              <i className='fas fa-plus-circle' /> Create New Item
            </Button>{' '}
            <Button className='mb-3' variant='info' onClick={handleImportItem}>
              <i className='fas fa-file-upload' /> Import From File
            </Button>{' '}
            <ItemTable userInfo />
          </div>
          {createModalOpen && <ItemCreateModal openState />}
          {importModalOpen && <ItemImportModal openState />}
        </>
      ) : (
        ''
      )}
    </>
  )
}
