import MaterialTable from 'material-table'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import {
  createCustomer,
  deleteCustomerById,
  updateCustomer,
  getAllCustomers,
} from '../../actions/customerActions'
import { Loader, Message } from '../componentLib'

export const CustomerTable = ({ history, userInfo }) => {
  const dispatch = useDispatch()

  const customerIndex = useSelector((state) => state.customerIndex)
  const { loading, error, customers } = customerIndex

  useEffect(() => {
    if (userInfo) {
      dispatch(getAllCustomers())
    }
  }, [dispatch, userInfo])

  const handleRowAdd = (newData, resolve) => {
    console.log(newData)
    dispatch(createCustomer(newData))
    resolve()
  }
  const handleRowUpdate = (newData, resolve) => {
    console.log(newData)
    dispatch(updateCustomer(newData, newData._id))
    resolve()
  }
  const handleCellUpdate = (newValue, rowData, columnDef, resolve) => {
    const newData = rowData
    newData[columnDef.field] = newValue
    console.log('newData')
    console.log(newData)
    dispatch(updateCustomer(newData, newData._id))
    resolve()
  }
  const handleRowDelete = (newData, resolve) => {
    console.log(newData)
    dispatch(deleteCustomerById(newData._id))
    resolve()
  }

  const handleBulkEdit = (changes, resolve) => {
    console.log(changes)
    for (const customer in changes) {
      console.log(changes[customer])
      dispatch(updateCustomer(changes[customer].newData, changes[customer].newData._id))
    }
    resolve()
  }

  const downloadHeaders = [
    { label: 'Customer', key: 'name' },
    { label: 'Short Name', key: 'shortName' },
    { label: 'No', key: 'customerNumForClient' },
    { label: 'Price Code', key: 'priceCode' },
    { label: 'Emails', key: 'emails' },
  ]

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : customers ? (
        <>
          <CSVLink data={customers} headers={downloadHeaders} filename={'customer-download.csv'}>
            <Button className='mb-3' variant='secondary'>
              <i className='fas fa-file-download' /> Download Data
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              {
                title: 'Customer',
                field: 'name',
              },
              {
                title: 'Short Name',
                field: 'shortName',
              },
              {
                title: 'No',
                field: 'customerNumForClient',
              },
              { title: 'Price Code', field: 'priceCode' },
              { title: 'Emails', field: 'emails' },
            ]}
            title='All Customers'
            data={customers}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve) => {
                  handleCellUpdate(newValue, rowData, columnDef, resolve)
                })
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  handleRowUpdate(newData, oldData, resolve)
                }),
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  handleRowAdd(newData, resolve)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData, resolve)
                }),
              onBulkUpdate: (changes) =>
                new Promise((resolve) => {
                  handleBulkEdit(changes, resolve)
                }),
            }}
            options={{
              paging: true,
              pageSize: 25, // make initial page size
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
              searchFieldAlignment: 'left',
              toolbarButtonAlignment: 'left',
            }}
          />
        </>
      ) : (
        ''
      )}
    </>
  )
}
