import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import { RBA_ADMIN, RBA_CLIENT } from '../../utils/rba'
// import { Link } from 'react-router-dom'

import { getClientDetailById } from '../../actions/clientActions'
import {
  Message,
  Loader,
  ClientDetails,
  ImageEditor,
  ClientMatchTable,
  ClientMatchCreateModal,
  ClientMatchImportModal,
  ClientPanelList,
  ClientRatioTable,
  ClientRatioImportModal,
} from '../../components/componentLib'

export const ClientDetailLayout = ({ location, history, match }) => {
  //LOCAL SCOPE
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)
  const [importRatioModalOpen, setImportRatioModalOpen] = useState(false)
  const [hideMatchTable, setHideMatchTable] = useState(true)
  const [hidePanels, setHidePanels] = useState(true)
  const [hideRatios, setHideRatios] = useState(true)
  const [updateSuccessLocal, setUpdateSuccessLocal] = useState(false)

  const handleCreateMatch = (e) => {
    setCreateModalOpen(!createModalOpen)
  }

  const handleImportMatch = (e) => {
    setImportModalOpen(!importModalOpen)
  }

  const handleImportRatio = (e) => {
    setImportRatioModalOpen(!importModalOpen)
  }

  const handleHideMatch = (e) => {
    setHideMatchTable(!hideMatchTable)
  }

  const handleHidePanels = (e) => {
    setHidePanels(!hidePanels)
  }

  const handleHideRatios = (e) => {
    setHideRatios(!hideRatios)
  }

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const clientDetail = useSelector((state) => state.clientDetail)
  const { loading, error, client, loadingUpdate, errorUpdate, successUpdate } = clientDetail

  //CHECK LOGIN
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (RBA_CLIENT.includes(userInfo?.role)) {
      dispatch(getClientDetailById(match.params.id))
    } else {
      history.push('/')
    }
  }, [history, dispatch, match, userInfo])

  useEffect(() => {
    successUpdate === true && setUpdateSuccessLocal(true)
    setTimeout(() => setUpdateSuccessLocal(false), 5000)
  }, [successUpdate])

  return (
    <Row>
      <Col md={4}>
        {(error || errorUpdate) && <Message variant='danger'>{error || errorUpdate}</Message>}
        {updateSuccessLocal && <Message variant='success'>Data Updated!</Message>}
        {(loading || loadingUpdate) && <Loader />}
        {client && !error && <ClientDetails userInfo={userInfo} client={client} />}
      </Col>
      <Col md={6}>
        {client && !error && (
          <>
            <h5>Set Logo</h5>
            <ImageEditor
              clientInitials={client?.inits}
              clientId={match.params.id}
              clientLogoUrl={client?.logoUrl}
            />
          </>
        )}

        {((userInfo.hasQuoter && RBA_CLIENT.includes(userInfo?.role)) ||
          RBA_ADMIN.includes(userInfo?.role)) && (
          <>
            {client && !error && (
              <>
                <Button
                  className='mt-3 me-2'
                  onClick={handleHideMatch}
                  variant={hideMatchTable ? 'primary' : 'secondary'}
                >
                  {hideMatchTable ? (
                    <>
                      <i className='fas fa-eye' /> Show Match Table
                    </>
                  ) : (
                    <>
                      <i className='fas fa-eye-slash' /> Hide Match Table
                    </>
                  )}
                </Button>
                <Button
                  className='mt-3 me-2'
                  onClick={handleHidePanels}
                  variant={hidePanels ? 'primary' : 'secondary'}
                >
                  {hidePanels ? (
                    <>
                      <i className='fas fa-eye' /> Show Panels
                    </>
                  ) : (
                    <>
                      <i className='fas fa-eye-slash' /> Hide Panels
                    </>
                  )}
                </Button>
                <Button
                  className='mt-3 me-2'
                  onClick={handleHideRatios}
                  variant={hideRatios ? 'primary' : 'secondary'}
                >
                  {hideRatios ? (
                    <>
                      <i className='fas fa-eye' /> Show Accessory Ratios
                    </>
                  ) : (
                    <>
                      <i className='fas fa-eye-slash' /> Hide Accessory Ratios
                    </>
                  )}
                </Button>
              </>
            )}

            {client && !error && (
              <>
                {/* Panel Data */}
                <div className='mt-3' hidden={hidePanels}>
                  {(error || errorUpdate) && (
                    <Message variant='danger'>{error || errorUpdate}</Message>
                  )}
                  {updateSuccessLocal && <Message variant='success'>Data Updated!</Message>}
                  {(loading || loadingUpdate) && <Loader />}
                  <ClientPanelList client={client} />
                </div>
                {/* Match Tables */}
                <div className='mt-3' hidden={hideMatchTable}>
                  {(error || errorUpdate) && (
                    <Message variant='danger'>{error || errorUpdate}</Message>
                  )}
                  {updateSuccessLocal && <Message variant='success'>Data Updated!</Message>}
                  {(loading || loadingUpdate) && <Loader />}
                  {/* <Button className='mb-3' onClick={handleCreateMatch}>
                  <i className='fas fa-plus-circle' /> Create New Match
                </Button>{' '} */}
                  <Button className='mb-3' variant='info' onClick={handleImportMatch}>
                    <i className='fas fa-file-upload' /> Import From File
                  </Button>{' '}
                  <ClientMatchTable client={client} />
                </div>
                {/* Ratios */}
                <div className='mt-3' hidden={hideRatios}>
                  {(error || errorUpdate) && (
                    <Message variant='danger'>{error || errorUpdate}</Message>
                  )}
                  {updateSuccessLocal && <Message variant='success'>Data Updated!</Message>}
                  {(loading || loadingUpdate) && <Loader />}
                  <Button className='mb-3' variant='info' onClick={handleImportRatio}>
                    <i className='fas fa-file-upload' /> Import From File
                  </Button>{' '}
                  <ClientRatioTable client={client} />
                </div>
                {/* Modals... */}
                {createModalOpen && <ClientMatchCreateModal openState client={client} />}
                {importModalOpen && <ClientMatchImportModal openState client={client} />}
                {importRatioModalOpen && <ClientRatioImportModal openState client={client} />}
              </>
            )}
          </>
        )}
      </Col>
    </Row>
  )
}
