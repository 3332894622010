import axios from 'axios'
import {
  NOTES_GET_REQUEST,
  NOTES_GET_SUCCESS,
  NOTES_GET_FAIL,
  NOTE_CREATE_REQUEST,
  NOTE_CREATE_SUCCESS,
  NOTE_CREATE_FAIL,
  JOB_GET_DETAIL_SUCCESS,
} from './constants'

export const createNoteByJobId = (jobId, note) => async (
  dispatch,
  getState
) => {
  console.log('in create note')
  try {
    dispatch({
      type: NOTE_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/v1/notes/${jobId}`, note, config)

    dispatch({
      type: NOTE_CREATE_SUCCESS,
      payload: data.note,
    })
    dispatch({ type: JOB_GET_DETAIL_SUCCESS, payload: data.notedJob })
  } catch (error) {
    dispatch({
      type: NOTE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getNotesByJobId = (jobId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NOTES_GET_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/v1/notes/${jobId}`, config)

    dispatch({
      type: NOTES_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NOTES_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
