import React, { useState, useEffect } from 'react'
import {} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import MaterialTable from 'material-table'
import { updateClient } from '../../actions/clientActions'
import ErrorBoundary from '../../components/ErrorBoundary'

export const ClientMatchTable = ({ client }) => {
  const [matchList, setMatchList] = useState([])

  useEffect(() => {
    if (client && client.matchLists != null) {
      setMatchList(client.matchLists)
    }
  }, [client])

  const dispatch = useDispatch()

  const handleSaveMatchList = () => {
    console.log('update')
    const updatedClient = {
      _id: client._id,
      matchLists: matchList,
    }
    dispatch(updateClient(updatedClient))
  }

  const handleRowAdd = (newData, resolve, reject) => {
    try {
      const tempMatchList = [...matchList, newData]
      setMatchList(tempMatchList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    try {
      const tempMatchList = [...matchList]
      const index = tempMatchList.indexOf(oldData)
      tempMatchList[index] = newData
      setMatchList(tempMatchList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }
  const handleCellUpdate = (newValue, rowData, columnDef, resolve) => {
    const newData = rowData
    newData[columnDef.field] = newValue
    console.log('newData')
    console.log(newData)
    let tempMatchList = matchList
    tempMatchList.filter((item) => item !== rowData).push(newData)
    setMatchList(tempMatchList)
    resolve()
  }
  const handleRowDelete = (oldData, resolve, reject) => {
    try {
      let tempMatchList = [...matchList]
      const index = tempMatchList.indexOf(oldData)
      tempMatchList.splice(index, 1)
      setMatchList(tempMatchList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }

  const handleBulkEdit = (changes, resolve, reject) => {
    try {
      console.log(changes)
      let tempMatchList = [...matchList]
      for (const line in changes) {
        console.log(changes[line])
        const index = tempMatchList.indexOf(changes[line].oldData)
        tempMatchList[index] = changes[line].newData
      }
      setMatchList(tempMatchList)
      resolve()
    } catch (err) {
      console.log(err)
      reject(err)
    }
  }

  const downloadHeaders = [
    { label: 'Code', key: 'code' },
    { label: 'Panel', key: 'panel' },
    { label: 'Drop', key: 'drop' },
  ]

  return (
    <>
      {client && (
        <>
          <ErrorBoundary>
            <CSVLink
              data={matchList}
              headers={downloadHeaders}
              filename={'matchList-download.csv'}
            >
              <Button className="mb-3" variant="secondary">
                <i className="fas fa-file-download" /> Download Data
              </Button>
            </CSVLink>
          </ErrorBoundary>
          <div>
            Any edits directly in the table do NOT auto save! <br />
            Be sure to click Save early and often. <br />
            <Button
              className="my-3"
              variant="primary"
              onClick={handleSaveMatchList}
            >
              <i className="fas fa-save" /> SAVE
            </Button>
          </div>
          <MaterialTable
            columns={[
              { title: 'Code', field: 'code' },
              { title: 'Panel', field: 'panel' },
              { title: 'Drop', field: 'drop' },
            ]}
            title="Match Lists"
            data={matchList}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve) => {
                  handleCellUpdate(newValue, rowData, columnDef, resolve)
                })
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  handleRowUpdate(newData, oldData, resolve, reject)
                }),
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  handleRowAdd(newData, resolve, reject)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  handleRowDelete(oldData, resolve, reject)
                }),
              onBulkUpdate: (changes) =>
                new Promise((resolve, reject) => {
                  handleBulkEdit(changes, resolve, reject)
                }),
            }}
            options={{
              paging: true,
              pageSize: 10, // make initial page size
              emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 25, 50, 100], // rows selection options
              searchFieldAlignment: 'left',
              toolbarButtonAlignment: 'left',
            }}
          />
        </>
      )}
    </>
  )
}
