import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  QuoteDetailHeader,
  QuoteDetailLines,
  QuoteDetailCutlist,
  QuotePdf,
  ScrollArrow,
} from '../../components/componentLib'
import ErrorBoundary from '../../components/ErrorBoundary'
import { getUserDetail } from '../../actions/userActions'

export const QuoteDetailLayout = ({ history, match }) => {
  let quoteId = match.params.id
  //CHECK LOGIN
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetail = useSelector((state) => state.userDetail)
  const { loading, error, user } = userDetail

  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo & (!user || error)) {
      dispatch(getUserDetail(userInfo?._id))
    }
  }, [userInfo, user, error, dispatch])

  useEffect(() => {
    if (error || !userInfo) {
      history?.push('/login')
    }
  })

  const linesRef = useRef(null)
  const cutlistRef = useRef(null)

  return (
    <>
      {userInfo ? (
        <>
          <h5>Project Details</h5>
          <ErrorBoundary>
            <div className='row'>
              <div className='col-md-5'>
                <QuoteDetailHeader quoteId={quoteId} userInfo={userInfo} />
              </div>

              <div className='col-md-5'>
                <QuotePdf quoteId={quoteId} userInfo={userInfo} />
              </div>
            </div>
          </ErrorBoundary>

          <h5 ref={linesRef}>Line Items</h5>
          <ErrorBoundary>
            <div className='row'>
              <div className='col-md-9'>
                <QuoteDetailLines quoteId={quoteId} userInfo={userInfo} />
              </div>
            </div>
          </ErrorBoundary>
          <h5 ref={cutlistRef}>Panel List</h5>
          <ErrorBoundary>
            <div className='row'>
              <div className='col-md-9'>
                <QuoteDetailCutlist quoteId={quoteId} userInfo={userInfo} />
              </div>
            </div>
          </ErrorBoundary>
          <ScrollArrow linesRef={linesRef} cutlistRef={cutlistRef} />
        </>
      ) : (
        ''
      )}
    </>
  )
}
