import {
  CLIENT_CREATE_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_GET_ALL_FAIL,
  CLIENT_GET_ALL_REQUEST,
  CLIENT_GET_ALL_SUCCESS,
  CLIENT_GET_DETAIL_FAIL,
  CLIENT_GET_DETAIL_REQUEST,
  CLIENT_GET_DETAIL_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
} from '../actions/constants'

export const clientIndexReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case CLIENT_GET_ALL_REQUEST:
      return { loading: true, ...state }
    case CLIENT_GET_ALL_SUCCESS:
      return { loading: false, clients: action.payload }
    case CLIENT_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    case CLIENT_CREATE_REQUEST:
      return { loadingCreate: true, ...state }
    case CLIENT_CREATE_SUCCESS:
      return { loadingCreate: false, clients: [action.payload, ...state.clients] }
    case CLIENT_CREATE_FAIL:
      return { loadingCreate: false, errorCreate: action.payload }
    default:
      return state
  }
}

export const clientDetailReducer = (state = { client: {} }, action) => {
  switch (action.type) {
    case CLIENT_GET_DETAIL_REQUEST:
      return { loading: true, ...state }
    case CLIENT_GET_DETAIL_SUCCESS:
      return { loading: false, client: action.payload }
    case CLIENT_GET_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case CLIENT_UPDATE_REQUEST:
      return { loadingUpdate: true, successUpdate: false, ...state }
    case CLIENT_UPDATE_SUCCESS:
      return { loadingUpdate: false, successUpdate: true, client: action.payload }
    case CLIENT_UPDATE_FAIL:
      return { loadingUpdate: false, successUpdate: false, errorUpdate: action.payload }
    default:
      return state
  }
}
